<div
  class="shadow-header fixed top-0 z-[10000] flex h-[68px] w-full items-center justify-between border-b border-palette-gray-200 bg-white !px-6 py-[14px] font-inter"
>
  <div class="flex gap-2">
    <button (click)="handleClickLogo()">
      <img
        src="assets/images/logo/blue-logo-text.svg"
        alt=""
        class="max-h-[30px] min-h-[30px] min-w-[125px]"
      />
      <!-- <img src="assets/images/logo/blue-soctrip-logo.svg" alt="" class="min-w-[125px] max-h-[30px] min-h-[30px]"> -->
    </button>
    <div class="text-2xl font-medium leading-8 text-palette-gray-700">Car</div>
  </div>
  <div class="flex items-center gap-2">
    <button
      *ngIf="isLogin"
      class="relative rounded px-2 py-1 hover:bg-palette-gray-200"
      (click)="handleClickMenuMore()"
    >
      <i class="sctr-icon-dots-grid text-2xl text-palette-gray-500"></i>
      <!-- Modal when click More Button -->
      <p-overlay
        [(visible)]="overlayMoreVisible"
        class="absolute right-[220px] top-2"
        [responsive]="{ direction: 'bottom-end', contentStyleClass: 'w-30rem' }"
      >
        <ng-template pTemplate="content">
          <div
            class="mt-2 flex min-w-[220px] flex-wrap rounded-lg border border-palette-gray-200 bg-white p-2 font-inter shadow lg:min-h-fit"
          >
            <button
              (click)="onClickMyBookingItem()"
              class="flex w-full items-center gap-2 rounded-lg px-3 py-2 hover:bg-palette-blue-light-25 hover:shadow-lg"
            >
              <i class="sctr-icon-building-07 text-xl text-palette-gray-500"></i>
              <p class="break-words text-sm leading-3 text-palette-gray-700">
                {{ 'MY_BOOKING.MY_BOOKING' | translate }}
              </p>
            </button>
            <button
              (click)="onClickSavedListItem()"
              class="flex w-full items-center gap-2 rounded-lg px-3 py-2 hover:bg-palette-blue-light-25 hover:shadow-lg"
            >
              <i class="sctr-icon-bookmark text-xl text-palette-gray-500"></i>
              <p class="break-words text-sm leading-3 text-palette-gray-700">
                {{ 'MY_BOOKING.SAVED_LIST' | translate }}
              </p>
            </button>
            <button
              (click)="onClickMyServices()"
              class="flex w-full items-center gap-2 rounded-lg px-3 py-2 hover:bg-palette-blue-light-25 hover:shadow-lg"
            >
              <img class="!h-5 !w-5" src="assets/images/common-icon/soctrip-center.svg" alt="" />
              <p class="break-words text-sm leading-3 text-palette-gray-700">
                {{ 'HEADER.SOCTRIP_CENTER' | translate }}
              </p>
            </button>
          </div>
        </ng-template>
      </p-overlay>
    </button>

    <div class="relative">
      <button
        class="flex h-[40px] w-[40px] items-center justify-center rounded hover:bg-palette-gray-200"
        (click)="toggleNotifications()"
      >
        <i
          pBadge
          [value]="badgeCount"
          severity="danger"
          class="bell-noti-header sctr-icon-bell-01 cursor-pointer text-[24px] text-palette-gray-500"
        ></i>
      </button>
      <app-header-notifications
        [overlayVisible]="notifyVisible"
        [mobileOverlayVisible]="notifyMobileVisible"
        (updateBadgeCount)="updateBadge($event)"
        (mobileOverlayVisibleChange)="notifyMobileVisible = $event"
      ></app-header-notifications>
    </div>

    <div
      *ngIf="isLogin"
      class="flex cursor-pointer flex-row-reverse items-center gap-3"
      (click)="toggleCustomSetting()"
    >
      <div
        class="box-avatar relative flex h-10 min-h-[40px] !w-10 min-w-[40px] items-center justify-center rounded-full"
      >
        <div class="flex h-full w-full items-center justify-center rounded-full">
          <img
            *ngIf="userInfo && userInfo.avatar_thumbnail_url"
            class="h-[34px] min-h-[34px] w-[34px] min-w-[34px] rounded-full"
            [src]="userInfo.avatar_thumbnail_url | ImagePath : 'thumbnail'"
            (error)="replaceAvatarWithDefaultImage($event)"
          />
        </div>
        <!-- Modal when click user avatar -->
        <!-- <p-overlay [(visible)]="overlayUserVisible" [contentStyleClass]="'shadow-2 border-round'"
                    class="absolute top-2 -left-[220px] md:-left-[110px] rounded-lg"
                    [responsive]="{ direction: 'bottom-end', contentStyleClass: 'w-30rem' }">
                        <div class="rounded-lg overflow-hidden bg-white">
                            <div class="flex flex-col gap-3 px-3 py-3 w-[260px] bg-palette-base-white border-b border-palette-gray-200 cursor-auto">
                                <button [routerLink]="['/personal-profile', userInfo.id]" class="flex items-center w-full bg-palette-blue-50 text-palette-blue-700 hover:bg-palette-blue-100
                                            text-base font-semibold h-[44px] rounded-md justify-center">
                                    <span>{{'HEADER.OPTIONS.VIEW_PROFILE' | translate}}</span>
                                </button>
                            </div>
                            <button class="w-full px-[15px] py-1 hover:bg-palette-gray-200" (click)="displayPopupLogout()">
                                <common-icon-text class="px-[9px] h-[44px] flex items-center gap-2 text-bg-palette-red-600 cursor-pointer font"
                                    [icon]="'sctr-icon-log-out-01'"
                                    [iconStyle]="'text-xl text-palette-red-700'"
                                    [text]="'HEADER.OPTIONS.SIGN_OUT'"
                                    [textStyle]="'text-palette-gray-700 font-medium'">
                                </common-icon-text>
                            </button>
                        </div>
                </p-overlay> -->
      </div>
      <div class="relative hidden flex-col items-end md:flex">
        <div
          #fullName
          (mouseover)="mouseOverHotelName()"
          class="max-w-[200px] truncate text-sm font-semibold leading-5"
        >
          {{ userInfo.full_name }}
        </div>
        <p
          class="tooltip-full-name z-100 absolute inline-block whitespace-nowrap rounded-lg bg-white !px-3 py-2 text-center text-xs font-semibold text-palette-gray-700 transition-opacity duration-200 ease-in"
        >
          {{ userInfo.full_name }}
        </p>
        <div class="text-xs font-medium leading-4 text-palette-gray-500">{{ userInfo.email }}</div>
      </div>
    </div>
    <div *ngIf="!isLogin">
      <div
        class="box-avatar relative flex h-10 min-h-[40px] !w-10 min-w-[40px] items-center justify-center rounded-full"
      >
        <div class="flex h-full w-full items-center justify-center rounded-full">
          <img
            (click)="onSignIn()"
            class="h-[40px] min-h-[40px] w-[40px] min-w-[40px] rounded-full"
            src="https://api-dev-tmtrav.tma-swerp.com/media/libraries/default/UserAvatar.jpg"
          />
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Popup Confirm Sign Out -->
<p-dialog
  header="{{ 'HEADER.SIGN_OUT_ACCOUNT' | translate }}"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [(visible)]="visiblePopupLogout"
  [style]="{ width: '400px' }"
  [styleClass]="'border-0'"
>
  <div
    class="flex justify-between gap-3 rounded-b-lg bg-white px-4 pb-4 pt-2 text-base font-semibold"
  >
    <button
      class="p-button-text flex w-[100%] cursor-pointer justify-center rounded-lg border border-palette-blue-700 px-[18px] py-2.5 text-palette-gray-700 hover:bg-palette-gray-100"
      (click)="closePopupLogout()"
    >
      {{ 'COMMON.CANCEL' | translate }}
    </button>
    <button
      class="p-button-text flex w-[100%] cursor-pointer justify-center rounded-lg border border-palette-blue-700 bg-palette-blue-700 px-[18px] py-2.5 text-white hover:bg-palette-blue-800"
      (click)="handleSignOut()"
    >
      {{ 'HEADER.OPTIONS.SIGN_OUT' | translate }}
    </button>
  </div>
</p-dialog>
