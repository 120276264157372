import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SearchBookingIDService {
  private historySearch: any[] = [];

  setValue(value: any) {
    const existingItemIndex = this.historySearch.findIndex(
      (item: any) => item.type_booking === value.type_booking
    );
    
    if (existingItemIndex === -1) {
      this.historySearch.push(value);
    } else {
      this.historySearch[existingItemIndex] = value;
    }
  }

  getValue() {
    return this.historySearch;
  }

  clearValue() {
    this.historySearch = [];
  }
}
