<p-dialog
  header="Save to"
  [(visible)]="visible"
  [style]="{ width: '384px' }"
  [modal]="true"
  [draggable]="false"
  (onHide)="onClose()"
  class="t-close-collection"
>
  <ng-template pTemplate="header">
    <div class="flex w-full items-center justify-between">
      <span class="text-xl font-semibold">
        {{ 'toast_save_car.save_to' | translate }}
      </span>
    </div>
  </ng-template>
  <div class="gap-2 bg-white px-4 pb-4">
    <div class="t-collection-panel flex max-h-[14.375rem] flex-col gap-4 {{ listSave ? (listSave.length>5 ? 'overflow-y-scroll' : '') : '' }}">
      <div *ngFor="let point of listSave; let i = index" class="t-checkbox flex">
        <p-checkbox
          name="group"
          [value]="point"
          [(ngModel)]="selectedSave"
          [inputId]="point.id!"
          (onChange)="onChange(point.id!, point.name!)"
        ></p-checkbox>
        <label
          #label
          [for]="point.id"
          class="font-regular font-Inter block max-w-full gap-4 truncate pl-3 text-md text-gray-700 md:text-lg"
          (mouseenter)="fullCollectionName.toggle($event); $event.stopPropagation()"
          (mouseleave)="fullCollectionName.hide()"
        >
          {{ point.name }}
        </label>
        <p-overlayPanel #fullCollectionName styleClass="t-more-categories-panel">
          <label
            [for]="point.id"
            class="font-regular font-Inter gap-4 pl-3 text-md text-gray-700 md:text-lg"
          >
            {{ point.name }}
          </label>
        </p-overlayPanel>
      </div>
    </div>
    <div class="t-divider my-4">
      <p-divider type="dashed" class=""></p-divider>
    </div>
    <div class="t-checkbox">
      <p-checkbox
        [value]="newSave[0]"
        [(ngModel)]="selectedSave"
        [inputId]="newSave[0].id"
        (onChange)="onChange(newSave[0].id, newSave[0].name)"
      ></p-checkbox>
      <label
        [for]="newSave[0].id"
        class="font-regular font-Inter gap-4 pl-3 text-md text-gray-700 md:text-lg"
      >
        <span>{{ 'toast_save_car.new_collection' | translate }}</span>
      </label>
    </div>
    <div *ngIf="openInput">
      <div class="flex pb-2 pt-4">
        <p>{{ 'toast_save_car.collection_name' | translate }}</p>
        <p class="pl-1 text-red-500">*</p>
      </div>
      <input
        [placeholder]="'toast_save_car.enter_collection_name' | translate"
        class="focus:border-primary-300 w-full rounded-lg border border-gray-200 p-3 focus:border-solid focus:shadow-[0_0px_0px_4px_#eaf3ff] focus-visible:outline-none"
        [(ngModel)]="inputValue"
        (ngModelChange)="checkValidinput(inputValue)"
        (blur)="blurInput(inputValue)"
      />
      <div *ngIf="spaceValid" class="flex pb-2 pt-4">
        <p class="text-red-500">{{ 'toast_save_car.invalid_collection_name_type' | translate }}</p>
      </div>
      <div *ngIf="emptyValid" class="flex pb-2 pt-4">
        <p class="text-red-500">{{ 'toast_save_car.collection_name_is_required' | translate }}</p>
      </div>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <div class="flex flex-row-reverse gap-3">
      <button
        (click)="checkUpdateOrAddNew()"
        class="rounded-lg border-solid"
        [ngClass]="[
          inputValue ||
          (selectedSave[0] !== undefined &&
            selectedSave[0].id !== 'new collection' &&
            selectedSave !== previousSave)
            ? 'bg-primary-700'
            : 'bg-primary-100 disabled:bg-primary-100 pointer-events-none'
        ]"
      >
        <p class="px-[20px] py-[12px] text-[14px] font-semibold leading-5 text-white">
          {{ 'COMMON.CONFIRM' | translate }}
        </p>
      </button>
      <button (click)="onClose()" class="rounded-lg border border-gray-200">
        <p class="px-[20px] py-[12px] text-[14px] font-semibold leading-5">
          {{ 'COMMON.CANCEL' | translate }}
        </p>
      </button>
    </div>
  </ng-template>
</p-dialog>
