import {
  HTTP_INTERCEPTORS,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpParams,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, Provider } from '@angular/core';
import { TokenStorageService } from '@modules/users/services/token-storage.service';
import { Observable, of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private tokenStorageService: TokenStorageService) {}
  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const token = this.tokenStorageService.getToken();
    if (!token) {
      return next.handle(req);
    }

    // const headers = req.headers.set('Authorization', `Bearer ${token}`);
    // req.url = req.url.replace('filter=status@=', 'NEW_FILTER_VALUE=')

    let modifiedParams = new HttpParams();

    // Thực hiện thay đổi vào modifiedParams nếu cần
    req.params.keys().forEach((key) => {
      let values = req.params.getAll(key) || [''];
      ////console.log("FILTERED: " + value);
      if (key == 'filter') {
        values.forEach((value) => {
          value = atob(value);
          value = decodeURIComponent(value);
          //value = value.replace( '@=','%40%3D')
          ////console.log("FILTERED1: " + value);
          modifiedParams = modifiedParams.append(key, value);
          ////console.log(modifiedParams.get('filter'));
        });
      } else {
        values.forEach((value) => {
          modifiedParams = modifiedParams.append(key, value);
        });
      }
    });

    ////console.log(modifiedParams)
    const modifiedReq = req.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
      params: modifiedParams,
    });

    ////console.log(req.url);
    ////console.log(modifiedReq.url);
    // req.params.keys().forEach(key => {
    //   //console.log(`Query parameter: ${key} = ${req.params.get(key)}`);
    // });
    return next.handle(modifiedReq);
    // return next.handle(
    //   req.clone({
    //     headers,
    //   })
    // );
    // return of('BEARER_TOKEN').pipe(
    //   take(1),
    //   switchMap((token) => {
    //     if (!token) {
    //       return next.handle(req);
    //     }

    //     const headers = req.headers.set('Authorization', `Bearer ${token}`);
    //     return next.handle(req.clone({
    //       headers
    //     }));
    //   })
    // );
  }
}

export const AUTH_INTERCEPTOR_PROVIDER: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: AuthInterceptor,
  multi: true,
} as const;
