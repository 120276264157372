import { Component } from '@angular/core';

@Component({
  selector: 'app-under-test',
  templateUrl: './under-test.component.html',
  styleUrls: ['./under-test.component.scss']
})
export class UnderTestComponent {

}
