<!--
 <div class="grid grid-cols-4 py-4">
  <div class="grid grid-cols-1 col-span-1 place-content-center ">
      <div class="flex col-span-1 mb-1 place-content-center">
          <div  class="flex items-center p-2 rounded-full bg-palette-blue-light-50">
              <img src="assets/icons/transmission-icon-color.svg">
          </div>
      </div>
      <div class="flex w-full col-span-1 place-content-center">
          <span class="ml-1 overflow-hidden font-normal text-soctrip-small text-palette-gray-500 text-ellipsis">Transmission</span>
      </div>
      <div class="flex w-full col-span-1 place-content-center">
          <span class="overflow-hidden font-normal font-semibold text-ellipsis" *ngIf="!dataDetailCar?.is_automatic_transmission">Manual</span>
          <span class="overflow-hidden font-normal font-semibold text-ellipsis" *ngIf="dataDetailCar?.is_automatic_transmission">Automatic</span>
      </div>
  </div>
  <div class="grid grid-cols-1 col-span-1 place-content-center ">
      <div class="flex col-span-1 mb-1 place-content-center">
          <div  class="bg-[#F4F3FF] rounded-full items-center flex p-2">
              <img src="assets/icons/seat-color.svg">
          </div>
      </div>
      <div class="flex w-full col-span-1 place-content-center">
          <span class="ml-1 overflow-hidden font-normal text-soctrip-small text-palette-gray-500 text-ellipsis">Seat</span>
      </div>
      <div class="flex w-full col-span-1 place-content-center">
          <span class="overflow-hidden font-normal font-semibold text-ellipsis">{{dataDetailCar?.seats}}</span>
      </div>
  </div>
  <div class="grid grid-cols-1 col-span-1 place-content-center ">
      <div class="flex col-span-1 mb-1 place-content-center">
          <div  class="bg-[#FFF1F3] rounded-full items-center flex p-2">
              <img src="assets/icons/fuel-color.svg">
          </div>
      </div>
      <div class="flex w-full col-span-1 place-content-center">
          <span class="ml-1 overflow-hidden font-normal text-soctrip-small text-palette-gray-500 text-ellipsis">Fuel</span>
      </div>
      <div class="flex w-full col-span-1 place-content-center">
          <span class="overflow-hidden font-normal font-semibold text-ellipsis">{{dataDetailCar?.fuel}}</span>
      </div>
  </div>
  <div class="grid grid-cols-1 col-span-1 place-content-center ">
      <div class="flex col-span-1 mb-1 place-content-center">
          <div  class="bg-[#FFFAEB] rounded-full items-center flex p-2">
              <img src="assets/icons/consume-color.svg">
          </div>
      </div>
      <div class="flex w-full col-span-1 place-content-center">
          <span class="ml-1 overflow-hidden font-normal text-soctrip-small text-palette-gray-500 text-ellipsis">Consumed fule</span>
      </div>
      <div class="flex w-full col-span-1 place-content-center">
          <span class="overflow-hidden font-normal font-semibold text-ellipsis" *ngIf="dataDetailCar?.fuel_consumption">{{dataDetailCar?.fuel_consumption}} /100km</span>
          <span class="overflow-hidden font-normal font-semibold text-ellipsis" *ngIf="!dataDetailCar?.fuel_consumption">N/A</span>
      </div>

  </div>
</div> -->
<!-- <div class="w-full"> -->
<!-- <div class="grid grid-cols-1 p-3 ">
    <div class="grid w-full grid-cols-2 mb-4">
      <div class="flex items-start h-4 col-span-1 icon-service">
          <img src="assets/icons/transmission-icon.svg">
          <span class="ml-1 overflow-hidden font-normal text-soctrip-small text-palette-gray-500 text-ellipsis">Automatic</span>
      </div>
      <div class="flex items-start h-4 col-span-1 icon-service">
          <img src="assets/icons/energy.svg">
          <span class="ml-1 overflow-hidden font-normal text-soctrip-small text-palette-gray-500 text-ellipsis">Automatic</span>
      </div>
  </div>
  <div class="grid w-full grid-cols-2 ">
      <div class="flex items-start h-4 col-span-1 icon-service">
          <img src="assets/icons/seat.svg">
          <span class="ml-1 overflow-hidden font-normal text-soctrip-small text-palette-gray-500 text-ellipsis">Automatic</span>
      </div>
      <div class="flex items-start h-4 col-span-1 icon-service">
          <img src="assets/icons/droplets.svg">
          <span class="ml-1 overflow-hidden font-normal text-soctrip-small text-palette-gray-500 text-ellipsis">Automatic</span>
      </div>
  </div> -->

<div class="flex w-full flex-wrap gap-y-[0.25rem]">
  <div class="icon-service flex w-1/2 items-center gap-x-[0.5rem]">
    <img src="assets/icons/transmission-icon.svg" />
    <span
      *ngIf="!dataDetailCar?.is_automatic_transmission"
      class="overflow-hidden text-ellipsis text-md font-normal text-palette-gray-500"
      >{{ 'SEARCH_RESULT.car_list.manual' | translate }}
    </span>
    <span
      class="overflow-hidden text-ellipsis text-md font-normal text-palette-gray-500"
      *ngIf="dataDetailCar?.is_automatic_transmission"
    >
      {{ 'SEARCH_RESULT.car_list.automatic' | translate }}
    </span>
  </div>
  <div class="icon-service flex w-1/2 items-center gap-x-[0.5rem]">
    <img src="assets/icons/energy.svg" />
    <span class="overflow-hidden text-ellipsis text-md font-normal text-palette-gray-500">{{
      fule
    }}</span>
  </div>
  <div class="icon-service flex w-1/2 items-center gap-x-[0.5rem]">
    <img src="assets/icons/seat.svg" />
    <span class="overflow-hidden text-ellipsis text-md font-normal text-palette-gray-500">
    {{ 'pluralization.seat(s)' | translate : {count: +dataDetailCar?.seats} }}
    </span>
  </div>
  <div class="icon-service flex w-1/2 items-center gap-x-[0.5rem]">
    <img src="assets/icons/droplets.svg" />
    <span class="overflow-hidden text-ellipsis text-md font-normal text-palette-gray-500">
      {{ getUnitFuels() }}
    </span>
  </div>
</div>
<!-- </div> -->
