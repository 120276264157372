<!-- <div class="hotel-page-room-type gap-3  relative boder !p-2 flex !mt-2 bg-palette-base-white border border-[#fff] rounded-lg">
    <div class="md:!w-[30.77%] !w-[22%]">
        <div>
            <img class="md:!h-[15.25rem] max-[768px]:!h-[150px] max-[600px]:!h-[110px] !w-full !h-[300px] rounded-md object-cover" 
                src="{{ item.room_image_overall_url }}" alt=""
                (error)="replaceWithDefaultImage($event)">
        </div>
        <div class="w-full mt-2">
            <div class="flex flex-wrap justify-between">
                <div *ngIf="item.bed_size" class="flex items-center gap-2 w-1/2 max-[768px]:w-[100%] mt-2">
                    <img class="w-[20px] h-[20px]" src="assets/images/home/icon/area_list_of_type_room.svg" alt="">
                    <span class="relative"><p class="text-base font-normal text-palette-gray-700 whitespace-nowrap overflow-hidden text-ellipsis">{{item.bed_size}}m<sup class="text-xs">2</sup></p></span>
                </div>
                <common-icon-text *ngIf="item.num_of_windows" class="flex items-center gap-2 w-1/2 max-[768px]:w-full mt-2"
                    [icon]="'sctr-icon-columns-02'"
                    [iconStyle]="'text-[20px]'"
                    [text]="(item.num_of_windows || 0) + ((item.num_of_windows > 1) ? ' Windows' : ' Window')"
                    [textStyle]="'text-base font-normal text-palette-gray-700 whitespace-nowrap overflow-hidden text-ellipsis'">
                </common-icon-text>  
                <common-icon-text *ngIf="item.is_sea_view" class="flex items-center gap-2 w-1/2 max-[768px]:w-[full mt-2"
                    [icon]="'sctr-icon-compass-03'"
                    [iconStyle]="'text-[20px]'"
                    [text]="'Sea view'"
                    [textStyle]="'text-base font-normal text-palette-gray-700 whitespace-nowrap overflow-hidden text-ellipsis'">
                </common-icon-text>                
                <common-icon-text *ngIf="item.is_balcony" class="flex items-center gap-2 w-1/2 max-[768px]:w-full mt-2"
                    [icon]="'sctr-icon-perspective-02'"
                    [iconStyle]="'text-[20px]'"
                    [text]="'Balcony'"
                    [textStyle]="'text-base font-normal text-palette-gray-700 whitespace-nowrap overflow-hidden text-ellipsis'">
                </common-icon-text> 
            </div>
        </div>
    </div>
    <div class="flex relative md:!ml-4 md:!w-[69.23%] md:!mt-0 mt-2 w-full">
        <div class="divider absolute top-8"></div>
        <div class="md:!w-[65%] w-[65%]">
            <div class="flex flex-wrap justify-between">
                <div class="flex items-center gap-2 w-1/2" *ngIf="item.num_of_beds">
                    <img class="w-[20px] h-[20px]" src="assets/images/home/icon/bed_list_of_room_type.svg" alt="">
                    <p class="text-base font-semibold text-palette-gray-700 whitespace-nowrap overflow-hidden text-ellipsis">{{item.num_of_beds}} {{item.bed_type_name}}{{item.num_of_beds > 1 ? 's' : ''}}</p>
                </div>
                <div class="flex items-center gap-2 w-1/2" *ngIf="item.max_occupancy_per_room">
                    <img class="w-[20px] h-[20px]" src="assets/images/home/icon/guest_list_of_room_type.svg" alt="">
                    <p class="text-base font-semibold text-palette-gray-700 whitespace-nowrap overflow-hidden text-ellipsis">{{item.max_occupancy_per_room || 0}} Guest</p>
                </div>
            </div>
            <div class="flex flex-wrap justify-between mt-4 max-[1000px]:absolute {{item.num_of_beds !== null || item.max_occupancy_per_room !== null ? '' : 'pt-4 '}}">
                <div class="flex items-center gap-2 w-1/2 mb-3 max-[1000px]:w-[33.3%]" *ngFor="let item of displayHotelFacilityList">
                    <div *ngIf="item.amenity_name == '24/7 front desk'; then frontdesk; else nofrontdesk"></div>
                    <ng-template #nofrontdesk>
                        <common-icon-text *ngIf="item.amenity_icon && item.amenity_name" class="flex items-center gap-2 mb-1"
                            [icon]="'sctr-icon-' + item.amenity_icon"
                            [iconStyle]="'text-xl'"
                            [text]="item.amenity_name"
                            [textStyle]="'text-base font-normal text-palette-gray-700 whitespace-normal overflow-hidden text-ellipsis'"></common-icon-text>
                    </ng-template>
                    <ng-template #frontdesk>
                        <img src="assets/images/common-icon/247h.svg" alt="" class="w-[20px] h-[20px]">
                        <span *ngIf="item.amenity_icon && item.amenity_name" class="text-base font-normal text-palette-gray-700 whitespace-nowrap overflow-hidden text-ellipsis">{{item.amenity_name}}</span>
                    </ng-template>
                </div>
            </div>
            <div class="flex flex-wrap max-w-[500px] gap-2 mt-2 max-[1000px]:!mt-40 ">
                <p-tag *ngIf="item.is_breakfast_free" [style]="{ 'background-color': '#FFFAEB', 'border-radius': '16px', 'height': '22px'}">
                    <span class="max-w-[150px] !font-inter !text-xs !font-medium !leading-[18px] text-palette-amber-700 whitespace-nowrap overflow-hidden text-ellipsis">Breakfast</span>
                </p-tag>
                <p-tag *ngIf="item.is_free_cancellation" [style]="{ 'background-color': '#ECFDF3', 'border-radius': '16px', 'height': '22px'}">
                    <span class="!font-inter !text-xs !font-medium !leading-[18px] text-system-success-700 whitespace-nowrap overflow-hidden text-ellipsis">Free cancellation</span>
                </p-tag>
                <p-tag *ngIf="!item.is_prepayment_required" [style]="{ 'background-color': '#FFF1F3', 'border-radius': '16px', 'height': '22px'}">
                    <span class="!font-inter !text-xs !font-medium !leading-[18px] text-palette-rose-700 whitespace-nowrap overflow-hidden text-ellipsis">No prepayment required</span>
                </p-tag>
            </div>
        </div>
        <div class="relative !w-[35%]">
            <div class="whitespace-nowrap overflow-hidden text-ellipsis">
                <div *ngIf="item.num_of_available_rooms !== 0">
                    <div *ngIf="is_support_multi_room" class="absolute flex items-center top-0 right-0 gap-2 max-[525px]:max-w-[100px] max-[570px]:max-w-[150px] max-[570px]:!gap-1 max-[375px]:max-w-[80px]">
                        <img class="w-[20px] h-[20px]" src="assets/images/home/icon/room-available.svg" alt="">
                        <span
                            class="text-base text-system-success-600 font-semibold whitespace-nowrap overflow-hidden text-ellipsis ">{{item.num_of_available_rooms}}+
                            rooms available</span>
                    </div>
                </div>
                
                <div *ngIf="item.num_of_available_rooms === 0" class="absolute flex top-0 right-0 gap-2 max-[570px]:max-w-[100px] max-[570px]:!gap-1 max-[375px]:max-w-[80px]">
                    <img class="w-[20px] h-[20px]" src="assets/images/home/icon/room-available-0.svg" alt="">
                    <span class="text-base text-palette-red-600 font-semibold whitespace-nowrap overflow-hidden text-ellipsis">Out of
                        room</span>
                </div>
            </div>
            
            <div class="max-[570px]:!h-[400px] max-[768px]:!h-[300px]">
                <div class="absolute bottom-[7rem] right-0 w-[234px] leading-8">
                    <div class="absolute right-0 text-base font-normal text-palette-gray-400 line-through">
                        <app-price-format [price]="item.origin_price"></app-price-format>
                    </div>
                    <div class="absolute right-0 top-7 flex items-center">
                        <div class="text-branding-primary-700 !text-xl leading-[38px] font-semibold flex items-center price-discount-hotel !text-[20px]">
                            <app-price-format [price]="item.net_price"></app-price-format>
                        </div>
                        <span class="text-palette-gray-500 text-base font-normal ">/{{'COMMON.NIGHT' | translate: { syntax: '' } }}</span>
                    </div>
                </div>
                <div class="absolute bottom-0 right-0 flex gap-2 text-base font-semibold text-palette-gray-700">
                    <div class="btn-view-detail py-[0.625rem] px-[1.125rem] flex text-center cursor-pointer whitespace-nowrap hover:!bg-palette-gray-100"
                        (click)="handleClickRoomHotel(item)">
                        {{'DETAIL.VIEW_DETAIL' | translate}}
                    </div>
                    <div *ngIf="is_support_multi_room">
                        <div *ngIf="item.num_of_available_rooms > 0," class="btn-buy-number flex text-center relative">
                            <div class="w-1/3 cursor-pointer">
                                <img *ngIf="item.numberRoomBuy >= 1" (click)="subtractNumberOfRoom(item)"
                                    src="assets/images/home/icon/subtract-room-type-allow.svg" alt="" />
                                <img *ngIf="item.numberRoomBuy === 0" (click)="subtractNumberOfRoom(item)"
                                    src="assets/images/home/icon/subtract.svg" alt="" />
                            </div>
                            <span class="w-1/3">{{item.numberRoomBuy}}</span>
                            <div class="w-1/3 absolute right-0 top-[14px] cursor-pointer">
                                <img (click)="plusNumberOfRoom(item)"
                                    src="assets/images/home/icon/{{item.num_of_available_rooms !== item.numberRoomBuy ? 'plus' : 'plus-disable'}}.svg"
                                    alt="" />
                            </div>
                        </div>
                    </div>
                    <div *ngIf="!is_support_multi_room">
                        <div pButton
                            (click)="handleClickBookRoom(item)"
                            class="btn-book-now font-inter text-base font-semibold flex justify-center items-center whitespace-nowrap cursor-pointer">
                            {{'DETAIL.BOOK_ROOM' | translate}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<div class="hotel-page-room-type flex !gap-4 !mt-2 bg-palette-base-white border border-[#fff] rounded-lg">
    <div class="flex flex-col !gap-4 !w-[258px]">
        <div>
            <div *ngIf="item.room_image_overall_url && item.room_image_overall_id === null">
                <img class="!w-[258px] !h-[193px] object-cover rounded-lg" src={{item.room_image_overall_url}} (error)="replaceWithDefaultImage($event)" alt="">
            </div>
            <div *ngIf="item.room_image_overall_url === null && item.room_image_overall_id">
                <img class="!w-[258px] !h-[193px] object-cover rounded-lg" src="{{baseUrl}}/storage/files/web/{{item.room_image_overall_id}}.webp" (error)="replaceWithDefaultImage($event)" alt="">
            </div>
            <div *ngIf="item.room_image_overall_url === null && item.room_image_overall_id === null">
                <img class="!w-[258px] !h-[193px] object-cover rounded-lg" src="assets/images/test/default-hotel.svg" alt="">
            </div>
        </div>
        <div class="w-full !py-2 !px-4 h-[36px] text-sm font-inter font-semibold text-palette-gray-900 border border-palette-gray-300 rounded-lg flex justify-center items-center text-center cursor-pointer whitespace-nowrap hover:!bg-palette-gray-50"
            (click)="handleClickRoomHotel(item)">
            {{'DETAIL.VIEW_DETAIL' | translate}}
        </div>
        <div class="grid grid-cols-2 !gap-y-2 !m-0 !gap-x-1">
            <div *ngIf="item.room_size" class="flex items-center !gap-1">
                <i class="sctr-icon-expand-03 text-palette-gray-500 text-[16px]"></i>
                <p class="text-sm font-normal text-palette-gray-700 w-full whitespace-nowrap overflow-hidden text-ellipsis">
                    {{item.room_size}}{{item.room_size_unit === 'square meters' ? 'm²' : '{{item.room_size_unit}}'}}</p>
            </div>
            <div *ngIf="item.is_balcony" class="flex items-center !gap-1">
                <i class="sctr-icon-perspective-02 text-palette-gray-500 text-[16px]"></i>
                <p class="text-sm font-normal text-palette-gray-700 w-full whitespace-nowrap overflow-hidden text-ellipsis">
                    Balcony</p>
            </div>
            <div *ngIf="item.room_view" class="flex items-center !gap-1">
                <i class="sctr-icon-compass-03 text-palette-gray-500 text-[16px]"></i>
                <p class="text-sm font-normal text-palette-gray-700 w-full whitespace-nowrap overflow-hidden text-ellipsis">
                    Sea view</p>
            </div>
            <div class="w-full" *ngFor="let amenity of item.room_amenity_list?.slice(0, 8)">
                <div class="flex items-center !gap-1 relative">
                    <i class="sctr-icon-{{amenity.amenity_icon}} text-[16px] text-palette-gray-500"></i>
                    <span [tooltip]="amenity.amenity_name" [isTruncated]="true" [position]="TooltipPosition.RIGHT"
                        class="line-clamp-1! text-sm font-normal text-palette-gray-700 w-full whitespace-nowrap overflow-hidden text-ellipsis">
                        {{amenity.amenity_name}}
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="flex-1">
        <div class="flex flex-col !gap-2">
            <div *ngFor="let rate_plan of item.rate_plan">
                <div class="w-full !min-h-[138px] h-auto bg-palette-gray-50 !p-4 rounded-lg flex items-start font-inter !gap-2 relative {{rate_plan.best_match ? 'best-match' : ''}}">
                    <div *ngIf="rate_plan.best_match" style="background: linear-gradient(263.89deg, #FF692E 0%, #FF4405 100%);"
                        class="absolute bottom-0 left-0 w-[81px] h-[26px] rounded-tr-md rounded-bl-md text-xs !leading-[18px] font-medium text-white flex justify-center items-center">
                        Best match
                    </div>
                    <div class="w-[25%] !pr-2 h-full flex flex-col !gap-1 border-r border-dashed border-r-palette-gray-200">
                        <p class="text-xs font-normal uppercase text-palette-gray-500">{{'DETAIL.INCLUDES' | translate}}</p>
                        <div class="flex flex-col !gap-1">
                            <div *ngIf="rate_plan.meal_plan?.meal_plan_name"
                                class="flex items-center !gap-1 relative px-[6px] py-[2px] rounded-lg bg-palette-emerald-50 text-palette-emerald-600 max-w-fit">
                                <img src="assets/icons/hotel-amenities/fork_knife_icon.svg" alt="">
                                <span [tooltip]="rate_plan.meal_plan.meal_plan_name" [isTruncated]="true" [position]="TooltipPosition.BELOW"
                                    class="line-clamp-1! font-inter text-xs font-medium h-auto overflow-hidden text-ellipsis whitespace-nowrap">
                                    {{rate_plan.meal_plan.meal_plan_name}}
                                </span>
                            </div>
                            <div *ngIf="rate_plan?.inclusions && rate_plan?.inclusions?.length !== 0" class="flex items-center !gap-1 relative px-[6px] py-[2px] rounded-lg bg-palette-blue-50 text-palette-blue-600 max-w-fit">
                                <i class="sctr-icon-car-01 text-[16px]"></i>
                                <span [tooltip]="rate_plan?.inclusions[0]" [isTruncated]="true" [position]="TooltipPosition.BELOW"
                                    class="line-clamp-1! font-inter text-xs font-medium overflow-hidden text-ellipsis whitespace-nowrap">
                                    {{rate_plan?.inclusions[0]}}
                                </span>
                            </div>
                            <div *ngIf="rate_plan?.inclusions?.length > 1" class="flex items-center !gap-1 relative px-[6px] py-[2px] rounded-lg bg-palette-blue-50 text-palette-blue-600 max-w-fit">
                                <span [tooltip]="renderTooltipInclusion(rate_plan?.inclusions)" [isTruncated]="false" [position]="TooltipPosition.BELOW"
                                    class="line-clamp-1! font-inter text-xs font-medium overflow-hidden text-ellipsis whitespace-nowrap">
                                    +{{rate_plan?.inclusions.length - 1}}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="w-[25%] !px-2 border-r border-dashed border-r-palette-gray-200 h-full flex flex-col !gap-4">
                        <div *ngIf="rate_plan.cancellation" class="flex flex-col !gap-1">
                            <p class="text-xs font-normal uppercase text-palette-gray-500">{{'DETAIL.CANCELLATION' | translate}}</p>
                            <div class="flex items-center !gap-2">
                                <p class="text-sm font-medium text-palette-gray-700">{{rate_plan.cancellation}}</p>
                                <div class="relative" *ngIf="rate_plan.cancellation_policy">
                                    <i class="sctr-icon-info-circle text-[16px] text-palette-gray-400 cursor-pointer"
                                        (mouseenter)="rate_plan.showTooltipPolicy = true" (mouseleave)="rate_plan.showTooltipPolicy = false"></i>
                                    <div *ngIf="rate_plan.showTooltipPolicy"
                                        class="background-tooltip absolute z-[9999] right-[-80%] top-[110%] min-w-[350px] min-[370px]:min-w-[150px] min-[500px]:min-w-[250px] font-inter text-xs font-semibold rounded-lg text-center bg-opacity-90 text-palette-base-white p-2 shadow">
                                        {{rate_plan.cancellation_policy}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="rate_plan.is_prepayment_required !== null" class="flex flex-col !gap-1 relative">
                            <p class="text-xs font-normal uppercase text-palette-gray-500">{{'DETAIL.PAYMENT' | translate}}</p>
                            <div [tooltip]="rate_plan.is_prepayment_required ? ('DETAIL.PREPAYMENT' | translate) : ('DETAIL.NO_PREPAYMENT' | translate)" [isTruncated]="true" [position]="TooltipPosition.ABOVE"
                                class="line-clamp-1! font-inter text-sm font-medium text-palette-gray-700 overflow-hidden text-ellipsis whitespace-nowrap">
                                {{rate_plan.is_prepayment_required ? ('DETAIL.PREPAYMENT' | translate) : ('DETAIL.NO_PREPAYMENT' | translate) }}
                            </div>
                        </div>
                    </div>
                    <div class="w-[25%] !px-2 border-r border-dashed border-r-palette-gray-200 h-full flex flex-col !gap-4">
                        <div class="flex flex-col !gap-1">
                            <p class="text-xs font-normal uppercase text-palette-gray-500">{{'DETAIL.GUEST' | translate}}</p>
                            <div>
                                <p *ngIf="rate_plan.max_adult_per_room" class="text-sm font-medium text-palette-gray-700">
                                    {{rate_plan.max_adult_per_room[0]}} {{rate_plan.max_adult_per_room[0] > 1 ? 'adults' : 'adult'}}<span *ngIf="rate_plan.max_children_per_room.length !== 0" class="text-sm font-medium text-palette-gray-700">
                                        , {{rate_plan.max_children_per_room[0]}} {{rate_plan.max_children_per_room[0] >= 2 ? 'children' : 'child'}}</span>
                                </p>
                            </div>
                        </div>
                        <div class="flex flex-col !gap-1" *ngIf="rate_plan?.tax_data">
                            <p class="text-xs font-normal uppercase text-palette-gray-500">{{'DETAIL.TAXES_AND_CHARGES' | translate}}</p>
                            <div class="flex items-center !gap-2">
                                <p class="text-sm font-medium text-palette-gray-700">
                                    {{getTotalTaxeCharge(rate_plan?.tax_data.taxes)}} {{rate_plan?.tax_data.taxes[0].currency_code}}
                                </p>
                                <div class="relative">
                                    <i class="sctr-icon-info-circle text-[16px] text-palette-gray-400 cursor-pointer"
                                        (mouseenter)="rate_plan.showTooltipTaxes = true" (mouseleave)="rate_plan.showTooltipTaxes = false"></i>
                                    <div *ngIf="rate_plan.showTooltipTaxes"
                                        class="background-tooltip absolute z-[9999] right-[-80%] top-[110%] min-w-[350px] min-[370px]:min-w-[150px] min-[500px]:min-w-[250px] font-inter text-xs font-semibold rounded-lg text-center bg-opacity-90 !px-4 !py-2 shadow flex flex-col !gap-1">
                                        <div *ngFor="let item of rate_plan?.tax_data.taxes">
                                            <div class="flex items-center font-inter !gap-2 text-xs font-normal text-white">
                                                <p class="w-[65%] text-left">{{convertToTitleCase(item.name)}}</p>
                                                <p class="w-auto">{{item.amount}} {{item.currency_code}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="w-[25%] !pl-2 h-full">
                        <div class="flex flex-col justify-end">
                            <div class="text-sm font-normal text-palette-gray-400 line-through ml-auto">
                                <app-price-format [price]="rate_plan.origin_price"></app-price-format>
                            </div>
                            <div class="flex items-center mt-[2px] ml-auto">
                                <div class="text-palette-blue-600 text-xl !text-[22px] leading-[30px] font-semibold">
                                    <app-price-format [price]="rate_plan.net_price"></app-price-format>
                                </div>
                                <span class="text-palette-gray-500 text-sm font-normal ">/{{'COMMON.NIGHT' | translate: { syntax: '' }
                                    }}</span>
                            </div>
                            <div class="!mt-2 ml-auto">
                                <div *ngIf="is_support_multi_room"
                                    class="border border-palette-gray-300 bg-white w-[119px] h-[44px] rounded-md flex items-center justify-center relative">
                                    <div class="cursor-pointer absolute top-[50%] left-0 translate-y-[-45%] pl-[10px]">
                                        <i class="sctr-icon-minus text-[16px] text-palette-gray-600"
                                            (click)="subtractNumberOfRoom(rate_plan)"></i>
                                    </div>
                                    <span class="text-sm font-semibold text-palette-gray-600">{{rate_plan.numberRoomBuy}}</span>
                                    <div class="absolute right-0 top-[50%] cursor-pointer translate-y-[-45%]">
                                        <i class="sctr-icon-plus text-[16px] text-branding-primary-700 pr-[10px]"
                                            (click)="plusNumberOfRoom(rate_plan, item)"></i>
                                    </div>
                                </div>

                                <div *ngIf="!is_support_multi_room" class="!mt-1">
                                    <div pButton (click)="handleClickBookRoom(rate_plan, item)"
                                        class="btn-book-now font-inter text-sm font-semibold flex justify-center items-center whitespace-nowrap cursor-pointer">
                                        {{'DETAIL.BOOK_ROOM' | translate}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>