import { DateTime } from 'luxon';
import { Pipe, PipeTransform, inject } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DateFormat } from '@shared/enum/date-format.enum';
import { SoctripTranslationService } from 'src/app/services/soctrip-translation/soctrip-translation.service';

@Pipe({
  name: 'customDate',
})
export class CustomDatePipe extends DatePipe implements PipeTransform {
  private translationService = inject(SoctripTranslationService);
  private localeString = this.translationService.getLocale();

  override transform(
    value: any,
    format?: string,
    tz?: string,
    tzFormat?: string,
    isConvertDate: boolean = false
  ): any {
    try {
      const offset = DateTime.now().setZone(tz);
      const dateValue = new Date(value).toISOString();
      const dateFormat =
        format && (Object.values(DateFormat) as string[]).includes(format) ? format : 'DATE_SHORT';

      const dateTime = DateTime.fromISO(dateValue, {
        zone: isConvertDate ? tz ?? undefined : undefined,
      })
        .setLocale(this.localeString)
        .toLocaleString({ ...DateTime[dateFormat as keyof typeof DateTime] });
      if (!tzFormat) {
        return dateTime;
      } else {
        if (tzFormat === DateFormat.OFFSET) {
          return `${dateTime} (UTC${offset.toFormat('ZZ')})`;
        }
        if (tzFormat === DateFormat.OFFSET_AND_ABB) {
          return `${dateTime} (UTC${offset.toFormat('ZZ')} ${offset.toFormat('ZZZZ')})`;
        }
        return dateTime;
      }
    } catch (error) {
      return '';
    }
  }
}
