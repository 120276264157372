<input #uploader hidden type="file" (change)="uploadFile($event)" />
<div class="mb-1 text-md font-medium text-gray-700 md:hidden">
  {{ title | translate }}
</div>
<div class="flex !h-11">
  <div
    class="flex !w-[35.554%] items-center truncate rounded-bl-lg rounded-tl-lg border bg-gray-100 !px-[11px] !py-[7px]"
  >
    <span
      class="!text-md !font-normal text-gray-700"
      pTooltip="{{ (title ? title : attach.title) | translate }}"
      [showDelay]="300"
      tooltipPosition="top"
      tooltipStyleClass="t-tooltip-light"
    >
      <span class="hidden md:inline-block">
        {{ (title ? title : attach.title) | translate }}
      </span>
      <span class="flex items-center gap-1 md:hidden">
        <i class="sctr-icon-attachment-01 text-md text-gray-700"></i>
        {{ "OTA.CONTRACT_AGREEMENT.ATTACHMENT" | translate }}
      </span>
    </span>
  </div>
  <div
    class="flex !w-[64.446%] items-center justify-between rounded-br-lg rounded-tr-lg border-b border-r border-t !px-[11px] !py-[7px]"
  >
    <div
      class="flex-1 overflow-hidden whitespace-nowrap text-ellipsis mr-2"
      [ngClass]="{ 'hover:cursor-pointer': !readOnly }"
      (click)="!readOnly && uploader.click()"
    >
      <span
        pTooltip="{{ attach.file?.name }}"
        class="overflow-hidden whitespace-nowrap text-ellipsis !text-md !font-normal text-gray-700"
      >
        {{ attach.file?.name }}
      </span>
      <span *ngIf="!attach.file" class="!text-md !font-normal text-gray-400">
        {{ "OTA.CONTRACT_AGREEMENT.CLICK_UPLOAD_FILE" | translate }}
      </span>
    </div>
    <div class="flex flex-shrink-0 hover:cursor-pointer">
      <i
        *ngIf="isClose && !readOnly"
        (click)="onRemove()"
        class="sctr-icon-trash-03 hover:text-primary-700 !px-1.5 text-gray-700"
      ></i>
      <i
        *ngIf="!readOnly"
        (click)="uploader.click()"
        class="sctr-icon-upload-01 hover:text-primary-700 !px-1.5 text-gray-700"
      ></i>
      <i
        (click)="enablePreview ? onPreview(attach) : ''"
        class="sctr-icon-eye hover:text-primary-700 !px-1.5 text-gray-700"
        [ngClass]="!enablePreview ? 'opacity-0 cursor-default' : ''"
      ></i>
      <i
        *ngIf="readOnly"
        (click)="onDownload(attach)"
        class="sctr-icon-download-01 hover:text-primary-700 !px-1.5 text-gray-700"
      >
      </i>
    </div>
  </div>
</div>
