<div
  *ngIf="visible"
  [ngClass]="stylesClass"
  class="flex h-60 w-full flex-col items-center justify-center gap-2 bg-white"
>
  <img
    class="inline-block h-20 w-20 min-w-[5rem] object-cover object-center"
    src="assets/images/empty-data.svg"
  />
  <span class="text-sm !font-medium text-gray-400"> No data </span>
</div>
