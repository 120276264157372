<div
  *ngIf="
    !!dataDetailCar?.document_options ||
    !!dataDetailCar?.term_of_user ||
    !!dataDetailCar?.collateral_price
  "
  [ngClass]="{
    'shadow-sx col-span-2  mt-3 rounded-xl border border-palette-gray-200 bg-white px-4 pb-4':
      isDependencyPanel
  }"
>
  <ng-container *ngIf="isShowRental">
    <p class="!mb-3 !pt-4 text-base font-semibold text-palette-gray-800 md:!text-xl">
      {{ 'APP_RENTAL_DOCUMENT.RENTAL_DOCUMENT' | soctripTranslate | async }}
    </p>
    <div *ngIf="!!dataDetailCar?.document_options">
      <p class="text-soctrip-small overflow-hidden text-ellipsis font-normal text-[#344054]">
        {{ 'APP_RENTAL_DOCUMENT.BEFORE_PICKING_UP_THE_CAR' | soctripTranslate | async }}
      </p>
      <div class="grid grid-cols-1 gap-3 px-2 py-4 md:grid-cols-3">
        <ng-container *ngFor="let item of dataDetailCar?.document_options">
          <div
            class="col-span-1 flex items-center rounded-md bg-branding-primary-50 p-2"
            *ngIf="item.code == 'Driver_license'"
          >
            <div class="flex items-center rounded-full bg-branding-primary-100 p-2">
              <img src="assets/icons/check-list.svg" />
            </div>
              <div class="ml-2 flex flex-col gap-0.5">
              <span class="overflow-hidden text-ellipsis text-md font-medium !text-gray-700">
                {{ 'APP_RENTAL_DOCUMENT.DRIVER_LICENSE' | soctripTranslate | async }}</span
              >
              <span
                class="text-soctrip-small overflow-hidden text-ellipsis font-normal !text-gray-500"
                >{{ 'APP_RENTAL_DOCUMENT.CROSS_REFERENCING' | soctripTranslate | async }}</span
              >
            </div>
          </div>
          <div
            class="col-span-1 flex items-center rounded-md bg-[#EFF8FF] p-2"
            *ngIf="item.code == 'ID_card'"
          >
            <div class="">
              <div class="flex items-center rounded-full bg-[#D1E9FF] p-2">
                <img src="assets/icons/check-list.svg" />
              </div>
            </div>
            <div class="ml-2 flex flex-col gap-0.5">
              <span class="overflow-hidden text-ellipsis text-md font-medium !text-gray-700">{{
                'APP_RENTAL_DOCUMENT.ID_CARD' | soctripTranslate | async
              }}</span>
              <span
                class="text-soctrip-small overflow-hidden text-ellipsis font-normal !text-gray-500"
                >{{ 'APP_RENTAL_DOCUMENT.CROSS_REFERENCING' | soctripTranslate | async }}</span
              >
            </div>
          </div>
          <div
            class="col-span-1 flex items-center rounded-md bg-[#EFF8FF] p-2"
            *ngIf="item.code == 'Passport'"
          >
            <div class="">
              <div class="flex items-center rounded-full bg-[#D1E9FF] p-2">
                <img src="assets/icons/check-list.svg" />
              </div>
            </div>
            <div class="ml-2 flex flex-col gap-1">
              <span class="overflow-hidden text-ellipsis text-md font-medium !text-gray-700">{{
                'APP_RENTAL_DOCUMENT.PASSPORT' | soctripTranslate | async
              }}</span>
              <span
                class="text-soctrip-small overflow-hidden text-ellipsis font-normal !text-gray-500"
                >{{ 'APP_RENTAL_DOCUMENT.RETAIN' | soctripTranslate | async }}</span
              >
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>


  <div *ngIf="!!dataDetailCar?.collateral_price && dataDetailCar?.is_required_collateral" class="!mb-6">
    <p class="!mb-3 text-base font-semibold text-palette-gray-800 md:!text-xl">
      {{ 'APP_RENTAL_DOCUMENT.COLLATERAL' | soctripTranslate | async }}
    </p>
    <div class="rounded-lg bg-palette-blue-50 px-4 py-2 text-md text-palette-gray-900">
      <p>
        {{
          'MY_BOOKING.COLLATERAL_DETAIL'
            | soctripTranslate : { amount: (getCollateralPrice() | currency: (currencyConversion?.currency || 'USD')) } | async
        }}
      </p>
    </div>
  </div>
  <div *ngIf="!!dataDetailCar?.term_of_user" class="border-t">
    <p class="!mb-3 !pt-4 text-base font-semibold text-palette-gray-800 md:!text-xl">
      {{ 'APP_RENTAL_DOCUMENT.TERM_OF_USE' | soctripTranslate | async }}
    </p>
    <div class="pb-4">
      <p class="whitespace-pre-wrap" [innerHTML]="dataDetailCar?.term_of_user"></p>

      <div
        class="mt-3 flex flex-col items-start gap-2 rounded-lg border border-branding-primary-100 bg-branding-primary-50 p-3 text-branding-primary-600"
      >
        <div class="flex gap-2">
          <i class="sctr-icon-info-circle text-[20px]"></i>
          <p class="text-[14px]">
            {{ 'APP_RENTAL_DOCUMENT.SOCTRIP_IS_NOT_RESPONSIBLE' | soctripTranslate | async }}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
