import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TotalPaymentService {
  private value: any;
  private totalPayment = new BehaviorSubject<any>({});
  private flagScrollListRoom = new Subject<void>();
  private minPriceHotel = new BehaviorSubject<any>({});
  private isLoadingPrice = new BehaviorSubject<boolean>(false);

  setdataPayment(newValue: any) {
    this.value = newValue;
    this.totalPayment.next(newValue);
  }

  getdataPayment(): Observable<any> {
    return this.totalPayment.asObservable();
  }

  notiFlagScrollListRoom(): void {
    this.flagScrollListRoom.next();
  }

  getFlagScrollListRoom(): Subject<void> {
    return this.flagScrollListRoom;
  }

  // set and get min price room for render general section hotel
  setMinPriceHotel(priceHotel: any) {
    this.minPriceHotel.next(priceHotel);
  }

  getMinPriceHotel(): Observable<any> {
    return this.minPriceHotel.asObservable();
  }

  setIsLoadingPrice(priceHotel: any) {
    this.isLoadingPrice.next(priceHotel);
  }

  getIsLoadingPrice(): Observable<any> {
    return this.isLoadingPrice.asObservable();
  }

  scrollToRoomList(targetId: string): void {
    const targetElement = document.querySelector(targetId);
    if (targetElement) {
      window.scrollTo({
        behavior: 'smooth',
        top:
          targetElement.getBoundingClientRect().top -
          document.body.getBoundingClientRect().top +
          30,
      });
    }
  }
}
