import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RoundNumberService {
  constructor() {}

  roundNumber(number: number, decimals: number = 2): number {
    const multiplier = Math.pow(10, decimals);
    const adjustedNumber = number * multiplier;
    const roundedNumber = Math.round(adjustedNumber);
    return roundedNumber / multiplier;
  }

  roundNumberDown(number: number, decimals: number = 2): number {
    const multiplier = Math.pow(10, decimals);
    const adjustedNumber = number * multiplier;
    const roundedNumber = Math.floor(adjustedNumber);
    return roundedNumber / multiplier;
  }
}
