<app-modal
  [showDialog]="isShowReport"
  (emitOnCloseDialog)="handleCloseModal()"
  [dialogConfig]="dialogConfig"
>
  <ng-container modal-header>
    <div class="text-xl font-semibold text-gray-700">
      {{ 'REPORT_DIALOG.REPORT' | translate }}
    </div>
  </ng-container>

  <ng-container modal-content>
    <div class="flex flex-col bg-white !px-6">
      <span class="text-md font-medium text-gray-700">
        {{ 'REPORT_DIALOG.REPORT_REASON' | translate }}
      </span>
      <div class="flex gap-4 !pb-4 !pt-2" *ngIf="contentReport">
        <div class="flex flex-wrap gap-2">
          <div class="flex" *ngFor="let item of contentReport; let i = index">
            <p-chip [class.selected]="isChipSelected(item.name)" (click)="toggleChip(item.name)">
              <div class="apply-color cursor-pointer !py-1.5 font-inter text-md">
                {{ item.name }}
              </div>
            </p-chip>
          </div>
        </div>
      </div>
      <div class="flex flex-col gap-1.5">
        <span class="text-md font-medium text-gray-700">
          {{ 'REPORT_DIALOG.OTHER' | translate }}
        </span>
        <textarea
          class="!mb-1 h-full rounded-lg text-md"
          [placeholder]="'REPORT_DIALOG.ENTER_DESCRIPTION' | translate"
          rows="5"
          cols="30"
          pInputTextarea
          [(ngModel)]="textareaValue"
          (ngModelChange)="checkAllFields()"
        ></textarea>
      </div>
    </div>
  </ng-container>

  <ng-container modal-footer>
    <div class="flex flex-row items-center justify-end gap-3">
      <button
        class="h-10 rounded-lg border border-solid border-gray-300 bg-white !px-4"
        type="submit"
        (click)="handleCloseModal()"
      >
        <p class="text-base font-semibold text-gray-700">
          {{ 'REPORT_DIALOG.CANCEL' | translate }}
        </p>
      </button>
      <button
        class="h-10 rounded-lg !px-4 text-white"
        type="submit"
        [ngClass]="{
          'border-blue-200 !bg-blue-200': !isDisabled,
          '!bg-blue-600': isDisabled
        }"
        [disabled]="!isDisabled"
        (click)="handleConfirmReport()"
      >
        {{ 'REPORT_DIALOG.CONFIRM' | translate }}
      </button>
    </div>
  </ng-container>
</app-modal>
