import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { BehaviorSubject, of } from 'rxjs';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  public userRoles = new BehaviorSubject<string[]>(
    this.userService.getUserInfoFromLocalStorage()
  );
  private userPermissionSubject = new BehaviorSubject<string | null>(null);
  userPermission$ = this.userPermissionSubject.asObservable();

  constructor(
    private tokenStorageService: TokenStorageService,
    private authService: AuthService,
    private userService: UserService
  ) {}
  nextPermissions(per: string) {
    this.userPermissionSubject.next(per);
  }

  checkPermission(requiredRoles: string[], roles: string[]) {
    const tokenId = this.tokenStorageService.getToken(); // || get token from cookie
    const isTokenValid = this.authService.isTokenExpired();
    if (!roles) {
      roles = this.userService.getUserInfoFromLocalStorage();
    }
    // Check if the token is expired or invalid
    if (tokenId && !isTokenValid) {
      const hasPermission = requiredRoles.some((permission) =>
        roles.includes(permission)
      );
      return hasPermission;
    }
    return false;
  }

  checkOtaPermission(requiredPermission: string[], permissions: string) {
    const tokenId = this.tokenStorageService.getToken();
    const isTokenValid = this.authService.isTokenExpired();

    if (tokenId && !isTokenValid) {
      const hasPermission = requiredPermission.some((per) => permissions.includes(per));
      return hasPermission;
    }
    return false;
  }
}
