import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BehaviorSubject, Subject, mergeMap, of, takeUntil } from 'rxjs';
import { MessageService } from 'primeng/api';
import {
  BookmarkControllerService,
  BookmarkGroupBasicDTO,
  BookmarkGroupControllerService,
  BookmarkGroupDetailDTO,
} from '@soctrip/angular-bookmarks-service';
import { ToastType } from '@shared/enum/toast-type';
import { DialogModule } from 'primeng/dialog';
import { CheckboxModule } from 'primeng/checkbox';
import { FormsModule } from '@angular/forms';
import { DividerModule } from 'primeng/divider';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ToastModule } from 'primeng/toast';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SoctripTranslationService } from 'src/app/services/soctrip-translation/soctrip-translation.service';

@Component({
  selector: 'app-dialog-save-list-car',
  standalone: true,
  imports: [
    CommonModule,
    DialogModule,
    CheckboxModule,
    FormsModule,
    DividerModule,
    ToastModule,
    OverlayPanelModule,
    TranslateModule,
    ScrollPanelModule,
  ],
  templateUrl: './dialog-save-list-car.component.html',
  styleUrls: ['./dialog-save-list-car.component.scss'],
})
export class DialogSaveListCarComponent {
  @Input() detailId = '';
  @Input() visible: boolean;
  @Input() key: string;
  @Output() disable = new EventEmitter();
  listSave: BookmarkGroupDetailDTO[];
  id: string;
  name: string;
  openInput: boolean;
  newSave: Array<{ name: string; id: string }> = [];
  inputValue: string = '';
  spaceValid: boolean;
  emptyValid: boolean;
  idAddBookmark: string;
  previousSave: any[] = [];
  selectedSave: any[] = [];
  bodyGroupCreateBookmark: any;
  private destroy$ = new Subject<void>();

  constructor(
    private messageService: MessageService,
    private bookmarkControllerService: BookmarkControllerService,
    private bookmarkGroupControllerService: BookmarkGroupControllerService,
    private translateService: TranslateService,
    private soctripTranslationService: SoctripTranslationService
  ) {}
  ngOnInit(): void {
    this.newSave = [
      {
        name: this.translateService.instant('toast_save_car.new_collection'),
        id: 'new collection',
      },
    ];
    let openBookmark = new BehaviorSubject<{
      open: boolean;
      type: string;
      id: string;
      name: string;
    }>({ open: false, type: '', id: '', name: '' });
    openBookmark.pipe(takeUntil(this.destroy$)).subscribe((value) => {});
  }
  ngOnChanges(changes: SimpleChanges) {
    if (this.visible) {
      this.listCarCollections();
    }
  }
  listCarCollections() {
    this.bookmarkGroupControllerService
      .bookmarksGroupsSearchTabGet(0, 1000, 'CARRENTAL')
      .subscribe((value) => {
        this.listSave = value?.data?.data!;
        this.selectedSave = [this.listSave[0]];
      });
  }
  onChange(value: string, value1: string) {
    this.id = value;
    this.name = value1;
    const select = this.selectedSave[this.selectedSave.length - 1];
    this.selectedSave.length = 0;
    this.selectedSave.push(select);
    if (value === 'new collection' && this.selectedSave[0] !== undefined) {
      this.openInput = true;
    } else if (value !== 'new collection' || this.selectedSave[0] === undefined) {
      this.openInput = false;
    }
  }
  checkValidinput(data: string) {
    if (/\s$/.test(data) && !/\w/.test(data)) {
      this.spaceValid = true;
      this.emptyValid = false;
    } else if (data === '') {
      this.spaceValid = false;
      this.emptyValid = true;
    } else if (data !== '' && !/\s$/.test(data) && /\w/.test(data)) {
      this.spaceValid = false;
      this.emptyValid = false;
    }
  }
  blurInput(data: string) {
    if (data === '') {
      this.emptyValid = true;
    }
  }
  checkUpdateOrAddNew() {
    if (this.selectedSave[0].id !== 'new collection') {
      this.updateBookmark();
    } else if (this.selectedSave[0].id === 'new collection') {
      this.addToBookmarkGroup();
    }
  }
  onClose() {
    this.visible = false;
    this.openInput = false;
    this.inputValue = '';
    this.disable.emit();
  }
  updateBookmark() {
    this.bookmarkControllerService
      .bookmarksObjectIdGroupGroupIdPut(this.detailId, this.id)
      .subscribe({
        next: (value) => {},
        error: () => {
          this.messageService.add({
            key: this.key,
            severity: ToastType.ERROR,
            detail: this.soctripTranslationService.getTranslationWithParam(
              'toast_save_car.save_tour_failed',
              {
                param: this.name,
              }
            ),
          });
        },
        complete: () => {
          this.messageService.add({
            key: this.key,
            severity: ToastType.SUCCESS,
            detail: this.soctripTranslationService.getTranslationWithParam(
              'toast_save_car.save_tour_success',
              {
                param: this.name,
              }
            ),
          });
          this.onClose();
        },
      });
  }
  objectGroupBookmark(
    name: string,
    cate: number,
    subCate: Array<number>,
    img: string
  ): BookmarkGroupBasicDTO {
    return {
      name: name,
      object_category: cate,
      object_sub_categories: subCate,
      image: img,
    };
  }
  addToBookmarkGroup() {
    this.bodyGroupCreateBookmark = this.objectGroupBookmark(this.inputValue, 2, [23], '');
    this.bookmarkGroupControllerService
      .bookmarksGroupsPost(this.bodyGroupCreateBookmark)
      .pipe(
        mergeMap((value) => {
          if (value.success) {
          }
          return of(value);
        })
      )
      .subscribe((value) => {
        this.bookmarkControllerService
          .bookmarksObjectIdGroupGroupIdPut(this.detailId, value?.data?.id)
          .subscribe({
            next: (value) => {},
            error: () => {
              this.messageService.add({
                key: this.key,
                severity: ToastType.ERROR,
                detail: this.soctripTranslationService.getTranslationWithParam(
                  'toast_save_car.save_tour_failed',
                  {
                    param: this.inputValue,
                  }
                ),
              });
            },
            complete: () => {
              this.messageService.add({
                key: this.key,
                severity: ToastType.SUCCESS,
                detail: this.soctripTranslationService.getTranslationWithParam(
                  'toast_save_car.save_tour_success',
                  {
                    param: this.inputValue,
                  }
                ),
              });
              this.onClose();
            },
          });
      });
  }
}
