import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { ImagesService } from '@modules/my-booking/services/image/media.service';
import { FileControllerService } from '@soctrip/angular-storage-service';
import { environment } from '@env/environment';

@Component({
  selector: 'app-popup-qr-code',
  templateUrl: './popup-qr-code.component.html',
  styleUrls: ['./popup-qr-code.component.scss']
})
export class PopupQrCodeComponent implements OnInit {
  @Input() qrCode: any;
  @Input() bookingId: any;
  @Output() closePopupEvent = new EventEmitter<boolean>();

  public isShowPopup: boolean = true;
  public urlImg: any;

  constructor(private imagesService: ImagesService, private fileControllerService: FileControllerService) {}

  ngOnInit(): void {
    this.getQRCode();
  }

  getQRCode () {
    this.fileControllerService.getFile(this.qrCode).subscribe({
      next: (url) => {
        if(url) {
          this.urlImg = `${environment.baseURL}/storage/files/web/${this.qrCode}.webp`;
        }
      },

    });
  }

  stopPropagationEvent(event: Event): void {
    event.stopPropagation();
  }

  public downloadImage(url: string, filename: string) {
      this.imagesService.downloadImage(url, filename);
  }

  public closePopup() {
    this.isShowPopup = false;
    this.closePopupEvent.emit(false);
    //console.log(this.qrCode);
  }
}
