import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  public currentLanguage$ = new BehaviorSubject<string>('');

  constructor() {
    const availableLocale = JSON.parse(localStorage.getItem(environment.USER_PROFILE)!)?.language;
    this.changeLanguage(availableLocale || 'en');
  }

  changeLanguage(value: string) {
    this.currentLanguage$.next(value);
  }
}
