import { Component, Input } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';

import { CopyService } from '@modules/car-booking/services/copy/copy.service';
import { CarDetail } from '@modules/car-booking/models/view-details';

import { ToastType } from '@shared/enum/toast-type';

import { UserService } from 'src/app/core/services/user/user.service';
import { SoctripTranslationService } from 'src/app/services/soctrip-translation/soctrip-translation.service';

@Component({
  selector: 'app-share-hotel',
  templateUrl: './share-hotel.component.html',
  styleUrls: ['./share-hotel.component.scss'],
})
export class ShareHotelComponent {
  @Input() dataDetailCar: CarDetail;

  overlayVisible = false;
  overlayVisibleShareToFeed = false;
  visiblePleaseSignIn = false;
  isLoggedIn: boolean | null = false;

  constructor(
    public dialogService: DialogService,
    private messageService: MessageService,
    private copyService: CopyService,
    private soctripTranslationService: SoctripTranslationService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.userService.getLoginStatus().subscribe((data) => {
      if (data !== null) {
        this.isLoggedIn = data;
      }
    });
  }

  onClickIconShare() {
    this.overlayVisible = !this.overlayVisible;
  }

  onCopyLink() {
    const currentURL = window.location.href;
    const successful = this.copyService.copyToClipboard(currentURL);
    if (successful) {
      this.messageService.add({
        key: 'cp',
        severity: ToastType.SUCCESS,
        detail: this.soctripTranslationService.getTranslation('MESSAGE.LINK_COPIED'),
      });
    }
  }

  onClickShareToFeed() {
    if (this.isLoggedIn) {
      this.overlayVisibleShareToFeed = !this.overlayVisibleShareToFeed;
    } else {
      this.visiblePleaseSignIn = !this.visiblePleaseSignIn;
    }
  }

  visibleShareToFeedModuleChange(event: any) {
    this.overlayVisibleShareToFeed = !this.overlayVisibleShareToFeed;
    if (event.postStatus) {
      if (event.postStatus) {
        this.messageService.add({
          severity: ToastType.SUCCESS,
          detail: this.soctripTranslationService.getTranslation('POST.POST_SUCCESSFUL'),
        });
      } else {
        this.messageService.add({
          severity: ToastType.ERROR,
          detail: this.soctripTranslationService.getTranslation('POST.POST_FAILED'),
        });
      }
    }
  }

  visiblePleaseSignInChange() {
    this.visiblePleaseSignIn = false;
  }
}
