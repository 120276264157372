import { Injectable, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable, of, map, catchError, combineLatest, switchMap, take, filter, defaultIfEmpty, finalize, interval, takeWhile, tap, throwError } from 'rxjs';
import { environment } from '@env/environment';
import {
  RentalAgencyControllerService,
} from '@car-rental/angular-car-rental-ota-service';
import { CAR, CAR_OTA } from '@shared/constants/routes';
import { UserRolesResolverService } from '../../services/user/roles.service';
import { FirstLoadAuthInfoService } from '@core/services/first-load-auth-info/first-load-auth-info.service';

@Injectable({
  providedIn: 'root',
})
export class AgencyRedirectorGuard implements CanActivate {
  private rentalAgencyControllerService = inject(RentalAgencyControllerService);
  private router = inject(Router);
  private userRoleResolverSrv = inject(UserRolesResolverService);
  private firstLoadAuthInfoService = inject(FirstLoadAuthInfoService);
  loader = false;
  private timeout = 0;
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const isByPassGuard = this.router.getCurrentNavigation()?.extras.state?.['byPassGuard'];
    if (isByPassGuard) return of(true);
    this.loader = true;
    let isStopWaiting = false;
    // Pauses execution until user profile information is available.
    return interval(100) // Check every 100 milliseconds
      .pipe(
        takeWhile(() => !isStopWaiting && this.timeout < 10000), // Stop checking after 8 seconds or already has user profile
        tap(() => (this.timeout += 100)), // Increment timeout counter
        filter(() => !!localStorage.getItem(environment.USER_PROFILE)), // Check if user profile exists
        switchMap(() => {
          isStopWaiting = true;
          return this.handleNavigation();
        }),
        catchError(() => {
          // Handle errors (optional)
          return throwError(() => new Error('Timeout reached'));
        }),
        finalize(() => {
          isStopWaiting = true;
          this.loader = false;
          if (!localStorage.getItem(environment.USER_PROFILE)) {
          }
        }),
        defaultIfEmpty(this.router.createUrlTree(['/'])) // If no value emitted, redirect to home
      );
  }
  handleNavigation() {
    return this.firstLoadAuthInfoService.firstLoadAuthInfo$.pipe(
      filter((firstLoadComplete) => firstLoadComplete), // Wait until the first load is complete
      take(1), // Only take the first emission of true
      switchMap(() => {
        const userProfile = localStorage.getItem(environment.USER_PROFILE);
        const user_id = JSON.parse(userProfile as string)?.id;

        return combineLatest({
          userAgency: this.rentalAgencyControllerService.getVehicleByUserId1(user_id),
          userRoles: this.userRoleResolverSrv.getAgencyRoles(),
        }).pipe(
          map(({ userAgency, userRoles }) => {
            const agencies = userRoles.data?.agencies ?? [];
            const agencyId = userAgency.data;
            let url: string = '/';
            let navigationExtras = {};

            if (!agencyId && agencies.length === 0) {
              url = `${environment.prefix}/${CAR_OTA.BECOME_AGENCY}`;
              navigationExtras = { state: { byPassGuard: true } };
            } else if (agencyId && agencies.length === 1) {
              url = `${environment.prefix}/${CAR_OTA.CAR_OTA}/${CAR_OTA.AGENCY}/${agencyId}/${CAR_OTA.CAR_MANAGEMENT}`;
            } else if (agencies.length >= 1) {
              url = `${environment.prefix}/${CAR_OTA.CAR_OTA}/${CAR_OTA.AGENCY_CENTER}`;
            }

            this.router.navigate([url], navigationExtras);
            return false;
          }),
          catchError((err) => {
            if (err.status === 404)
              this.router.navigate([`${environment.prefix}/${CAR_OTA.BECOME_AGENCY}`], {
                state: { byPassGuard: true },
              });
            else this.router.navigate(['/']);
            return of(false);
          })
        );
      }),
      catchError(() => {
        this.router.navigate(['/']);
        return of(false);
      })
    );
  }
}
