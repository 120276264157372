import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FirstLoadAuthInfoService {
  private isFirstLoadAuthInfoSubject: BehaviorSubject<boolean> = new BehaviorSubject<any>(false);
  private hasFirstLoaded: boolean = false;
  public firstLoadAuthInfo$: Observable<any> = this.isFirstLoadAuthInfoSubject.asObservable();

  constructor() {}

  updateFirstLoadAuthInfo() {
    if (!this.hasFirstLoaded) {
      this.isFirstLoadAuthInfoSubject.next(true);
      this.hasFirstLoaded = true;
    }
  }
}
