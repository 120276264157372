import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { environment } from '@env/environment';
import { NotificationsService } from './notifications.service';
import { OverlayPanel } from 'primeng/overlaypanel';
import moment from 'moment';

export const NotificationType = {
  RequestNewFriend: 'request_new_friend',
  TagYouInPost: 'tag_you_in_post',
  TagYouInComment: 'tag_you_in_comment',
  UploadEvent: 'upload_event',
  NewPost: 'new_post',
  ReactOnPost: 'react_on_post',
  Event: 'event',
  Livestream: 'livestream',
  Memory: 'memory',
  Gift: 'gift',
};

export const ModuleType = {
  social: 'SOCIAL',
  shop: 'SHOP',
  restaurant: 'RESTAURANT',
  hotel: 'HOTEL',
  travel_tour: 'TRAVEL_TOUR',
  car: 'CAR',
};

export const FriendRequest = {
  ACCEPTED: 'accept',
  DECLINED: 'decline',
};

@Component({
  selector: 'app-header-notifications',
  templateUrl: './header-notifications.component.html',
  styleUrls: ['./header-notifications.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderNotificationsComponent implements OnInit {
  @Input() overlayVisible: boolean;
  @Input() mobileOverlayVisible: boolean;
  @Output() updateBadgeCount = new EventEmitter<any>();
  @Output() mobileOverlayVisibleChange = new EventEmitter<any>();
  @ViewChildren('overlayPanelElement') overlayPanels!: QueryList<OverlayPanel>;
  environment = environment;
  badgeCount: any;
  notificationList: any[] = [];

  loadingAccept = false;
  loadingReject = false;
  currentActiveItem = -1;
  showButtonIndex: number | null = null;
  indexOverlay: number;

  constructor(private notificationsService: NotificationsService) {}

  @HostListener('window:scroll', ['$event'])
  handleHideOverlayPanel() {
    if (typeof this.indexOverlay === 'number') {
      this.overlayPanels.toArray()[this.indexOverlay].hide();
    }
  }

  ngOnInit(): void {
    this.getNotificationList();
  }

  getNotificationList() {
    const pageNum = 0;
    const pageSize = 100;
    this.notificationsService
      .getNotifications(this.environment.baseURL, pageNum, pageSize)
      .subscribe((data) => {
        if (data && data.data) {
          const notifications = data.data;
          notifications.sort((a: any, b: any) => {
            return +new Date(a.send_time) - +new Date(b.send_time);
          });
          this.notificationList = notifications.reverse();
          this.setCountNoti(data.totalElement || 0);
          this.loadingAccept = false;
          this.loadingReject = false;
        }
      });
  }

  markAllReadNotification(event: Event) {
    event.stopPropagation();
    this.notificationsService.markAllReadNotification(this.environment.baseURL).subscribe(() => {
      this.getNotificationList();
    });
  }

  processNotificationAction(ctaURL: any, id: any, currentNotiIndex: number, isAccept: boolean) {
    this.currentActiveItem = currentNotiIndex;
    isAccept ? (this.loadingAccept = true) : (this.loadingReject = true);
    this.notificationsService
      .callToActionNotification(this.environment.baseURL, ctaURL)
      .subscribe(() => {
        this.notificationsService
          .completeCTANotification(this.environment.baseURL, id)
          .subscribe((res) => {
            if (res) {
              this.notificationList[currentNotiIndex] = {
                ...res,
                cta_accept: isAccept ? FriendRequest.ACCEPTED : FriendRequest.DECLINED,
              };
              this.updateNotfiCount();
            }
          });
      });
  }

  updateNotfiCount(): void {
    const pageNum = 0;
    const pageSize = 100;
    this.notificationsService
      .getNotifications(this.environment.baseURL, pageNum, pageSize)
      .subscribe((data) => {
        if (data && data.data) this.setCountNoti(data.totalElement || 0);
      });
  }

  toggleNotification(id: any, event: Event) {
    event.stopPropagation();
    this.notificationsService
      .toggleReadNotification(this.environment.baseURL, id)
      .subscribe((data) => {
        // this.handleUpdateBadgeCount(data);
        this.getNotificationList();
      });
    const notificationIndex = this.notificationList.findIndex((item) => item.id === id);
    if (notificationIndex !== -1) {
      this.notificationList[notificationIndex]._read =
        !this.notificationList[notificationIndex]._read;
    }
  }

  deleteNotification(id: any, event: Event, isRead: boolean) {
    event.stopPropagation();
    this.notificationsService.deleteNotification(this.environment.baseURL, id).subscribe((data) => {
      this.handleNotificationDeletion(isRead);
      this.getNotificationList();
    });
  }

  handleNotificationDeletion(isRead: boolean) {
    if (!isRead) {
      this.decrementBadgeCount();
    }
  }

  handleCountBadge(count: number | string) {
    this.updateBadgeCount.emit(count);
  }

  handleUpdateBadgeCount(data: any) {
    if (data && data._read) {
      this.decrementBadgeCount();
    } else {
      this.incrementBadgeCount();
    }
  }

  incrementBadgeCount() {
    this.badgeCount += 1;
    this.handleCountBadge(this.badgeCount);
  }

  decrementBadgeCount() {
    if (this.badgeCount > 0) {
      this.badgeCount -= 1;
      this.handleCountBadge(this.badgeCount);
    }
  }

  setCountNoti(totalElement: number) {
    let countNoti = '0';
    if (totalElement >= 100) {
      countNoti = '99+';
    } else if (totalElement <= 99) {
      countNoti = totalElement + '';
    }
    this.badgeCount = countNoti;
    this.handleCountBadge(countNoti);
  }

  get friendRequest() {
    return FriendRequest;
  }

  onMouseEnter(index: number) {
    this.showButtonIndex = index;
  }

  onMouseLeave() {
    this.showButtonIndex = null;
  }

  navigateToAccountSettings() {
    return this.environment.SOCIAL_APP_URL + '/account-settings?setting=notifications';
  }

  closeDialog() {
    this.mobileOverlayVisibleChange.emit(false);
  }

  navigationUrl(item: any, event: Event) {
    !item._read && this.toggleNotification(item.id, event);
    this.overlayVisible = false;
    switch (item.module_type) {
      case ModuleType.travel_tour:
        window.location.href = this.environment.TRAVEL_ASSIST_URL + item.web_link;
        break;
      case ModuleType.car:
        window.location.href =
          this.environment.CAR_RENTAL_URL + '/' + environment.prefix + item.web_link;
        break;
      case ModuleType.hotel:
        window.location.href = this.environment.BOOKING_URL + item.web_link;
        break;
      case ModuleType.restaurant:
        window.location.href = this.environment.ECOMMERCE_FOOD_URL + item.web_link;
        break;
      case ModuleType.shop:
        window.location.href = this.environment.ECOMMERCE_URL + item.web_link;
        break;
      default:
        window.location.href = this.environment.SOCIAL_APP_URL + item.web_link;
        break;
    }
    localStorage.setItem('groupDetailEvent', 'active');
    localStorage.setItem('activeIndexGroup', '3');
  }

  getAvatar(id: string) {
    if (!id) {
      return '';
    }
    const isPath = id.startsWith('/');
    const webPath = isPath ? '' : '/storage/files/web/';
    const addWebp = isPath ? '' : '.webp';

    return `${this.environment.baseURL}${webPath}${id}${addWebp}`;
  }

  getColorIcon(notificationType: string): {
    background: string;
    color: string;
  } {
    switch (notificationType) {
      case NotificationType.RequestNewFriend:
        return {
          background: 'bg-palette-yellow-100',
          color: 'text-palette-yellow-500',
        };
      case NotificationType.TagYouInPost:
        return {
          background: 'bg-palette-amber-100',
          color: 'text-palette-amber-500',
        };
      case NotificationType.TagYouInComment:
        return {
          background: 'bg-branding-primary-100',
          color: 'text-branding-primary-500',
        };
      case NotificationType.UploadEvent:
        return {
          background: 'bg-palette-rose-100',
          color: 'text-palette-rose-500',
        };
      case NotificationType.NewPost:
        return {
          background: 'bg-palette-violet-100',
          color: 'text-palette-violet-500',
        };
      case NotificationType.ReactOnPost:
        return {
          background: 'bg-palette-fuchsia-100',
          color: 'text-palette-fuchsia-500',
        };
      case NotificationType.Event:
        return {
          background: 'bg-palette-rose-100',
          color: 'text-palette-rose-500',
        };
      case NotificationType.Livestream:
        return {
          background: 'bg-palette-red-100',
          color: 'text-palette-red-500',
        };
      case NotificationType.Memory:
        return {
          background: 'bg-palette-teal-100',
          color: 'text-palette-teal-500',
        };
      case NotificationType.Gift:
        return {
          background: 'bg-palette-orange-100',
          color: 'text-palette-orange-500',
        };
      default:
        return {
          background: 'bg-branding-primary-100',
          color: 'text-branding-primary-500',
        };
    }
  }

  transformBoldStyledBody(styledBody: string): string {
    return styledBody.replace(/<bold>(.*?)<\/bold>/g, '<strong>$1</strong>');
  }

  calculateFromNow(date: any) {
    return moment(date)
      .locale(localStorage.getItem('lang') || 'en')
      .fromNow();
  }
}
