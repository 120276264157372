<div class="toast-booking">
  <p-toast position="top-right" [key]="'save'">
    <ng-template let-message pTemplate="message">
      <div class="flex flex-col" style="flex: 1">
        <div class="flex gap-3">
          <i
            class="font-semibold"
            [ngClass]="[
              message.severity === 'success' ? 'sctr-icon-check-circle' : 'sctr-icon-alert-circle'
            ]"
            style="font-size: 20px"
          ></i>
          <p class="pb-2 text-md font-medium text-black">
            {{ message.detail }}
          </p>
        </div>
        <div class="pl-[20px]">
          <button
            class="px-[12px] py-[4px] hover:rounded-lg hover:border-solid hover:bg-[#EFF8FF]"
            (click)="openDialog()"
          >
            <p class="text-primary-600 text-md font-semibold">
              {{ 'toast_save_car.change' | translate }}
            </p>
          </button>
        </div>
      </div>
    </ng-template>
  </p-toast>
</div>
<div *ngIf="!mobileView; else mobileTemp"
  class="relative rounded-lg border border-[#EAECF0] bg-white bg-opacity-5 shadow-sm max-md:!mb-2 max-md:!p-2 md:!mb-3 md:flex md:!p-4"
>
  <!-- <div
        *ngIf="item.best_match"
        class="!h-[26px] !px-2 !py-1 bg-gradient-to-br from-[#FF692E] to-[#FF4405] absolute bottom-0 left-0 rounded-tr-md rounded-bl-md z-[5]">
        <p class="!h-[18px] font-inter !text-xs !font-medium !leading-[18px] text-palette-base-white">Best match</p>
    </div> -->
  <div
    class="relative max-md:!h-[148px] max-md:!w-full md:!h-[186px] md:w-1/3 md:min-w-[33.33%] md:max-w-[33.33%]"
  >
    <img
      class="!h-full min-w-full !max-w-full cursor-pointer rounded-lg border !object-cover"
      [src]="getPropertyImageId(item)"
    />
    <!-- <img class="!h-full min-w-full !max-w-full border rounded-lg !object-cover cursor-pointer"
            src="{{ item.property_image_overview_url || getPropertyImageId(item) }}"
            (error)="replaceWithDefaultImage($event)"
            (click)="quickViewPhotos($event, item.property_image_urls|| convertImgIdsToImages(item.property_image_ids))"> -->
    <div
      (click)="handleBookmark($event, item.id)"
      class="absolute right-2 top-2 bg-[#10182866] max-md:!h-7 max-md:!w-7 max-md:rounded-md max-md:!p-1.5 md:!h-9 md:!w-9 md:rounded-lg md:!p-2"
    >
      <span *ngIf="isBookmark; then favourite; else unfavourite"></span>
      <ng-template #favourite>
        <img class="!h-5 !w-5" src="assets/images/common-icon/bookmark-close.svg" alt="" />
      </ng-template>
      <ng-template #unfavourite>
        <img class="!h-5 !w-5" src="assets/images/common-icon/bookmark-open.svg" alt="" />
      </ng-template>
    </div>
  </div>
  <div
    class="flex flex-col max-md:!mt-2 md:!ml-4 md:!w-2/3 md:min-w-[calc(66.66%-16px)] md:max-w-[calc(66.66%-16px)]"
  >
    <div class="flex flex-row justify-between">
      <!-- <div class="max-md:!w-[80%] max-md:!h-9 2xl:!w-[65%] xl:max-2xl:!w-[50%] md:max-xl:!w-[50%] md:!h-12"></div> -->
      <div class="max-md:!w-[80%]">
        <div
          (mouseenter)="showStarTooltip = true"
          (mouseleave)="showStarTooltip = false"
          class="relative"
        >
          <app-star
            [count]="item.star"
            starStyle="!gap-0.5"
            iconStyle="sctr-icon-solid-star-01 text-palette-amber-400 text-xl"
          >
          </app-star>
        </div>
        <div>
          <p
            #hotelName
            (mouseover)="mouseOverHotelName()"
            class="truncate font-inter !font-semibold text-palette-gray-900 max-md:!text-base md:!text-xl"
          >
            {{ item.vehicle_info?.car_model_name }} {{ item.vehicle_info?.year_model }}
          </p>
          <span class="line-clamp-1 text-[14px] text-gray-600">
            {{ item.location?.district?.name }}, {{ item.location?.province?.name }}, {{ item.location?.country?.name }}
          </span>
          <div
            class="tooltip-hotel-name z-100 absolute inline-block whitespace-nowrap rounded-lg bg-white !px-3 py-2 text-center text-xs font-semibold text-palette-gray-700 transition-opacity duration-200 ease-in"
          >
            {{ item.vehicle_info?.car_model_name }}
          </div>
        </div>
      </div>
      <div>
        <div class="flex w-fit items-center justify-end">
          <div class="flex flex-col items-end gap-1" *ngIf="item.sum_review">
            <p
              class="mb-[2px] flex w-[40px] items-center justify-center rounded-md border border-palette-blue-700 text-right font-inter !font-medium text-palette-blue-700 md:!text-base"
            >
              {{ item.point }}
            </p>
            <p class="whitespace-nowrap font-inter !font-normal text-palette-gray-500 md:!text-sm">
              {{ 'pluralization.total_reviews' | translate: { count: item.sum_review } }}
            </p>
            <!-- <div *ngIf="!isVote" class="w-full ">
                            <p class="md:!text-base font-inter !font-medium text-right text-palette-gray-700">{{item.sum_review}}</p>
                            <p class="md:!text-sm font-inter !font-normal text-palette-gray-500">{{ item.sum_review }} reviews</p>
                        </div> -->
            <!-- <app-rating-score [rating]="item.sum_review"
                            [style]="'max-md:absolute max-md:top-0 max-md:right-0 md:!ml-4 relative max-md:!px-2 max-md:!py-1 max-md:!text-xs max-md:!leading-[18px] md:!px-3 md:!py-1 md:!text-base md:!h-8 border border-palette-blue-600 text-palette-blue-600'"
                            [tooltipStyle]="'bottom-1/2 right-[120%] translate-y-[50%] w-[150px] min-[370px]:w-[180px]'">
                        </app-rating-score> -->
          </div>
          <div class="flex justify-center" *ngIf="!item.sum_review">
            <p class="whitespace-nowrap font-inter !text-sm !font-medium text-palette-gray-500">
              {{ 'COMMON.NEW_ON_SOCTRIP' | translate }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-between max-md:!h-[auto] md:!mb-2 md:!mt-3">
      <div class="relative flex flex-col max-md:!w-[90%] md:!w-[80%]">
        <!-- <div class="max-md:!w-[80%] md:!w-auto max-md:!py-2 flex relative align-middle">
                    <i class="sctr-icon-marker-pin-01 !text-lg !leading-[18px] text-palette-gray-500"></i>
                    <div #hotelAddress (mouseover)="mouseOverHotelAddress()" (click)="viewMap(item)"
                        class="max-md:!text-xs md:!text-sm font-inter !font-medium text-palette-gray-500 !ml-1 truncate cursor-pointer">
                        {{ item.location.district.name }}</div>
                    <div
                        class="absolute inline-block !px-3 !py-2 text-xs font-semibold text-center transition-opacity duration-200 ease-in bg-white rounded-lg tooltip-hotel-address z-100 text-palette-gray-700 whitespace-nowrap">
                        {{ item.location.province.name }}</div>

                </div> -->
        <!-- <div class="flex align-middle">
                    <p *ngIf="item.distance_from_center"
                        class="max-md:!text-xs md:!text-sm font-inter !font-medium text-palette-gray-500 w-[18px] text-center">
                        &nbsp;•&nbsp;</p>
                    <div *ngIf="item.distance_from_center" #hotelDistance (mouseover)="mouseOverHotelDistance()"
                        class="max-md:!text-xs md:!text-sm font-inter !font-medium text-palette-gray-500 !ml-1 truncate">
                        {{ item.distance_from_center }} km from centre</div>
                    <div
                        class="absolute inline-block !px-3 !py-2 text-xs font-semibold text-center transition-opacity duration-200 ease-in bg-white rounded-lg tooltip-hotel-distance z-100 text-palette-gray-700 whitespace-nowrap">
                        {{ item.distance_from_center }} km from centre</div>
                </div> -->
      </div>
      <div *ngIf="isVote" class="max-md:!w-[20%]">
        <p
          class="text-right font-inter !font-medium text-palette-gray-700 max-md:!text-xs max-md:!leading-[18px]"
        >
          {{ item.sum_review }}
        </p>
        <p
          class="text-right font-inter !font-normal text-palette-gray-500 max-md:!text-[10px] max-md:!leading-[16px]"
        >
          {{ item.sum_review }} {{ 'SEARCH_RESULT.car_list.automaliter' | translate }}
        </p>
      </div>
    </div>
    <!-- <div *ngIf="item.room_amenity_list.length > 0" class="max-md:flex max-md:!gap-2 max-md:!mt-1 md:!hidden">
            <ng-container  *ngFor="let facility of item.room_amenity_list.slice(0,5)">
                <div class="!h-4 !w-4">
                    <img class="w-full h-full" src="assets/images/home/icon/{{ facility }}.svg">
                </div>
            </ng-container>
        </div> -->
    <!-- <div class="max-md:!mt-3 max-md:relative max-md:!h-6 md:flex md:justify-between price-hidden">
            <div class="max-md:!hidden">
                <p class="md:!text-base font-inter !font-medium text-palette-gray-700">{{ item.room_type_name || 'One-Bedroom Apartment' }}</p>
            </div>
            <div *ngIf="isPrice && !item.best_match" class="max-md:absolute max-md:right-0">
                <div class="max-md:!text-sm max-md:!ml-0.5 md:!text-base md:!ml-0.5 line-through font-inter !font-normal !text-palette-gray-500">
                    <app-price-format [price]="item.origin_price"></app-price-format>
                </div>
            </div>grid grid-cols-3 gap-2
        </div> -->
    <div class="flex flex-1 flex-col justify-end gap-3">
      <div class="relative flex items-end justify-between">
        <div class="w-full max-w-[350px]">
          <app-car-highlight [dataDetailCar]="item"></app-car-highlight>
        </div>
        <div class="bottom-0 right-0 col-span-1">
          <div class="max-md:absolute max-md:bottom-0 max-md:right-0 md:!ml-4">
            <div class="flex items-end justify-end">
              <div class="flex justify-end max-md:relative md:!h-full md:items-end">
                <div
                  class="w-max-md:!hidden font-inter !font-medium !text-palette-gray-400 line-through md:!ml-0.5 md:flex md:items-center md:!text-base md:!leading-tight"
                >
                  <app-price-format
                    [keepValue]="userCurrency === item.currency"
                    *ngIf="item.original_price > item.price"
                    [price]="
                      userCurrency === item?.currency
                        ? item.original_price_canal
                        : item.original_price
                    "
                  ></app-price-format>
                </div>
              </div>
              <div class="flex justify-end max-md:relative md:!h-full md:items-end">
                <div
                  class="!ml-0.5 flex items-center font-inter !font-bold !text-palette-orange-dark-500 max-md:!text-[24px] max-md:!leading-[30px] md:!ml-1.5 md:!text-[24px] md:!leading-none"
                >
                  <app-price-format
                    [keepValue]="userCurrency === item.currency"
                    [price]="userCurrency === item?.currency ? item?.price_canal : item?.price"
                  ></app-price-format>
                </div>
              </div>
            </div>
            <div class="flex justify-end max-md:relative md:!h-full md:items-end">
              <span
                class="text-soctrip-small ml-1 w-max overflow-hidden text-ellipsis text-[14px] font-normal text-palette-gray-500"
              >
                {{ 'SEARCH_RESULT.car_list.taxes_charges' | translate }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="max-md:!hidden md:flex md:justify-between md:items-end">
        <div #policy class="overflow-hidden md:max-h-[3.75rem] md:w-full md:flex md:flex-wrap md:gap-y-2">
          <app-tag
            *ngFor="let policy of displayPolicy"
            [type]="policy.type"
            [item]="policy.name"
          ></app-tag>
          <!-- <span #hotelPolicy (mouseover)="mouseOverHotelPolicy()" *ngIf="hasRemainingPolicy" class="md:!h-[22px] md:!py-[2px] md:!px-2 md:rounded-2xl md:!mr-2 font-inter !text-xs !font-medium !leading-[18px] bg-[#EAECF0] text-palette-gray-700">{{ remainingPolicy }}</span>
                  <div *ngIf="hotelPolicyArray" class="tooltip-hotel-policy absolute inline-block !px-3 py-2 text-xs font-semibold text-center transition-opacity duration-200 ease-in bg-white rounded-lg z-100 text-palette-gray-700 whitespace-nowrap">
                      {{ slicePolicy }}
                  </div> -->
        </div>
        <div>
          <button
            class="rounded-lg bg-[#1570EF] font-inter !font-semibold text-palette-base-white md:!w-[162px] md:!px-[18px] md:!py-2.5 md:!text-base"
            (click)="viewDetail(item.id, item.description, item)"
          >
            {{ 'SEARCH_RESULT.car_list.view_detail' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #mobileTemp>
  <div class="rounded-lg border border-[#EAECF0] bg-white bg-opacity-5 shadow-sm flex flex-col py-[0.75rem] gap-2 mb-[8px]" (click)="viewDetail(item.id, item.description, item)">
    <div class="t-mob-card-title flex justify-between gap-2 items-center px-[0.75rem]">
      <div class="w-full">
        <p
          #hotelName
          (mouseover)="mouseOverHotelName()"
          class="truncate font-inter !font-semibold text-palette-gray-900 text-[14px]/5"
        >
          {{ item.vehicle_info?.car_model_name }}
        </p>
        <span class="line-clamp-1 text-[12px]/4 text-system-gray-500">
          {{ item.location?.district?.name }}, {{ item.location?.province?.name }}, {{ item.location?.country?.name }}
        </span>
        <div
          class="tooltip-hotel-name z-100 absolute inline-block whitespace-nowrap rounded-lg bg-white !px-3 py-2 text-center text-xs font-semibold text-palette-gray-700 transition-opacity duration-200 ease-in"
        >
          {{ item.vehicle_info?.car_model_name }}
        </div>
      </div>
      <button class="t-bookmark-btn flex items-center bg-white p-1.5 border border-system-gray-300 rounded-lg" (click)="handleBookmark($event, item.id)">
        <i
          [ngClass]="isBookmark ? 'sctr-icon-solid-bookmark text-[#FDB022]' : 'sctr-icon-bookmark text-system-gray-500'"
          class="text-base/4"
        ></i>
      </button>
    </div>
    <div class="t-mob-card-body flex gap-2 px-[0.75rem]">
      <div
        class="relative w-[132px] aspect-[3/2] flex"
      >
        <div
          class="absolute left-1 top-1 bg-[#10182866] rounded-[4px] px-2 py-1"
          *ngIf="item?.point"
        >
          <p
            class="font-inter !font-medium text-white text-sm/4"
          >
            {{ item.point }}
          </p>
        </div>
        <img
          class="min-w-[132px] w-[132px] aspect-[3/2] rounded-md cursor-pointer border !object-cover"
          [src]="getPropertyImageId(item)"
        />
      </div>
      <div class="flex flex-col flex-wrap gap-2">
        <app-car-highlight [dataDetailCar]="item" displayUnitType="SHORT" class="[&_span]:text-sm/4"></app-car-highlight>
        <div #policy *ngIf="displayPolicy?.length" class="flex flex-wrap gap-1">
          <app-tag
            [type]="displayPolicy[0].type"
            [item]="displayPolicy[0].name"
            class="[&_span]:px-1.5 [&_span]:py-0.5 [&_div]:w-fit [&_div]:rounded-full inline-table whitespace-nowrap"
          ></app-tag>
          <div class="flex gap-2">
            <app-tag
              *ngIf="displayPolicy.length >= 2"
              [type]="displayPolicy[1].type"
              [item]="displayPolicy[1].name"
              class="[&_span]:px-1.5 [&_span]:py-0.5 [&_div]:w-fit [&_div]:rounded-full inline-table whitespace-nowrap"
            ></app-tag>
            <div *ngIf="displayPolicy.length - 2 > 0" class="flex rounded-full px-[8px] py-[2px] bg-[#EFF8FF]" pTooltip="{{'SEARCH_RESULT.car_list.view_detail' | translate}}"
              (click)="viewDetail(item.id, item.description, item)"
            >
              <span class="text-sm text-branding-primary-600 font-normal"> + {{ displayPolicy.length - 2 }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="t-mob-card-footer border-t border-t-system-gray-200 pt-[0.5rem] px-[0.75rem]">
      <div class="flex items-center justify-end">
        <div class="flex justify-end">
          <div class="font-inter !font-medium !text-palette-gray-400 line-through">
            <app-price-format
              [keepValue]="userCurrency === item.currency"
              *ngIf="item.original_price > item.price"
              [price]="
                userCurrency === item?.currency
                  ? item.original_price_canal
                  : item.original_price
              "
              class="[&_div]:text-md"
            ></app-price-format>
          </div>
        </div>
        <div class="flex justify-end ">
          <div
            class="!ml-0.5 flex items-center font-inter !font-bold !text-palette-orange-dark-500"
          >
            <app-price-format
              [keepValue]="userCurrency === item.currency"
              [price]="userCurrency === item?.currency ? item?.price_canal : item?.price"
              class="[&_div]:text-xl"
              ></app-price-format>
          </div>
        </div>
      </div>
      <div class="flex justify-end">
        <span
          class="text-soctrip-small ml-1 w-max overflow-hidden text-ellipsis text-[12px] font-normal text-palette-gray-500"
        >
          {{ 'SEARCH_RESULT.car_list.taxes_charges' | translate }}
        </span>
      </div>
    </div>
  </div>
</ng-template>
<app-toast></app-toast>
<app-dialog-save-list-car
  [visible]="visible"
  [detailId]="item.id"
  (disable)="closeDialog()"
></app-dialog-save-list-car>
