import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
})
export class PaginatorComponent {
  RowsPerPageOptions = [5, 10, 20];
  @Input() pagination: { rows: number; page: number };
  @Input() totalRecords: number;
  @Input() dataLength: number;
  @Input() isLoading = false;
  @Input() isCompact = false;
  @Input() stylesClass = '';

  @Output() onChange = new EventEmitter();
  @Output() paginationChange = new EventEmitter<{
    rows: number;
    page: number;
  }>();

  onPageChange(event: any) {
    const { page } = event;
    this.pagination.page = page;
    this.paginationChange.emit(this.pagination);
    this.onChange.emit();
  }

  onRowsChange(rows: number) {
    this.pagination.rows = rows;
    this.pagination.page = 0;
    this.paginationChange.emit(this.pagination);
    this.onChange.emit();
  }
}
