import { MapSearchService } from '@modules/car-booking/services/map-search/map-search.service';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { IProperty } from '@modules/car-booking/models/hotel-search.model';
import { environment } from '@env/environment';

@Component({
  selector: 'app-list-of-hotel-on-map',
  templateUrl: './hotel-result-card-on-map.component.html',
  styleUrls: ['./hotel-result-card-on-map.component.scss']
})
export class HotelResultCardOnMap implements AfterViewInit {
  @Input() item: IProperty | any;
  @Output("openPopup") openPopup: EventEmitter<any> = new EventEmitter();
  @ViewChild('selectedCardRef', { static: false }) selectedCardRef!: ElementRef;
  @ViewChild('propertyName', { static: false }) propertyName!: ElementRef<HTMLElement>;
  isDisplayToolTip: boolean = false;

  isToggle: boolean = true;
  isFavorite: boolean = false;
  baseUrl = environment.BASE_PATH;
  baseImageId = environment.STORAGE_SERVICE_API_BASE_PATH_ID_IMAGE;

  constructor(private mapSearchService: MapSearchService, private cd: ChangeDetectorRef){}

  ngAfterViewInit(): void {
    this.isEllipsisActive();
    this.cd.detectChanges();
  }

  isEllipsisActive() {
    if (this.propertyName) {
      const { scrollWidth, clientWidth } = this.propertyName.nativeElement;
      this.isDisplayToolTip = scrollWidth > clientWidth;
    }
  }

  public handleFavorite(event: Event) {
    event.stopPropagation();
    this.isFavorite = !this.isFavorite;
  }

  public renderStar(number: number): any {
    return [...Array(number)];
  }

  childClick(event: Event) {
    this.deselectAll();
    this.mapSearchService.setMarker(this.item)
    this.selectedCardRef.nativeElement.classList.add('selected-card')
    this.openPopup.emit()
  }

  deselectAll() {
    document.querySelectorAll('[id^="map-"]').forEach(element => {
      element.classList.remove('selected-card');
    }
    );
  }

  replaceWithDefaultImage(event: any): void {
    event.target.src = 'assets/images/test/default-hotel.svg';
  }
}
