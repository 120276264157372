import { ActivatedRouteSnapshot, RouterStateSnapshot, Routes } from '@angular/router';
import { environment } from '@env/environment';
import { ADMIN, CAR, CAR_OTA } from '@shared/constants/routes';
import { AuthGuard } from './core/guards/auth/auth.guard';
import { PermissionGuard } from './core/guards/permission/permission.guard';
import { AgencyRedirectorGuard } from './core/guards/agency-redirector/agency-redirector.guard';
import { RedirectorComponent } from '@modules/car-ota/components/redirector/redirector.component';
import { UserRolesResolverService } from './core/services/user/roles.service';
import { inject } from '@angular/core';
import { MainComponent } from '@modules/car-ota/components/become-agency/main/main.component';

export const APP_ROUTES: Routes = [
  {
    path: '',
    redirectTo: `${environment.prefix}`,
    pathMatch: 'full',
  },
  {
    path: `${environment.prefix}`,
    // canActivate: [AuthGuard],
    // loadChildren: () =>
    //   import('@modules/booking/booking.routes').then(({ BOOKING_ROUTES }) => BOOKING_ROUTES),
    children: [
      {
        path: '',
        loadChildren: () =>
          import('@modules/car-booking/car-detail.routes').then(
            ({ HOTEL_BOOKING_ROUTES }) => HOTEL_BOOKING_ROUTES
          ),
      },
      {
        path: 'my-booking',
        // canActivate: [AuthGuard],
        loadChildren: () =>
          import('./modules/my-booking/my-booking.routes').then(
            ({ MY_BOOKING_ROUTES }) => MY_BOOKING_ROUTES
          ),
      },
      {
        path: `${CAR.CAR_RATING}/:id`,
        // canActivate: [AuthGuard],
        loadComponent: () =>
          import(
            './modules/car-booking/components/detail/approval-review-card/approval-review-card.component'
          ).then(({ ApprovalReviewCardComponent }) => ApprovalReviewCardComponent),
      },
      {
        path: `${CAR_OTA.BECOME_AGENCY}`,
        // canActivate: [AuthGuard, PermissionGuard],
        canActivate: [PermissionGuard, AgencyRedirectorGuard],
        data: {
          roles: ['USER'],
        },
        loadChildren: () =>
          import('@modules/car-ota/components/become-agency/become-agency.module').then(
            (module) => module.BecomeAgencyModule
          ),
      },
      {
        path: `${CAR_OTA.CAR_OTA}`,
        canActivate: [AgencyRedirectorGuard],
        component: RedirectorComponent,
        pathMatch: 'full',
      },
      {
        path: `${CAR_OTA.CAR_OTA}/${CAR_OTA.AGENCY_CENTER}`,
        component: MainComponent,
        children: [
          {
            path: '',
            title: 'Agency center',
            loadComponent: () =>
              import('./modules/car-ota/components/agency-center/agency-center.component').then(
                ({ AgencyCenterComponent }) => AgencyCenterComponent
              ),
          },
        ],
      },
      {
        path: `${CAR_OTA.CAR_OTA}/${CAR_OTA.AGENCY}/:${CAR_OTA.OTA_ID}`,
        // canActivate: [AuthGuard, PermissionGuard],
        canActivate: [PermissionGuard],
        data: {
          roles: ['USER'],
        },
        loadChildren: () =>
          import('./modules/car-ota/car-ota.module').then((module) => module.CarOTAModule),
        resolve: {
          data: (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
            inject(UserRolesResolverService).resolve(route, state),
        },
      },
      {
        path: 'become-agency-infos/:id',
        loadComponent: () =>
          import(
            './modules/car-ota/components/become-agency/components/car-agency-infos-iframe/car-agency-infos-iframe.component'
          ).then(({ CarAgencyInfosIframeComponent }) => CarAgencyInfosIframeComponent),
        title: 'Confirm agency',
      },
      {
        path: `${ADMIN.CAR_APPROVAL}/:id`,
        loadComponent: () =>
          import('./modules/admin-approval/car-approval/car-approval.component').then(
            ({ CarApprovalComponent }) => CarApprovalComponent
          ),
      },

      {
        path: '**',
        loadComponent: () =>
          import('./layout/page-not-found/page-not-found.component').then(
            ({ PageNotFoundComponent }) => PageNotFoundComponent
          ),
        title: 'Page Not Found',
      },
    ],
  },

  {
    path: 'sign-in',
    loadComponent: () =>
      import('./modules/users/components/sign-in/sign-in.component').then(
        ({ SignInComponent }) => SignInComponent
      ),
    title: environment.PRODUCT_NAME + ': Sign In',
  },
  {
    path: 'sign-up',
    loadComponent: () =>
      import('./modules/users/components/sign-up/sign-up.component').then(
        ({ SignUpComponent }) => SignUpComponent
      ),
    title: environment.PRODUCT_NAME + ': Sign Up',
  },
  {
    path: 'common/version',
    loadComponent: () =>
      import('./modules/common/version/version.component').then(
        ({ VersionComponent }) => VersionComponent
      ),
    title: 'checking version',
  },
  {
    path: '**',
    loadComponent: () =>
      import('./layout/page-not-found/page-not-found.component').then(
        ({ PageNotFoundComponent }) => PageNotFoundComponent
      ),
    title: 'Page Not Found',
  },
];
