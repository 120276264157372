import {
  Component,
  ElementRef,
  Input,
  QueryList,
  Renderer2,
  ViewChildren,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ICarItem, PropertyAmenityList } from '@modules/car-booking/models/hotel.model';
import { Router } from '@angular/router';
import { BookMarkService } from '@modules/car-booking/services/bookmark/bookmark.service';
import { ToastType } from '@shared/enum/toast-type';
import { MessageService } from 'primeng/api';
import { BookmarkControllerService } from '@soctrip/angular-bookmarks-service';
import { TotalPaymentService } from '@modules/car-booking/services/total-payment.service';
import { HotelSearchRequest } from '@modules/car-booking/models/hotel-search.model';
import { DEFAULT_SEARCH_PARAMS } from '@shared/constant';
import { PlaceType } from '@shared/enum/recommend-place.enum';
import { TooltipPosition, TooltipTheme } from '@shared/enum/tooltip.enums';
import { Subject, takeUntil } from 'rxjs';
import { environment } from '@env/environment';

const { BloomFilter } = require('@soctrip-common/soctrip-algorithm/dist/api.js');
@Component({
  selector: 'app-featured-hotel-card',
  templateUrl: './featured-hotel-card.component.html',
  styleUrls: ['./featured-hotel-card.component.scss'],
  providers: [MessageService],
})
export class FeaturedHotelCardComponent implements OnInit {
  @Input() item: ICarItem;
  private ngUnsubscribe = new Subject<void>();
  baseImageId = environment.STORAGE_SERVICE_API_BASE_PATH_ID_IMAGE;

  numbersArray: number[] = [];
  isShowMore: boolean = false;
  invalidIconName: string = 'check-circle-broken';
  isBookmark: boolean;
  showScoreTooltip = false;
  showStarTooltip = false;
  TooltipPosition: typeof TooltipPosition = TooltipPosition;
  TooltipTheme: typeof TooltipTheme = TooltipTheme;

  constructor(
    private router: Router,
    private renderer: Renderer2,
    private bookMarkService: BookMarkService,
    private messageService: MessageService,
    private bookmarkControllerService: BookmarkControllerService,
    private totalPaymentService: TotalPaymentService
  ) {}

  ngOnInit(): void {
    this.checkBookmark();
    this.handleSyncBookmark();
  }

  handleSyncBookmark(): void {
    this.bookMarkService
      .getRecentItem()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        if (data && data.object_id === this.item.id && data.section === 'hotDeal') {
          if (data.type === 'create') {
            this.isBookmark = true;
          } else {
            this.isBookmark = false;
          }
        }
      });
  }

  renderStar(number: number): any {
    return [...Array(number)];
  }

  formatCurrency(price: number) {
    return price.toLocaleString('en-US');
  }

  handleBookmark(event: any, item: any) {
    event.stopPropagation();
    if (!this.bookMarkService.isAllow) {
      this.messageService.add({
        key: 'fh',
        severity: ToastType.WARNING,
        detail: 'Please login before bookmark',
      });
    } else {
      if (this.isBookmark) {
        this.isBookmark = false;
        this.bookMarkService
          .deleteBookmark(item.property_id, 'featured')
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe();
        this.bookMarkService.deleteItemOfMySavedList(item.property_id);
      } else {
        this.isBookmark = true;
        this.bookMarkService
          .createBookMark(item.property_id, 'featured')
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe();
        this.bookMarkService.addItemToMySavedList(item);
      }
    }
  }

  checkBookmark() {
    this.bookMarkService.apiSuccess$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((success) => {
      if (success) {
        let bloomFilter = BloomFilter.load(this.bookMarkService.bloomFilter);
        const exists = bloomFilter.has(this.item.id);
        if (exists) {
          this.bookmarkControllerService.bookmarksObjectIdIsExistedGet(this.item.id).subscribe({
            next: (res) => {
              if (res.success) {
                this.isBookmark = true;
              }
            },
          });
        } else {
          this.isBookmark = false;
        }
      }
    });
  }

  openMap(event: any, item: ICarItem) {
    event.stopPropagation();
    const queryParams = {
      property_id: item.id,
    };
    this.router.navigate(['/booking/car/map', this.item.description], {
      queryParams,
    });
  }

  viewDetails(id: string, name: string, carItem: any) {
    // const queryParams = { property_id: id, destination: city_name };
    // const addressArray = address.split(',');
    // this.router.navigate(
    //   [
    //     '/booking/car/search',
    //     this.createSlug(addressArray[addressArray.length - 1]),
    //     this.createSlug(name),
    //   ],
    //   { queryParams }
    // );
  }

  createSlug(text: string) {
    return text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, '-')
      .replace(/[^\w\-]+/g, '')
      .replace(/\-\-+/g, '-')
      .replace(/^-+/, '')
      .replace(/-+$/, '');
  }

  renderAmenityList(property_amenity_list: PropertyAmenityList[]) {
    let availableList = property_amenity_list.filter(
      (item) => item.amenity_icon !== this.invalidIconName
    );
    return availableList;
  }

  replaceWithDefaultImage(event: any): void {
    event.target.src = 'assets/images/test/default-hotel.svg';
  }

  // Render hafl star
  getStarCount(star: number): number {
    return Math.floor(star);
  }

  hasHalfStar(star: number): boolean {
    return star % 1 !== 0;
  }
  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
