<div
  class="shadow-sx col-span-2 mt-3 rounded-xl border border-palette-gray-200 bg-white p-[1.5rem]"
>
  <p
    class="!mb-[1rem] text-[1.125rem] font-semibold not-italic leading-[1.75rem] text-palette-gray-900"
  >
    {{ 'APP_OWNER_CONTACT_INFO.CAR_OWNER' | translate }}
  </p>
  <div
    class="flex flex-col justify-between gap-y-[1rem] md:flex-row md:items-center md:gap-x-[1.5rem]"
  >
    <div class="flex items-center gap-x-[1rem]">
      <div class="min-w-[4rem]">
        <img
          class="h-[4rem] w-[4rem] rounded-full"
          src="{{ baseImageId }}/{{ agentDetailData?.avatar }}.webp"
        />
      </div>
      <div class="flex min-w-36 flex-col gap-y-[0.25rem]">
        <div
          class="overflow-hidden text-ellipsis text-[1.125rem] font-semibold leading-[1.75rem] text-palette-gray-900"
        >
          {{ agentDetailData?.agency_name }}
        </div>
        <div class="flex items-center gap-[0.5rem]">
          <div
            class="flex items-center gap-0.5 overflow-hidden text-ellipsis text-base font-medium text-palette-yellow-400"
          >
            <i class="sctr-icon-solid-star-01"></i>
            <span class="px-1 font-inter">{{ agentDetailData?.point }}</span>
          </div>
          <div class="h-[5px] w-[5px] rounded-full bg-palette-gray-300"></div>
          <div class="flex items-center gap-1">
            <i class="sctr-icon-solid-car-01 text-blue-600"></i>
            <div class="text-md font-normal text-palette-gray-700">
              {{ 'pluralization.trip(s)' | translate : {count: agentDetailData?.trips} }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="m-0 grid flex-1 justify-between gap-x-[1.5rem] gap-y-[0.5rem] md:flex">
      <div class="col-span-1 m-0 grid grid-cols-1 gap-y-[0.25rem]">
        <div class="flex items-center gap-[0.5rem]">
          <i class="sctr-icon-phone-call-01 text-palette-blue-600"></i>
          <div class="overflow-hidden text-ellipsis text-md font-medium text-palette-gray-500">
            {{ 'APP_OWNER_CONTACT_INFO.PHONE_NUMBER' | translate }}
          </div>
        </div>
        <div class="overflow-hidden text-ellipsis text-md font-medium text-palette-gray-700">
          {{
            '(' +
              agentDetailData?.agency_phone?.phone_area_code +
              ') ' +
              agentDetailData?.agency_phone?.phone_number
          }}
        </div>
      </div>
      <div class="col-span-1 m-0 grid grid-cols-1 gap-y-[0.25rem]">
        <div class="flex items-center gap-[0.5rem]">
          <i class="sctr-icon-mail-01 text-palette-blue-600"></i>
          <div class="overflow-hidden text-ellipsis text-md font-medium text-palette-gray-500">
            E-mail
          </div>
        </div>
        <div class="overflow-hidden text-ellipsis text-md font-medium text-palette-gray-700">
          {{ agentDetailData?.agency_email }}
        </div>
      </div>
    </div>
  </div>
</div>
