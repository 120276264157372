import { DateTime } from 'luxon';
import { Pipe, PipeTransform, inject } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DateTimeFormat } from '@shared/enum/date-time-format.enum';
import { SoctripTranslationService } from 'src/app/services/soctrip-translation/soctrip-translation.service';

@Pipe({
  name: 'customDateTime',
  standalone: true,
})
export class CustomDateTimePipe extends DatePipe implements PipeTransform {
  private translationService = inject(SoctripTranslationService);
  private localeString = this.translationService.getLocale();
  // private localeString = 'en-US';

  override transform(
    value: any,
    format?: string,
    tz?: string,
    tzFormat?: string,
    isConvertDate: boolean = false
  ): any {
    try {
      const offset = DateTime.now().setZone(tz);
      const dateTimeValue = new Date(value).toISOString();
      const dateTimeFormat =
        format && (Object.values(DateTimeFormat) as string[]).includes(format)
          ? format
          : DateTimeFormat.DATETIME_SHORT__24H;
      const formatSplited: string[] = dateTimeFormat.split('__');
      const dateTime = DateTime.fromISO(dateTimeValue, {
        zone: isConvertDate ? tz ?? undefined : undefined,
      })
        .setLocale(this.localeString)
        .toLocaleString(
          formatSplited[1]
            ? {
                ...DateTime[formatSplited[0] as keyof typeof DateTime],
                hourCycle: 'h23',
                hour: '2-digit',
              }
            : { ...DateTime[formatSplited[0] as keyof typeof DateTime] }
        );
      if (!tzFormat) {
        return dateTime;
      } else {
        if (tzFormat === DateTimeFormat.OFFSET) {
          return `${dateTime} (UTC${offset.toFormat('ZZ')})`;
        }
        if (tzFormat === DateTimeFormat.OFFSET_AND_ABB) {
          return `${dateTime} (UTC${offset.toFormat('ZZ')} ${offset.toFormat('ZZZZ')})`;
        }
        return dateTime;
      }
    } catch (error) {
      return '';
    }
  }
}
