import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '@env/environment';

@Pipe({
  name: 'VideoPath',
  standalone: true,
})
export class VideoPathPipe implements PipeTransform {
  transform(file: string): string {
    return `${environment.baseURL}/storage/files/stream-video/${file}.webp`;
  }
}
