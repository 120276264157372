<p-dialog
  [closable]="displayCloseButton"
  [(visible)]="showDialog"
  [modal]="true"
  [styleClass]="styleClass"
  [draggable]="false"
  [dismissableMask]="closeFromOutSide"
  (onHide)="closeDialog()"
  [resizable]="resizable"
  [position]="position"
>
  <ng-template pTemplate="header">
    <ng-content select="[modal-header]"></ng-content>
  </ng-template>
  <div class="truncate">
    <ng-content select="[modal-content]"></ng-content>
  </div>
  <ng-template pTemplate="footer">
    <ng-content select="[modal-footer]"></ng-content>
    <div [hidden]="!showCancelBtn && !showConfirmBtn" class="flex justify-end gap-2">
      <button
        class="t-footer-btn rounded-lg border border-solid border-gray-300 bg-white !px-[18px] !py-[10px] !font-inter text-lg !font-semibold text-gray-700"
        *ngIf="showCancelBtn"
        (click)="onCancelDialog()"
      >
        {{ cancelBtnText }}
      </button>
      <button
        class="t-footer-btn t-confirm rounded-lg bg-blue-600 !px-[18px] !py-[10px] !font-inter text-lg !font-semibold text-white disabled:bg-[#53b1fd]"
        *ngIf="showConfirmBtn"
        [ngClass]="{
          'flex items-center !gap-2 !py-0': loading !== undefined
        }"
        (click)="handleSave($event)"
        [disabled]="disabledConfirmBtn"
      >
        <div class="!h-5 !w-5" *ngIf="loading">
          <p-progressSpinner
            styleClass="opacity-60 w-full h-full"
            strokeWidth="8"
            animationDuration=".5s"
            [style]="{
              width: '1.25rem',
              height: '1.25rem'
            }"
          ></p-progressSpinner>
        </div>
        {{ confirmBtnText }}
      </button>
    </div>
  </ng-template></p-dialog
>
