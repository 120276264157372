import { Pipe, PipeTransform } from '@angular/core';
import { DateTimeFormat } from '@shared/enum/date-time-format.enum';
import { DateTime } from 'luxon';

@Pipe({
  name: 'tzOnly',
  standalone: true,
})
export class TzOnlyPipe implements PipeTransform {
  transform(
    tz?: string,
    tzFormat: 'OFFSET' | 'OFFSET_AND_ABB' = 'OFFSET',
    isRemoveParentheses: boolean = false
  ): string {
    try {
      let result = '';
      const offset = DateTime.now().setZone(tz);
      if (tzFormat === DateTimeFormat.OFFSET) {
        result = `(UTC${offset.toFormat('ZZ')})`;
      } else if (tzFormat === DateTimeFormat.OFFSET_AND_ABB) {
        result = `(UTC${offset.toFormat('ZZ')} ${offset.toFormat('ZZZZ')})`;
      } else {
        result = `(UTC${offset.toFormat('ZZ')})`;
      }
      if (isRemoveParentheses) {
        result = result.slice(1, -1);
      }

      return result;
    } catch (error) {
      return 'Invalid timezone';
    }
  }
}
