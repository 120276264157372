
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { PropertyAmenitiesService } from '@modules/car-booking/services/ota/property-amenities.service';
//@ts-ignore
import { AmenitiesSection } from '@modules/ota/become-agency/models/property-amenities.model';
import { Subscription } from 'rxjs';
import { PropertyAmenitiesManagementService } from '@modules/car-booking/services/property-management/property-amenities-management.service';

@Component({
  selector: 'app-property-amenities-section',
  templateUrl: './property-amenities-section.component.html',
  styleUrls: ['./property-amenities-section.component.scss'],
  animations: [
    trigger('slideDownUp', [
      state('void', style({ height: '0', opacity: '0' })),
      state('*', style({ height: '*', opacity: '1' })),
      transition(':enter, :leave', [animate('0.3s ease-out')]),
    ]),
    trigger('rotateAnimation', [
      state('open', style({ transform: 'rotate(0deg)' })),
      state('closed', style({ transform: 'rotate(180deg)' })),
      transition('open <=> closed', animate('0.3s ease-out')),
    ]),
  ],
})
export class PropertyAmenitiesSectionComponent implements OnInit, OnDestroy {
  @Input() amenitiesList: AmenitiesSection;
  @Input() isTopAmenities: boolean;
  @Input() typeAccess: string;
  @Input() page: string;
  @Output() outIsDirty = new EventEmitter<boolean>();

  checkedSelectAll: boolean = false;
  isShowAmenitiesSection: boolean = true;
  selectedAmenities: { [filterTitle: string]: any[] } = {};
  selectAll: boolean = false;
  selectedCategory: any = {};
  resultObject: { [filterTitle: string]: any[] } = {};
  numberSelectedAmenities: number = 0;
  private subscription: Subscription;

  categories: any[] = [
    { name: 'Free', value: 'free' },
    { name: 'Charge', value: 'charge' },
  ];

  constructor(
    private propertyAmenitiesService: PropertyAmenitiesService,
    private propertyAmenitiesManagementService: PropertyAmenitiesManagementService
  ) {
    this.propertyAmenitiesService.collapseAll$.subscribe(
      (isCollapseAll: boolean) => {
        this.handleCollapseAll(isCollapseAll);
      }
    );

    this.subscription = this.propertyAmenitiesManagementService
      .getIsViewOnly()
      .subscribe((isViewOnly: boolean) => {
        if (isViewOnly) {
          this.typeAccess = 'view-only';
        } else {
          this.typeAccess = 'edit';
        }
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.subscription = this.propertyAmenitiesManagementService
      .getIsViewOnly()
      .subscribe((isViewOnly: boolean) => {
        if (isViewOnly) {
          this.typeAccess = 'view-only';
        } else {
          this.typeAccess = 'edit';
        }
      });
      
    this.numberSelectedAmenities =
      this.selectedAmenities[
        this.amenitiesList.property_amenity_category_id
      ]?.length;
    this.amenitiesList.property_amenities.forEach((amenity: any) => {
      if (amenity?.is_allow_charge === true) {
        this.selectedCategory[amenity.property_amenity_id] = 'free';
      }
    });

    this.propertyAmenitiesService
      .getSelectedAmenities()
      .subscribe((data: any) => {
        if (!this.isEmptyObject(data)) {
          this.selectedAmenities = data;
        }
      });

    this.propertyAmenitiesService
      .getIsChargeAmenities()
      .subscribe((data: any) => {
        if (!this.isEmptyObject(data)) {
          this.selectedCategory = data;
        }
      });
    this.checkSelectedAll();
  }

  handleCollapse() {
    this.isShowAmenitiesSection = !this.isShowAmenitiesSection;
    this.numberSelectedAmenities =
      this.selectedAmenities[
        this.amenitiesList.property_amenity_category_id
      ]?.length;

    // Count number of amenity selected for Top amenity section
    if (this.isTopAmenities) {
      this.numberSelectedAmenities = 0;
      for (let i = 0; i < this.amenitiesList.property_amenities.length; i++) {
        const existsInObject = Object.values(this.selectedAmenities).some(
          (array) =>
            array.includes(
              this.amenitiesList.property_amenities[i].property_amenity_id
            )
        );
        if (existsInObject) {
          this.numberSelectedAmenities++;
        }
      }
    }
  }

  handleCollapseAll(isCollapseAll: boolean) {
    this.isShowAmenitiesSection = isCollapseAll;
    this.numberSelectedAmenities =
      this.selectedAmenities[
        this.amenitiesList.property_amenity_category_id
      ]?.length;

    if (this.isTopAmenities) {
      this.numberSelectedAmenities = 0;
      for (let i = 0; i < this.amenitiesList.property_amenities.length; i++) {
        const existsInObject = Object.values(this.selectedAmenities).some(
          (array) =>
            array.includes(
              this.amenitiesList.property_amenities[i].property_amenity_id
            )
        );
        if (existsInObject) {
          this.numberSelectedAmenities++;
        }
      }
    }
  }

  handleClickCheckbox(amenity: any) {
    this.outIsDirty.emit(true);
    this.numberSelectedAmenities =
      this.selectedAmenities[
        this.amenitiesList.property_amenity_category_id
      ]?.length;
    this.propertyAmenitiesService.setSelectedAmenities(this.selectedAmenities);
    this.checkSelectedAll();

    const existsInObject = Object.values(this.selectedAmenities).some((array) =>
      array.includes(amenity.property_amenity_id)
    );
    if (existsInObject && amenity.is_allow_charge) {
      this.selectedCategory[amenity.property_amenity_id] = 'free';
    }
    this.propertyAmenitiesService.setIsChargeAmenities(this.selectedCategory);
  }

  handleSelectAll() {
    if (!this.selectAll) {
      // Case for Top Amenities when unchecked SelectAll
      if (this.isTopAmenities) {
        for (let i = 0; i < this.amenitiesList.property_amenities.length; i++) {
          for (const key in this.selectedAmenities) {
            const index = this.selectedAmenities[key].indexOf(
              this.amenitiesList.property_amenities[i].property_amenity_id
            );

            if (index !== -1) {
              this.selectedAmenities[key].splice(index, 1);
            }
          }
        }
        const temp: any = {};
        for (const key in this.selectedAmenities) {
          if (this.selectedAmenities[key].length !== 0) {
            temp[key] = this.selectedAmenities[key];
          }
        }
        this.selectedAmenities = temp;

        this.isShowAmenitiesSection = false;
        setTimeout(() => {
          this.isShowAmenitiesSection = true;
        }, 50)

      } else {
        this.selectedAmenities[this.amenitiesList.property_amenity_category_id] = [];
      }

      this.propertyAmenitiesService.setSelectedAmenities(this.selectedAmenities);
      this.selectAll = true;
      this.numberSelectedAmenities = 0;
    } else {
      this.selectAll = false;
      this.amenitiesList.property_amenities.forEach((amenity: any) => {
        if (amenity.property_amenity_id) {
          if (this.resultObject[amenity.property_amenity_category_id]) {
            this.resultObject[amenity.property_amenity_category_id].push(
              amenity.property_amenity_id
            );
          } else {
            this.resultObject[amenity.property_amenity_category_id] = [
              amenity.property_amenity_id,
            ];
          }
        }

        // Add category free or charge for amenity when clicked or selected all
        const existsInObject = Object.values(this.selectedAmenities).some(
          (array) => array.includes(amenity.property_amenity_id)
        );
        if (existsInObject && amenity.is_allow_charge) {
          this.selectedCategory[amenity.property_amenity_id] = 'free';
        }
      });

      for (const key of Object.keys(this.resultObject)) {
        if (this.selectedAmenities[key]) {
          this.selectedAmenities[key] = [
            ...new Set([
              ...this.selectedAmenities[key],
              ...this.resultObject[key],
            ]),
          ];
        } else {
          this.selectedAmenities[key] = this.resultObject[key];
        }
      }
      this.propertyAmenitiesService.setSelectedAmenities(
        this.selectedAmenities
      );
      this.numberSelectedAmenities =
        this.selectedAmenities[
          this.amenitiesList.property_amenity_category_id
        ]?.length;
    }
    this.propertyAmenitiesService.setIsChargeAmenities(this.selectedCategory);
    this.selectAll = !this.selectAll;

    this.amenitiesList.property_amenities.forEach((amenity: any) => {
      // Add category free or charge for amenity when clicked or selected all
      const existsInObject = Object.values(this.selectedAmenities).some(
        (array) => array.includes(amenity.property_amenity_id)
      );
      if (existsInObject && amenity.is_allow_charge) {
        this.selectedCategory[amenity.property_amenity_id] = 'free';
      }
    });
  }

  // Check this amenity is_allow_charge true or false
  checkMoreOption(selectedAmenities: any, amenity: any) {
    if (
      selectedAmenities?.[amenity.property_amenity_category_id]?.includes(
        amenity.property_amenity_id
      )
    ) {
      return true;
    } else {
      return false;
    }
  }

  handleClickRadioButton(amenity: any) {
    this.propertyAmenitiesService.setIsChargeAmenities(this.selectedCategory);
  }

  isEmptyObject = (obj: Object) => {
    return Object.keys(obj).length === 0;
  };

  // Check Select all when user check all amenities in one section
  checkSelectedAll() {
    for (let i = 0; i < this.amenitiesList.property_amenities.length; i++) {
      const existsInObject = Object.values(this.selectedAmenities).some(
        (array) =>
          array.includes(
            this.amenitiesList.property_amenities[i].property_amenity_id
          )
      );
      if (!existsInObject) {
        this.selectAll = false;
        return;
      }
    }
    this.selectAll = true;
  }
}
