import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { CalendarTypeView } from 'primeng/calendar';
import { CalendarOptionsChoose, CalendarOptionsSelect } from '@shared/constants/dashboard.config';
import { CalendarFilter, FrameType } from './calendar-filter.model';
import { CalendarOptionEnum } from '@shared/enum/dashboard.enum';
import { DateTime } from 'luxon';
import { CustomDateTimeService } from '@shared/services/custom-date-time.service';
import { DateFormat } from '@shared/enum/date-format.enum';

@Component({
  selector: 'app-calendar-filter',
  templateUrl: './calendar-filter.component.html',
  styleUrls: ['./calendar-filter.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CalendarFilterComponent {
  readonly optionsChoose = CalendarOptionsChoose;
  readonly optionsSelect = CalendarOptionsSelect;
  readonly dateNow = new Date();

  @Input() filter: CalendarFilter;
  @Output() filterChange = new EventEmitter<CalendarFilter>();

  date: Date[] | Date;
  title: string;
  dateTitle: string;
  inputOptions: {
    mode: 'single' | 'multiple' | 'range';
    view: CalendarTypeView;
    disabled: boolean;
  };
  optionSelected: string;

  constructor(private customDateTimeService: CustomDateTimeService) {}
  ngOnInit() {
    this.date = new Date();
    this.dateTitle = this.customDateTimeService.formatDate(
      DateTime.fromJSDate(this.date),
      DateFormat.DATE_SHORT
    );
    this.inputOptions = {
      mode: 'single',
      view: 'date',
      disabled: false,
    };
    this.optionSelected = this.optionsSelect[0].value;
    this.title = this.optionsSelect[0].title;
  }

  onSelectDate(evt: Date) {
    switch (this.optionSelected) {
      case CalendarOptionEnum.BY_DAY: {
        this.date = evt;
        this.dateTitle = this.customDateTimeService.formatDate(
          DateTime.fromJSDate(evt),
          DateFormat.DATE_SHORT
        );
        this.onChangeFilter({ frame: 'DAYS', comparison: 1 });
        break;
      }
      case CalendarOptionEnum.BY_WEEK: {
        const start = DateTime.fromJSDate(evt).startOf('week');
        const end = DateTime.fromJSDate(evt).endOf('week');
        if (end.toJSDate() > this.dateNow) {
          this.date = [start.toJSDate(), DateTime.fromJSDate(this.dateNow).toJSDate()];
        } else {
          this.date = [start.toJSDate(), end.toJSDate()];
        }
        this.dateTitle = `${this.customDateTimeService.formatDate(
          start,
          DateFormat.DATE_SHORT
        )} - ${this.customDateTimeService.formatDate(end, DateFormat.DATE_SHORT)}`;
        this.onChangeFilter({ frame: 'DAYS', comparison: 7 });
        break;
      }
      case CalendarOptionEnum.BY_MONTH: {
        this.date = evt;
        this.dateTitle = DateTime.fromJSDate(this.date).toFormat('MM/yyyy');

        const startOfMonth = DateTime.fromJSDate(evt).startOf('month').toJSDate();
        const endOfMonth = DateTime.fromJSDate(evt).endOf('month').toJSDate();

        const comparisonDate = DateTime.fromJSDate(startOfMonth)
          .minus({ days: 1 })
          .toFormat('MM/yyyy');

        this.filter = {
          frame: 'WEEKS',
          rangeDates: [startOfMonth, endOfMonth],
          comparisonDate: comparisonDate,
        };

        this.filterChange.emit(this.filter);
        break;
      }
      case CalendarOptionEnum.BY_YEAR: {
        this.date = evt;
        this.dateTitle = DateTime.fromJSDate(this.date).toFormat('yyyy');

        const startOfYear = DateTime.fromJSDate(evt).startOf('year').toJSDate();
        const endOfYear = DateTime.fromJSDate(evt).endOf('year').toJSDate();

        const comparisonDate = DateTime.fromJSDate(evt).minus({ years: 1 }).toFormat('yyyy');

        this.filter = {
          frame: 'MONTHS',
          rangeDates: [startOfYear, endOfYear],
          comparisonDate: DateTime.fromFormat(comparisonDate, 'yyyy').toFormat('yyyy'),
        };
        this.filterChange.emit(this.filter);
        break;
      }
    }
  }

  onSelectOption(value: string, title: string) {
    this.title = title;
    this.optionSelected = value;
    switch (value) {
      case CalendarOptionEnum.TODAY: {
        this.inputOptions = {
          mode: 'single',
          view: 'date',
          disabled: true,
        };
        this.date = new Date();
        this.dateTitle = this.customDateTimeService.formatDate(
          DateTime.fromJSDate(this.date),
          DateFormat.DATE_SHORT
        );
        this.onChangeFilter({ frame: 'DAYS', comparison: 1 });
        break;
      }
      case CalendarOptionEnum.YESTERDAY: {
        this.inputOptions = {
          mode: 'single',
          view: 'date',
          disabled: true,
        };
        const yesterday = DateTime.now().minus({ days: 1 });
        this.date = yesterday.toJSDate();
        this.dateTitle = this.customDateTimeService.formatDate(yesterday, DateFormat.DATE_SHORT);
        this.onChangeFilter({ frame: 'DAYS', comparison: 1 });
        break;
      }
      case CalendarOptionEnum.PAST_7_DAYS: {
        this.inputOptions = {
          mode: 'range',
          view: 'date',
          disabled: true,
        };

        const startDate = DateTime.now().minus({ days: 8 }).startOf('day');
        const endDate = DateTime.now().minus({ days: 1 }).endOf('day');

        this.date = [startDate.toJSDate(), endDate.toJSDate()];
        this.dateTitle = `${this.customDateTimeService.formatDate(
          startDate,
          DateFormat.DATE_SHORT
        )} - ${this.customDateTimeService.formatDate(endDate, DateFormat.DATE_SHORT)}`;
        this.onChangeFilter({ frame: 'DAYS', comparison: 7 });
        break;
      }
      case CalendarOptionEnum.PAST_30_DAYS: {
        this.inputOptions = {
          mode: 'range',
          view: 'date',
          disabled: true,
        };

        const startDate = DateTime.now().minus({ days: 31 }).startOf('day');
        const endDate = DateTime.now().minus({ days: 1 }).endOf('day');

        this.date = [startDate.toJSDate(), endDate.toJSDate()];
        this.dateTitle = `${this.customDateTimeService.formatDate(
          startDate,
          DateFormat.DATE_SHORT
        )} - ${this.customDateTimeService.formatDate(endDate, DateFormat.DATE_SHORT)}`;
        this.onChangeFilter({ frame: 'WEEKS', comparison: 30 });
        break;
      }
      case CalendarOptionEnum.BY_DAY: {
        this.inputOptions = {
          mode: 'single',
          view: 'date',
          disabled: false,
        };
        this.date = new Date();
        break;
      }
      case CalendarOptionEnum.BY_WEEK: {
        this.inputOptions = {
          mode: 'range',
          view: 'date',
          disabled: false,
        };
        this.date = [];
        break;
      }
      case CalendarOptionEnum.BY_MONTH: {
        this.inputOptions = {
          mode: 'single',
          view: 'month',
          disabled: false,
        };
        this.date = new Date();
        break;
      }
      case CalendarOptionEnum.BY_YEAR: {
        this.inputOptions = {
          mode: 'single',
          view: 'year',
          disabled: false,
        };
        this.date = new Date();
      }
    }
  }

  onChangeFilter({ frame, comparison }: { frame: FrameType; comparison: number }) {
    const type = Array.isArray(this.date) ? 'range' : 'single';
    if (type === 'single') {
      const date = this.date as Date;
      const dateTime = DateTime.fromJSDate(date);

      const comparisonDate = this.customDateTimeService.formatDate(
        dateTime.minus({ days: comparison }),
        DateFormat.DATE_SHORT
      );
      this.filter = {
        frame,
        rangeDates: [dateTime.startOf('day').toJSDate(), dateTime.endOf('day').toJSDate()],
        comparisonDate,
      };
    } else if (type === 'range') {
      const range = this.date as Date[];
      const startDateTime = DateTime.fromJSDate(range[0]);
      const endDateTime = DateTime.fromJSDate(range[1]);

      const comparisonEndDate = this.customDateTimeService.formatDate(
        startDateTime.minus({ days: 1 }),
        DateFormat.DATE_SHORT
      );
      const comparisonStartDate = this.customDateTimeService.formatDate(
        startDateTime.minus({ days: comparison }),
        DateFormat.DATE_SHORT
      );

      this.filter = {
        frame,
        rangeDates: [startDateTime.startOf('day').toJSDate(), endDateTime.endOf('day').toJSDate()],
        comparisonDate: `${comparisonStartDate} - ${comparisonEndDate}`,
      };
    }
    this.filterChange.emit(this.filter);
  }
}
