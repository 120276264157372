import { 
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef,
  EmbeddedViewRef,
  HostListener,
  Injector,
  Input,
} from '@angular/core';
import { TooltipPosition, TooltipTheme } from '@shared/enum/tooltip.enums';
import { TooltipComponent } from '@shared/components/tooltip/tooltip.component';

@Directive({
  selector: '[tooltip]'
})
export class TooltipDirective {

  @Input() tooltip = '';
  @Input() position: TooltipPosition = TooltipPosition.DEFAULT;
  @Input() theme: TooltipTheme = TooltipTheme.DEFAULT;
  @Input() isTruncated: boolean = false;
  @Input() showDelay = 0;
  @Input() hideDelay = 0;
  @Input() styleClass: string = '';

  private componentRef: ComponentRef<any> | null = null;
  private showTimeout?: number;
  private hideTimeout?: number;
  private touchTimeout?: number;

  constructor(
    private elementRef: ElementRef,
    private appRef: ApplicationRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
  ) {}

  @HostListener('mouseenter')
  onMouseEnter(): void {
    if (this.isTruncated) {
      if (
        this.elementRef.nativeElement.scrollHeight >
          this.elementRef.nativeElement.clientHeight ||
        this.elementRef.nativeElement.scrollWidth >
          this.elementRef.nativeElement.clientWidth
      ) {
        this.initializeTooltip();
      }
    } else {
      this.initializeTooltip();
    }
  }

  @HostListener('mouseleave')
  onMouseLeave(): void {
    this.setHideTooltipTimeout();
  }

  @HostListener('mousemove', ['$event'])
  onMouseMove($event: MouseEvent): void {
    if (this.componentRef !== null && this.position === TooltipPosition.DYNAMIC) {
      this.componentRef.instance.left = $event.clientX;
      this.componentRef.instance.top = $event.clientY;
      this.componentRef.instance.tooltip = this.tooltip;
    }
  }

  @HostListener('touchstart', ['$event'])
  onTouchStart($event: TouchEvent): void {
    $event.preventDefault();
    window.clearTimeout(this.touchTimeout);
    this.touchTimeout = window.setTimeout(this.initializeTooltip.bind(this), 500);
  }

  @HostListener('touchend')
  onTouchEnd(): void {
    window.clearTimeout(this.touchTimeout);
    this.setHideTooltipTimeout();
  }

  private initializeTooltip() {
    if (this.componentRef !== null) return;

    window.clearInterval(this.hideDelay);

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(TooltipComponent);
    this.componentRef = componentFactory.create(this.injector);

    this.appRef.attachView(this.componentRef.hostView);
    const [tooltipDOMElement] = (this.componentRef.hostView as EmbeddedViewRef<any>).rootNodes;
    const tooltipElementRef = new ElementRef(tooltipDOMElement);
    if (this.styleClass) {
      const classesToAdd = this.styleClass.split(' ');
      classesToAdd.forEach((className) => {
        tooltipElementRef.nativeElement.querySelector('.tooltip').classList.add(className);
      })
    }

    this.setTooltipComponentProperties();
    if (this.elementRef && this.elementRef.nativeElement) {
      let parentElement = this.elementRef.nativeElement.parentNode;
      const parentElementRef = new ElementRef(parentElement);
      parentElementRef.nativeElement.appendChild(tooltipDOMElement);
    }

    this.showTimeout = window.setTimeout(() => this.showTooltip(), this.showDelay);
  }

  private setTooltipComponentProperties() {
    if (!this.componentRef) return;

    this.componentRef.instance.tooltip = this.tooltip;
    this.componentRef.instance.position = this.position;
    this.componentRef.instance.theme = this.theme;

    const { height, width } = this.elementRef.nativeElement.getBoundingClientRect();

    switch (this.position) {
      case TooltipPosition.BELOW: {
        this.componentRef.instance.left = Math.round(width / 2);
        this.componentRef.instance.top = Math.round(height);
        break;
      }
      case TooltipPosition.ABOVE: {
        this.componentRef.instance.left = Math.round(width / 2);
        this.componentRef.instance.top = Math.round(-2);
        break;
      }
      case TooltipPosition.RIGHT: {
        this.componentRef.instance.left = Math.round(width);
        this.componentRef.instance.top = Math.round(height / 2);
        break;
      }
      case TooltipPosition.LEFT: {
        this.componentRef.instance.top = Math.round(height / 2);
        break;
      }
      default: {
        break;
      }
    }
  }

  private showTooltip() {
    if (this.componentRef !== null) {
      this.componentRef.instance.visible = true;
    }
  }

  private setHideTooltipTimeout() {
    this.hideTimeout = window.setTimeout(this.destroy.bind(this), this.hideDelay);
  }

  ngOnDestroy(): void {
    this.destroy();
  }

  destroy(): void {
    if (this.componentRef !== null) {
      window.clearInterval(this.showTimeout);
      window.clearInterval(this.hideDelay);
      this.appRef.detachView(this.componentRef.hostView);
      this.componentRef.destroy();
      this.componentRef = null;
    }
  }
}
