<div
  class="shadow-sx col-span-2 mt-3 rounded-xl border border border-palette-gray-200 bg-white px-4 pb-4"
>
  <p class="!mb-3 !pt-4 text-xl font-semibold not-italic leading-[30px] text-palette-gray-800">
    {{ 'APP_RENT_CONTACT_INFORMATION.CONTACT_INFORMATION' | translate }}
  </p>
  <div class="mx-0 grid items-center justify-between gap-1 rounded-lg bg-[#F2F4F7] p-4 md:flex">
    <div class="col-span-1 grid grid-cols-1 px-3">
      <div
        class="text-soctrip-small overflow-hidden text-ellipsis font-normal text-palette-gray-500"
      >
        {{ 'APP_RENT_CONTACT_INFORMATION.FULL_NAME' | translate }}
      </div>
      <div class="overflow-hidden text-ellipsis text-base font-normal text-palette-gray-900">
        {{ reservationDetail?.document?.full_name }}
      </div>
    </div>
    <div class="col-span-1 grid grid-cols-1 px-3">
      <div
        class="text-soctrip-small overflow-hidden text-ellipsis font-normal text-palette-gray-500"
      >
        {{ 'APP_RENT_CONTACT_INFORMATION.PHONE_NUMBER' | translate }}
      </div>
      <div class="overflow-hidden text-ellipsis text-base font-normal text-palette-gray-900">
        {{ '('+ reservationDetail?.document?.pre_phone + ') ' + reservationDetail?.document?.phone }}
      </div>
    </div>
    <div class="col-span-1 grid grid-cols-1 px-3">
      <div
        class="text-soctrip-small overflow-hidden text-ellipsis font-normal text-palette-gray-500"
      >
        E-mail
      </div>
      <div class="overflow-hidden text-ellipsis text-base font-normal text-palette-gray-900">
        {{ reservationDetail?.document?.email }}
      </div>
    </div>
  </div>
</div>
