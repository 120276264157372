<div class="t-input-phone-number flex !w-full flex-col gap-1" [formGroup]="phoneNumberForm">
  <div
    class="input-phone-booking flex items-center {{ readonly ? 'bg-gray-50' : 'hover:border-blue-300 focus-within:border-blue-300 focus-within:shadow-focus-light-primary' }}"
    [ngClass]="{ 'is-invalid ': f['phoneNumber'].touched && f['phoneNumber'].errors }"
  >
    <p-dropdown
      [options]="countries"
      [filter]="true"
      filterBy="name"
      formControlName="phoneNumberCode"
      [readonly]="readonly"
      [styleClass]="readonly ? 'bg-gray-50' : ''"
    >
      <ng-template pTemplate="selectedItem">
        <div class="flex items-center justify-center !gap-[2px]">
          <img
            [src]="getIcon(f['phoneNumberCode'].value)"
            class="!h-5 !w-6 rounded border border-palette-base-white object-cover"
          />
          <span class="font-inter !text-md !font-normal text-palette-gray-700">{{
            f['phoneNumberCode'].value.code
          }}</span>
        </div>
      </ng-template>
      <ng-template let-country pTemplate="item">
        <div class="z-1000 flex items-center !gap-2">
          <img
            [src]="country.icon"
            class="!h-5 !w-6 rounded border border-palette-base-white object-cover"
          />
          <span>{{ country.name }}</span>
          <span>({{ country.code }})</span>
        </div>
      </ng-template>
    </p-dropdown>
    <input
      [id]="id"
      [maxlength]="15"
      [placeholder]="'OTA.CONTRACT_AGREEMENT.P_PHONE_NUMBER' | translate"
      class="phone-input w-full !pl-3 font-inter !text-md !font-normal outline-none {{
        readonly ? 'bg-gray-50' : ''
      }}"
      type="number"
      (paste)="onPaste($event)"
      (keypress)="onKeyPress($event)"
      [readonly]="readonly"
      formControlName="phoneNumber"
    />
  </div>
  <div *ngIf="f['phoneNumber'].touched && f['phoneNumber'].errors" class="invalid-feedback">
    <div *ngIf="f['phoneNumber'].errors['required']" class="flex items-center gap-1">
      <span class="flex items-center"><i class="sctr-icon-alert-circle"></i></span>
      {{ 'OTA.CONTACT.PHONE_NUMBER' | translate }} {{ 'OTA.CONTACT.IS_REQUIRED' | translate }}
    </div>
    <div *ngIf="f['phoneNumber'].errors['pattern']" class="flex items-center gap-1">
      <span class="flex items-center"><i class="sctr-icon-alert-circle"></i></span>
      {{ 'OTA.CONTACT.PHONE_NUMBER' | translate }} {{ 'OTA.CONTACT.IS_INVALID' | translate }}
    </div>
  </div>
</div>
