import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';

import { PostRequest } from '@shared/models/post.model';
import { UserInfo } from '@modules/users/models/user';
import { CarDetail } from '@modules/car-booking/models/view-details';
import { environment } from '@env/environment';

import { UserService } from 'src/app/core/services/user/user.service';
import { PostService } from 'src/app/services/posts/post.service';

interface FileWithPreview {
  thumbnail: string;
  original: string;
  file?: any;
  type?: any;
}

interface DropdownOption {
  label: string;
  value: any;
  icon: string;
}

@Component({
  selector: 'soctrip-post-dialog',
  templateUrl: './post-dialog.component.html',
  styleUrls: ['./post-dialog.component.scss'],
  providers: [MessageService],
  encapsulation: ViewEncapsulation.None,
})
export class PostDialogComponent {
  @Input() visible = false;
  @Input() dataDetailCar: CarDetail;
  @Output() visibleChange = new EventEmitter(false);
  @ViewChild('hotelDescription') hotelDescription: ElementRef;

  apiUrl = environment.baseURL;
  postForm: FormGroup;
  selectedOption: any = 'PUBLIC';
  baseUrl: string = environment.baseURL;
  userInfo: UserInfo;
  loading = false;
  progressValue = 0;
  myOptions: DropdownOption[] = [
    { label: 'POST.PUBLIC', value: 'PUBLIC', icon: 'sctr-icon-globe-04' },
    { label: 'POST.FRIENDS', value: 'FRIENDS', icon: 'sctr-icon-users-01' },
    { label: 'POST.PRIVATE', value: 'PRIVATE', icon: 'sctr-icon-user-01' },
  ];
  showProgress = false;
  content = '';
  isExpanded: boolean = true;
  contentHeight = 0;
  elementHeight = 24 * 3;
  isLongText: boolean = false;
  shareLink: Subscription;
  onOffEmoji: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private cdr: ChangeDetectorRef,
    private postService: PostService
  ) {
    this.postForm = this.formBuilder.group({
      content: [''],
    });
  }

  ngOnInit(): void {
    this.getUserInfor();
  }

  ngAfterViewInit(): void {
    this.handleShowViewMore();
    this.cdr.detectChanges();
  }

  addEmoji(event: any) {
    this.postForm
      .get('content')
      ?.setValue(
        (this.postForm.get('content')?.value === null ? '' : this.postForm.get('content')?.value) +
          event.emoji.native
      );
  }

  getUserInfor() {
    this.userService.getUserInfor().subscribe((user) => {
      if (user) this.userInfo = user;
    });
  }

  resetDataInForm(): void {
    this.postForm.reset(); // Reset the form
    this.showProgress = false;
    this.selectedOption = 'PUBLIC';
  }

  openDialog() {
    this.visible = true; // Set the value to true to open the dialog.
  }

  closeDialog() {
    this.visibleChange.emit(false);
  }

  onDialogHide(): void {
    this.visibleChange.emit({
      visible: !this.visible,
      postStatus: null,
    });
    this.resetDataInForm();
  }

  onShowDialog() {
    this.postForm.get('content')?.addValidators(Validators.required);
  }

  onExpandText() {
    this.isExpanded = !this.isExpanded;
  }

  handleShowViewMore(): void {
    if (this.hotelDescription) {
      const elementHeight = this.hotelDescription.nativeElement.offsetHeight;
      if (elementHeight > this.elementHeight) this.isLongText = true;
      else this.isLongText = false;
      this.isExpanded = false;
    }
  }

  formatBreakLine(text: string) {
    return text?.replace(/\n/g, '<br><br>');
  }

  onChangePriority(value: any) {
    this.selectedOption = value;
  }

  shouldDisableButton(): boolean {
    let check = false;
    if (
      this.postForm.get('content')?.value === '' ||
      this.postForm.get('content')?.value === null ||
      this.showProgress
    ) {
      check = true;
    }
    return check;
  }

  onSubmit() {
    // const currentURL =
    // 'https://soctrip-booking-dev.tma-swerp.com'

    // const currentURL =
    //   this.baseUrl + '/booking/car/search/' +
    //   createSlug(this.dataDetailCar.location?.district?.name) +
    //   '/' +
    //   createSlug(this.dataDetailCar.description) +
    //   '?destination=' +
    //   this.dataDetailCar.location?.district?.name +
    //   '&property_id=' +
    //   this.dataDetailCar.id;
    this.content = this.postForm.value.content;
    const postRequest: PostRequest = {
      content: this.content,
      post_type: 'USER',
      post_privacy: this.selectedOption,
      friend_tags: [],
      share_link: location?.href,
      share_link_type: 'CAR',
      dag_object_name: 'CAR_SHARE',
      dag_object_id: this.dataDetailCar.id,
      post_feeling: '',
    };
    this.shareLink = this.postService.post(postRequest).subscribe((data) => {
      if (data.success) {
        this.visibleChange.emit({
          visible: !this.visible,
          postStatus: true,
        });
      } else {
        this.visibleChange.emit({
          visible: !this.visible,
          postStatus: false,
        });
      }
    });
  }

  replaceAvatarWithDefaultImage(event: any): void {
    event.target.src = 'assets/images/default_user_avatar.png';;
  }

  replaceWithDefaultImage(event: any): void {
    event.target.src = 'assets/images/test/default-hotel.svg';
  }

  ngOnDestroy(): void {
    if (this.shareLink) {
      this.shareLink.unsubscribe();
    }
  }
}
