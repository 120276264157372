<div #selectedCardRef class="md:!mb-3 md:!p-4 md:flex md:!gap-4 md:border md:border-[#EAECF0] md:rounded-lg"
    id="map-{{item.property_id}}" (click)="childClick($event)">
    <div *ngIf="item" class="md:relative md:!w-[31.41%]">
        <img *ngIf="!item.room_image_overall_id" class="!h-[96px] !w-[96px] rounded-md"
            src="{{item.room_image_overall_url}}" (error)="replaceWithDefaultImage($event)" />
        <img *ngIf="item.room_image_overall_id" class="!h-[96px] !w-[96px] rounded-md"
            src="{{baseImageId}}/{{item.room_image_overall_id}}.webp" (error)="replaceWithDefaultImage($event)" />
    </div>
    <div class="md:!w-[64.39%] flex flex-col justify-between">
        <div>
            <app-star [count]="item.star" starStyle="!gap-0.5"
                iconStyle="sctr-icon-solid-star-01 text-palette-yellow-400 text-xs !h-3 !w-3">
            </app-star>
            <p #propertyName [tooltipDisabled]="!isDisplayToolTip"
                class="font-inter !text-sm !font-semibold text-palette-gray-900 max-w-full whitespace-nowrap overflow-hidden text-ellipsis"
                pTooltip="{{item.property_name}}" tooltipStyleClass="tooltipCustom">{{ item.property_name }}</p>
            <div class="hotel-address flex gap-1">
                <img src="assets/images/home/icon/marker-pin-01.svg" width="16px" height="16px" alt="">
                <span
                    class="text-sm font-medium font-inter text-palette-gray-500 max-w-full whitespace-nowrap overflow-hidden text-ellipsis"
                    pTooltip="{{item.address}}" tooltipStyleClass="tooltipCustom">{{ item.address }}</span>
            </div>
        </div>
        <div class="hotel-price flex font-inter items-center ">
            <span class="text-palette-gray-700 text-xs font-inter font-medium">From</span>
            <span class="text-red-500 text-sm font-semibold ml-1 flex justify-start"><app-price-format
                    [price]="item.net_price"></app-price-format></span>
        </div>
    </div>
</div>