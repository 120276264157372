import { Component, Input } from '@angular/core';
import { environment } from '@env/environment';
import { getRentType } from '@shared/utils/helper';

@Component({
  selector: 'app-rental-document',
  templateUrl: './rental-document.component.html',
  styleUrls: ['./rental-document.component.scss'],
})
export class RentalDocumentComponent {
  @Input() isDependencyPanel: boolean = true;
  @Input() dataDetailCar: any;
  currencyConversion: any;
  constructor() {}

  ngOnInit(): void {
    this.currencyConversion = JSON.parse(localStorage.getItem(environment.CURRENCY_CONVERSATION_DATA) || '');
  }

  get isShowRental() {
    const isWithDriver = this.dataDetailCar.is_with_driver;
    const isWithoutDriver = this.dataDetailCar.is_without_driver;
    const rentType = getRentType();
    if (isWithDriver && isWithoutDriver) {
      return !rentType;
    } else if (isWithDriver && !isWithoutDriver) {
      return false;
    } else {
      return true;
    }
  }
  getCollateralPrice() {
    if (this.dataDetailCar?.currency === this.currencyConversion?.currency) {
      return this.dataDetailCar?.collateral_price_canal;
    } else {
      const rate = this.currencyConversion?.rate || 1;
      return this.dataDetailCar?.collateral_price * rate;
    }
  }
}
