import {
  Component,
  ElementRef,
  Renderer2,
  ViewChild,
  ChangeDetectorRef,
  Input,
  AfterViewInit,
  HostListener,
  OnInit,
  EventEmitter,
  Output,
} from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Reviewer } from '@modules/car-booking/models/customer-review.model';
import { environment } from '@env/environment';
import { TooltipTheme, TooltipPosition } from '@shared/enum/tooltip.enums';
import { HelpfulService } from '@modules/car-booking/services/helpful.service';

@Component({
  selector: 'app-customer-review-card',
  templateUrl: './customer-review-card.component.html',
  styleUrls: ['./customer-review-card.component.scss'],
  providers: [DialogService],
})
export class CustomerReviewCardComponent implements AfterViewInit, OnInit {
  @ViewChild('reviewContent') reviewContent: ElementRef;
  @Input() item: Reviewer;
  @Output() emitOpenReportDialog = new EventEmitter<string>();
  @Output() emitRemindLogin = new EventEmitter<string>();
  isExpanded: boolean = true;
  isHelpful: boolean = false;
  isReported: boolean = false;
  isLongText: boolean = false;
  openImageRef: DynamicDialogRef | undefined;
  focusedImageIndex = -1;
  baseUrl = environment.BASE_PATH;
  helpfulCount: number = 0;
  initialHelpfulCount: number = 0;
  isShowPopupPreview: boolean = false;
  previewFile: any = null;
  TooltipPosition: typeof TooltipPosition = TooltipPosition;
  TooltipTheme: typeof TooltipTheme = TooltipTheme;
  listTypeRoomToolTip: string = '';

  constructor(
    private cdRef: ChangeDetectorRef,
    private renderer: Renderer2,
    private ref: DynamicDialogRef,
    public dialogService: DialogService,
    private helpfulService: HelpfulService
  ) {}

  ngOnInit(): void {
    this.helpfulService.checkIsHelpful(this.item.id).subscribe((response) => {
      if (response.success) this.isHelpful = response.data;
    });
    this.helpfulCount = this.item.reactions[0].count;
    this.initialHelpfulCount = this.helpfulCount;
  }

  toggleContent(): void {
    this.isExpanded = !this.isExpanded;
  }

  handleClickHelpful(id: string): void {
    const userProfile = JSON.parse(localStorage.getItem('user_profile')!);
    if (!userProfile) {
      return this.emitRemindLogin.emit();
    }
    if (this.isHelpful) {
      this.isHelpful = false;
      if (this.helpfulCount > 0) this.helpfulCount -= 1;
      this.helpfulService.markAsUnHelpful(id).subscribe({
        next: (response) => {
          if (!response.success) {
            this.failedToUnVoteHelpful();
          }
        },
        error: () => {
          this.failedToUnVoteHelpful();
        },
      });
    } else {
      this.isHelpful = true;
      this.helpfulCount += 1;
      this.helpfulService.markAsHelpful(id).subscribe({
        next: (response) => {
          if (!response.success) {
            this.failedToVoteHelpful();
          }
        },
        error: () => {
          this.failedToVoteHelpful();
        },
      });
    }
  }

  failedToUnVoteHelpful() {
    this.isHelpful = true;
    this.helpfulCount = this.initialHelpfulCount;
  }

  failedToVoteHelpful() {
    this.isHelpful = false;
    if (this.helpfulCount > 0) this.helpfulCount = this.initialHelpfulCount;
  }

  handleClickReport(id: string): void {
    this.emitOpenReportDialog.emit(id);
  }

  handleShowViewMore(els: ElementRef, height: number): void {
    const elementHeight = els.nativeElement.offsetHeight;
    if (elementHeight > height) {
      this.isLongText = true;
    } else {
      this.isLongText = false;
    }
    this.isExpanded = false;
  }

  ngAfterViewInit(): void {
    this.handleShowViewMore(this.reviewContent, 24 * 3);
    this.cdRef.detectChanges();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    const elementHeight = this.reviewContent.nativeElement.offsetHeight;

    if (elementHeight > 24 * 3) {
      this.isLongText = true;
      this.isExpanded = false;
      this.renderer.addClass(this.reviewContent.nativeElement, 'line-clamp-3');
    }

    if (elementHeight == 24 * 3) {
      this.renderer.removeClass(this.reviewContent.nativeElement, 'line-clamp-3');
      const actualHeight = this.reviewContent.nativeElement.offsetHeight;

      if (actualHeight > 24 * 3) {
        this.isLongText = true;
        this.renderer.addClass(this.reviewContent.nativeElement, 'line-clamp-3');
      } else this.isLongText = false;
      this.isExpanded = false;
    }

    if (elementHeight < 24 * 3) {
      this.isLongText = false;
      this.isExpanded = false;
    }
  }

  // replaceWithDefaultImage(event: any): void {
  //   event.target.src = 'assets/images/test/default-hotel.svg';
  // }
  // isImageFile(extension: string) {
  //   try {
  //     const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.tiff', '.webp'];
  //     const fileExtension = extension.toLowerCase();
  //     return imageExtensions.includes(fileExtension);
  //   } catch (error) {
  //     return false;
  //   }
  // }
  // showPreview(file: any) {
  //   this.isShowPopupPreview = true;
  //   this.previewFile = file;
  // }
  // onClosePreview() {
  //   this.isShowPopupPreview = false;
  // }
  ngOnDestroy() {
    if (this.openImageRef) {
      this.ref.close();
    }
  }
}
