import { Directive, Input, ElementRef, Renderer2 } from '@angular/core';
import { SoctripTranslationService } from 'src/app/services/soctrip-translation/soctrip-translation.service';

@Directive({
  selector: '[message-error]',
})
export class MeesageErrorFormComponent {
  @Input('message-error') errorMessage: string | undefined;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private soctripTranslationService: SoctripTranslationService
  ) {}

  ngOnInit() {
    if (this.errorMessage) {
      const iconElement = this.renderer.createElement('i');
      this.renderer.addClass(iconElement, 'sctr-icon-alert-circle');

      const iconSpan = this.renderer.createElement('span');
      this.renderer.appendChild(iconSpan, iconElement);

      const textSpan = this.renderer.createElement('span');
      const text = this.renderer.createText(
        this.errorMessage +
          ' ' +
          this.soctripTranslationService.getTranslation(
            'COMMON.IS_REQUIRED'
          )
      );

      this.renderer.appendChild(textSpan, text);

      const flexDiv = this.renderer.createElement('div');
      this.renderer.addClass(flexDiv, 'flex');
      this.renderer.addClass(flexDiv, 'place-items-center');
      this.renderer.addClass(flexDiv, 'gap-1');
      this.renderer.addClass(flexDiv, 'font-inter');
      this.renderer.addClass(flexDiv, 'text-sm');
      this.renderer.addClass(flexDiv, 'text-[#dc3545]');
      this.renderer.addClass(iconSpan, 'flex');
      this.renderer.addClass(iconSpan, 'flex-col');
      this.renderer.addClass(iconSpan, 'justify-center');

      this.renderer.appendChild(flexDiv, iconSpan);
      this.renderer.appendChild(flexDiv, textSpan);

      this.renderer.appendChild(this.el.nativeElement, flexDiv);
    }
  }
}
