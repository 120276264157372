import { Component, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { BookingType } from '@modules/car-booking/models/booking-type.model';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { SearchBookingIDService } from '@modules/car-booking/services/search-booking-id.service';
import {
  ReservationControllerService,
  ReservationRequestCodeDTO,
} from '@booking/booking-reservation-service';
import { TranslateService } from '@ngx-translate/core';
import { ReservationSearchService } from '@modules/car-booking/services/reservation-service/reservation-search.service';
import { firstValueFrom } from 'rxjs';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { environment } from '@env/environment';
import { OrderControllerService } from '@car-rental/angular-car-rental-order-service';
import { MY_BOOKING } from '@shared/constants/routes';

@Component({
  selector: 'app-booking-menu',
  templateUrl: './booking-menu.component.html',
  styleUrls: ['./booking-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('slide', [
      state('start', style({ transform: 'translateX(0)' })),
      state('end', style({ transform: 'translateX(320px)' })),
      transition('start => end', animate('1.5s ease-in-out')),
      transition('end => start', animate('0s')),
    ]),
  ],
})
export class BookingMenuComponent {
  state = 'start';
  interval: any;

  bookingType = 'car';
  bookingTypeList: BookingType[] = [];
  visible: boolean = false;
  isLoading: boolean = false;

  // nav modal
  modalBookingType = 'car';
  modalBookingTypeList: BookingType[] = [];
  disableSearchButton: boolean = true;
  searchBookingId: string = '';
  PINcode: string = '';
  isShowPinCode: boolean = false;
  responseDataSearch: any = [];
  statusResponse: number;
  baseImageId = environment.STORAGE_SERVICE_API_BASE_PATH_ID_IMAGE;
  pipe = new DatePipe('en-US');
  @ViewChild('searchInput', { static: false }) searchInput: ElementRef;

  showDialog() {
    this.visible = true;
  }

  constructor(
    private router: Router,
    private searchBookingIDService: SearchBookingIDService,
    private reservationController: ReservationControllerService,
    private translateService: TranslateService,
    private reservationSearchService: ReservationSearchService,
    private orderService: OrderControllerService
  ) {
    let MENU_ITEM = ['flight', 'car'];
    const commonElements = this.router.url
      .split('/')
      .filter((element_url) => MENU_ITEM.includes(element_url));

    this.bookingType = commonElements ? commonElements[0] : 'car';

    this.bookingTypeList = [
      {
        id: '1',
        booking_type_name: 'HEADER.MENU.BOOKING_MENU.FLIGHTS',
        short_name: 'flight',
        text_color: 'text-palette-blue-600',
        icon: 'sctr-icon-plane',
        url: environment.FLIGHT_URL,
      },
      {
        id: '2',
        booking_type_name: 'HEADER.MENU.BOOKING_MENU.HOTELS',
        short_name: 'hotel',
        text_color: 'text-palette-rose-600',
        icon: 'sctr-icon-building-01',
        url: environment.HOTEL_URL,
      },
      {
        id: '3',
        booking_type_name: 'HEADER.MENU.BOOKING_MENU.CAR_RENTALS',
        short_name: 'car',
        text_color: 'text-palette-orange-600',
        icon: 'sctr-icon-car-01',
        url: environment.CAR_URL,
      },
      {
        id: '4',
        booking_type_name: 'HEADER.MENU.BOOKING_MENU.TOURS',
        short_name: 'tour',
        text_color: 'text-palette-purple-600',
        icon: 'sctr-icon-flag-05',
        url: environment.TOUR_URL,
      },
    ];
    this.modalBookingTypeList = [
      {
        id: '1',
        booking_type_name: 'HEADER.MENU.BOOKING_MENU.FLIGHTS',
        short_name: 'flight',
        icon: 'sctr-icon-plane',
        text_color: 'text-palette-blue-600',
        background_color: 'bg-palette-blue-50',
        url: environment.FLIGHT_URL,
      },
      {
        id: '2',
        booking_type_name: 'HEADER.MENU.BOOKING_MENU.HOTELS',
        short_name: 'hotel',
        icon: 'sctr-icon-building-07',
        text_color: 'text-palette-rose-600',
        background_color: 'bg-palette-rose-50',
        url: environment.HOTEL_URL,
      },
      {
        id: '3',
        booking_type_name: 'HEADER.MENU.BOOKING_MENU.CAR_RENTALS',
        short_name: 'car',
        icon: 'sctr-icon-car-01',
        text_color: 'text-palette-orange-600',
        background_color: 'bg-palette-orange-50',
        url: environment.CAR_URL,
      },
      {
        id: '4',
        booking_type_name: 'HEADER.MENU.BOOKING_MENU.TOURS',
        short_name: 'tours',
        icon: 'sctr-icon-flag-05',
        text_color: 'text-palette-purple-600',
        background_color: 'bg-palette-purple-50',
        url: environment.TOUR_URL,
      },
    ];
  }

  ngOnInit(): void {
    this.startImageSlider();
  }

  ngOnDestroy(): void {
    this.stopImageSlider();
  }

  startImageSlider() {
    this.interval = setInterval(() => {
      this.moveImage();
    }, 3000);
  }

  stopImageSlider() {
    clearInterval(this.interval);
  }

  moveImage() {
    this.state = this.state === 'start' ? 'end' : 'start';
  }
  handleClickNav(menuItemName: BookingType) {
    this.bookingType = menuItemName.short_name;
    location.href = menuItemName.url;
    //this.router.navigate(['booking' + '/' + menuItemName.short_name]);
  }
  ngAfterViewInit() {
    setTimeout(() => {
      this.searchInput.nativeElement.value = '';
      this.searchInput.nativeElement.setAttribute('autocomplete', 'new-password');
    });
  }

  onCloseModal() {
    this.PINcode = '';
    this.searchBookingId = '';
    this.responseDataSearch = [];
    this.statusResponse = 0;
    this.searchBookingIDService.clearValue();
    this.disableSearchButton = true;
  }

  handleNavBookingType(typeBooking: string) {
    this.modalBookingType = typeBooking;
    const searchHistory = this.searchBookingIDService.getValue();

    const existingItemIndex = searchHistory.findIndex((item) => item.type_booking === typeBooking);

    if (existingItemIndex === -1) {
      this.searchBookingId = '';
      this.PINcode = '';
      this.responseDataSearch = [];
      this.statusResponse = 0;
    } else {
      this.searchBookingId = searchHistory[existingItemIndex].search_booking_id;
      this.PINcode = searchHistory[existingItemIndex].pin_code;
      this.responseDataSearch = [];
      this.statusResponse = 0;
    }

    this.checkSearchButton();
  }

  checkSearchButton() {
    if (this.searchBookingId !== '' && this.PINcode !== '') {
      this.disableSearchButton = false;
    } else {
      this.disableSearchButton = true;
    }
  }

  async handleClickSearch() {
    const reservationRequestDTO: ReservationRequestCodeDTO = {
      reservation_code: this.searchBookingId,
      code: this.PINcode,
    };

    try {
      this.isLoading = true;
      const response = await firstValueFrom(
        this.modalBookingType === 'car'
          ? this.orderService.ordersCodeCodePinCodeGet(this.searchBookingId, this.PINcode)
          : this.reservationController.getHotelReservation(reservationRequestDTO, 'body', true)
      );
      if (response.success && this.PINcode === response.data?.pin_code) {
        this.responseDataSearch = [
          {
            hotel_image_overview_url:
              this.modalBookingType === 'car'
                ? response.data.vehicle_avatar
                : response.data.property.property_image_overview_url,
            hotel_name:
              this.modalBookingType === 'car'
                ? response.data.vehicle_name
                : response.data.property.property_name,
            hotel_year:
              this.modalBookingType === 'car'
                ? response.data.vehicle.vehicle_info.year_model
                : null,
            reservation_code:
              this.modalBookingType === 'car' ? response.data.code : response.data.reservation_code,
            check_in:
              this.pipe.transform(
                this.modalBookingType === 'car'
                  ? response.data.start_date
                  : response.data.checkin_date,
                this.translateService.instant('COMMON.DATEFORMAT')
              ) || '',
            check_out:
              this.pipe.transform(
                this.modalBookingType === 'car'
                  ? response.data.end_date
                  : response.data.checkout_date,
                this.translateService.instant('COMMON.DATEFORMAT')
              ) || '',
            pin_code: response.data.pin_code,
          },
        ];
        this.reservationSearchService.setItemStorage({
          reservation_code: response.data.reservation_code,
          pin_code: response.data.pin_code,
        });
        this.isLoading = false;
      } else {
        this.responseDataSearch = [];
        this.isLoading = false;
      }
    } catch (err) {
      console.error(err);
    }
    this.statusResponse = 200;
  }

  onSearchBookingIdInputChange(event: any) {
    this.checkSearchButton();
    this.searchBookingIDService.setValue({
      type_booking: this.bookingType,
      search_booking_id: this.searchBookingId || '',
      pin_code: this.PINcode || '',
    });
  }
  togglePassword() {
    this.isShowPinCode = !this.isShowPinCode;
  }
  handleClickBookingSearchHotel(item: any) {
    window.scrollTo(0, 0);
    this.router.navigate(
      [`${environment.prefix}/${MY_BOOKING.MY_BOOKING}`, item.reservation_code],
      {
        queryParams: {
          pincode: item.pin_code,
        },
      }
    );
  }
  replaceWithDefaultImage(event: any): void {
    event.target.src = 'assets/images/test/default-hotel.svg';
  }
}
