export enum StatisticEnum {
  TOTAL_REVENUE = 'total_revenue',
  NUMBER_OF_CARS = 'number_of_cars',
  TOTAL_BOOKING = 'total_booking',
  AVERAGE_RENTAL_DURATION = 'average_rental_duration',
  AVERAGE_DAILY_SPEND_ON_RENTAL = 'average_daily_spend_on_rental',
  REVENUE_PER_CAR = 'revenue_per_car',
  CANCELLATION_RATE = 'cancellation_rate',
}

export enum StatisticUnitEnum {
  PRICE = 'price',
  PERCENT = 'percent',
  NORMAL = 'normal',
}

export enum CalendarOptionEnum {
  TODAY = 'TODAY',
  YESTERDAY = 'YESTERDAY',
  PAST_7_DAYS = 'PAST_7_DAYS',
  PAST_30_DAYS = 'PAST_30_DAYS',
  BY_DAY = 'BY_DAY',
  BY_WEEK = 'BY_WEEK',
  BY_MONTH = 'BY_MONTH',
  BY_YEAR = 'BY_YEAR',
}
