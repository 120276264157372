import { Component, Input } from '@angular/core';
import { environment } from '@env/environment';

@Component({
  selector: 'app-owner-contact-info',
  templateUrl: './owner-contact-info.component.html',
  styleUrls: ['./owner-contact-info.component.scss'],
})
export class OwwnerContactInformationComponent {
  @Input() agentDetailData: any;
  constructor() {}
  baseImageId = environment.STORAGE_SERVICE_API_BASE_PATH_ID_IMAGE;
}
