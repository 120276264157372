<div id="menu-bar" [ngClass]="handleMenuBar()" class="fixed top-0 z-20 w-full">
  <lib-header
    [environment]="environment"
    [userInfo]="userInfo"
    [currentPage]="currentPage"
    [myBookingUrl]="myBookingUrl"
    [visibleShopingCart]="false"
    [visibleSearch]="false"
    [(megaVisible)]="megaVisible"
    [loginReturnUrl]="environment.CAR_RENTAL_URL"
    (updateLanguage)="changeLanguage($event)"
  ></lib-header>
</div>
