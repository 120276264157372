import { Component, Input } from '@angular/core';
import { environment } from '@env/environment';
import { Currency, FooterService } from '@modules/car-booking/services/footer/footer.service';

@Component({
  selector: 'app-price-format',
  templateUrl: './price-format.component.html',
  styleUrls: ['./price-format.component.scss'],
})
export class PriceFormatComponent {
  @Input() keepValue: boolean = false;
  @Input() price: number;
  @Input() styleClass: string;
  currentCurrency: any;

  constructor(private footerService: FooterService) {}
  ngOnInit(): void {
    this.currentCurrency =
      JSON.parse(localStorage.getItem(environment.CURRENCY_CONVERSATION_DATA) || '')?.currency ||
      'USD';
  }

  formatPrice(price: number): string {
    const formattedPrice = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: this.currentCurrency,
    }).format(price);
    return formattedPrice;
  }
}
