import { Pipe, PipeTransform } from '@angular/core';
import { RoundNumberService } from '@shared/services/round-number.service';

import { CurrencyConversionPipe } from '@soctrip-common/app-security';

@Pipe({
  standalone: true,
  name: 'shortCurrencyPipe',
})
export class ShortCurrencyPipe implements PipeTransform {
  smallNumText = '0';
  regex = /^\D+/;
  constructor(
    private currencyConversionPipe: CurrencyConversionPipe,
    private roundNumberService: RoundNumberService
  ) {}

  transform(value: number, isSymbolOnly: boolean = false, keepValue: boolean = false): any {
    const transformedNumber = this.currencyConversionPipe.transform(value, true);
    const match = transformedNumber.match(this.regex);
    const currencySymbol = match ? match[0] : '';
    const symbolLength = match ? match[0].length : 0;
    let currencyValue;

    if (transformedNumber.charAt(symbolLength) !== this.smallNumText) {
      currencyValue = this.roundNumberService.roundNumber(
        keepValue ? value : parseFloat(transformedNumber.replace(/[^\d.]/g, '')),
        0
      );
    } else {
      currencyValue = this.roundNumberService.roundNumber(
        keepValue ? value : parseFloat(transformedNumber.replace(/[^\d.]/g, '')),
        2
      );
    }

    let shortValue = currencyValue;
    let suffix = '';

    if (currencyValue >= 10000 && currencyValue < 10000000) {
      shortValue = Math.floor(currencyValue / 1000);
      suffix = 'k';
    } else if (currencyValue >= 10000000 && currencyValue < 1000000000) {
      shortValue = Math.floor(currencyValue / 1000000);
      suffix = 'M';
    } else if (currencyValue >= 1000000000) {
      shortValue = Math.floor(currencyValue / 1000000000);
      suffix = 'B';
    } else {
      shortValue = currencyValue;
    }

    const finalValue = shortValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    if (isSymbolOnly) {
      return currencySymbol;
    } else {
      return finalValue + suffix;
    }
  }
}
