<div class="container-tab-menu">
  <div class="tab-menu">
    <div class="item-tab-menu"
      *ngFor="let tab of tabs; let i = index"
      [ngClass]="{ 'active': activeTab === i }"
      (click)="setActiveTab(i)"
    >
      {{ tab.title | translate}}
    </div>
  </div>
</div>

