import { BOOKING } from '@shared/constants/routes';
import moment from 'moment';

export const ROLES = {
  user: 'user',
  manager: 'manager',
  admin: 'admin',
};
export const USER_PERMISSION = [ROLES.user, ROLES.manager, ROLES.admin];
export const MANAGER_PERMISSION = [ROLES.admin, ROLES.manager];
export const ADMIN_PERMISSION = [ROLES.admin];

export const DEFAULT_SEARCH_PARAMS = {
  page_num: 1,
  page_size: 20,
  num_of_adults: 1,
  num_of_children: 0,
  num_of_rooms: 1,
  child_ages_list: [],
  currency: 'USD',
  country_code: 'US',
  checkin: moment().format('yyyy-MM-DD'),
  checkout: moment().add(1, 'day').format('yyyy-MM-DD'),
};

export const URL_COUNTRY_CODE = 'https://ipgeolocation.abstractapi.com/v1/?api_key=';
export const APIKEY_COUNTRY_CODE = 'da81649210074b6f8003c7903841046f';

export const TIME_OUT_REQUEST = 30000;

export const MEDIA_FILE_SIZE_LIMITS_BYTES = {
  image: 5242880,
  video: 104857600,
};

export const MEDIA_FILE_FORMAT_LIMITS = {
  image: [
    'image/avif',
    'image/jpeg',
    'image/png',
    'image/gif',
    'image/jpg',
    'image/bmp',
    'image/svg+xml',
    'image/webp',
    'image/tiff',
  ],
  video: [
    'video/mp4',
    'video/mpeg',
    'video/ogg',
    'video/webm',
    'video/3gpp',
    'video/x-msvideo',
    'video/x-ms-wmv',
    'video/quicktime',
  ],
};

export const MEDIA_TYPE = {
  image: 'Image',
  video: 'Video',
  file: 'File',
};

export const TOAST_MESSAGE_LIFETIME_DURATIONS = {
  short: 2000,
  medium: 5000,
  long: 7000,
};

export const TOAST_MESSAGE_SEVERITY_LEVELS = {
  warn: 'warn',
  info: 'info',
  success: 'success',
  error: 'error',
};

export const MY_BOOKING_STATUS = {
  all: 'ALL',
  toPay: 'TO_PAY',
  waitingForConfirm: 'WAITING_FOR_CONFIRM',
  confirmed: 'CONFIRMED',
  noShow: 'NO_SHOW',
  completed: 'COMPLETED',
  canceled: 'CANCELED',
  refunded: 'REFUNDED',
  refundProcessing: 'REFUND_PROCESSING',
  pendingReview: 'PENDING_REVIEW',
  failed: 'FAILED',
  inProgress: 'IN_PROGRESS',
  paymentFailed: 'PAYMENT_FAILED',
};

export const GROUP_MY_BOOKING_STATUS = {
  all: 'ALL',
  waiting: 'WAITING_FOR_CONFIRM',
  confirmed: 'CONFIRMED',
  completed: 'COMPLETED',
  inProgress: 'IN_PROGRESS',
  canceled: 'CANCELED',
  refund: 'REFUNDED',
  to_pay: 'TO_PAY',
  payment_failed: 'PAYMENT_FAILED',
};

export const BOOKING_STATUS_POST_ACTIONS = {
  review: [MY_BOOKING_STATUS.pendingReview, MY_BOOKING_STATUS.noShow, MY_BOOKING_STATUS.completed],
  cancel: [
    MY_BOOKING_STATUS.toPay,
    MY_BOOKING_STATUS.waitingForConfirm,
    MY_BOOKING_STATUS.confirmed,
  ],
};
