import { Injectable } from '@angular/core';
import { BookmarkControllerService } from '@soctrip/angular-bookmarks-service';
import { UserService } from 'src/app/core/services/user/user.service';
import { BehaviorSubject, Observable, of } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class BookMarkService {
    private mySavedList = new BehaviorSubject<any>([]);
    private recentItem = new BehaviorSubject<any>(null);
    public bloomFilter: string = "";
    public isAllow: boolean = false;

    private apiSuccessSubject = new BehaviorSubject<boolean>(false);
    apiSuccess$ = this.apiSuccessSubject.asObservable();

    constructor(
        private bookmarkControllerService: BookmarkControllerService,
        private userService: UserService,
    ) {}

    getBloomFilter() {
        this.userService.getLoginStatus().subscribe({
            next: (res) => {
                if (res) {
                    this.bookmarkControllerService.bookmarksBloomFilterGet().subscribe({
                        next: (res) => {
                            if (res.success) {
                                this.bloomFilter = res.data.bloom_filter;
                                this.apiSuccessSubject.next(true);
                                this.isAllow = true;
                            }
                        },
                    });
                }
            }
        });
    }

    createBookMark(id: string, section: string | null = null) {
        let BookmarkBasicDTO = {
            object_id: id,
            dag_object: 'CAR_BOOKMARK'
        }
        if (section) {
            this.recentItem.next({
                object_id: id,
                section,
                type: 'create'
            })
        }
        return this.bookmarkControllerService.bookmarksPost(BookmarkBasicDTO);
    }

    deleteBookmark(id: string, section: string | null = null) {
        if (section) {
            this.recentItem.next({
                object_id: id,
                section,
                type: 'delete'
            })
        }
        return this.bookmarkControllerService.bookmarksObjectIdDelete(id);
    }

    getRecentItem(): Observable<any> {
        return this.recentItem.asObservable();
    }

    addItemToMySavedList(newItem: any) {
        let currentItems = this.mySavedList.getValue();
        currentItems.unshift(newItem);
        this.mySavedList.next(currentItems);
    }

    deleteItemOfMySavedList(id: string) {
        let currentItems = this.mySavedList.getValue();
        const indexToRemove = currentItems.findIndex((obj: any) => obj.property_id === id);
        if (indexToRemove !== -1) {
            currentItems.splice(indexToRemove, 1);
        }
        this.mySavedList.next(currentItems);
    }

    setSavedList(list: any): void {
        this.mySavedList.next(list);
    }

    getSavedList(): Observable<any> {
        return this.mySavedList.asObservable();
    }

}
