import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { Subject, takeUntil } from 'rxjs';
import { ConfirmationService } from 'primeng/api';
import { ConfirmationDialogService } from '@shared/services/confirmation-dialog.service';
import { SoctripTranslationService } from 'src/app/services/soctrip-translation/soctrip-translation.service';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss'],
})
export class ConfirmationComponent {
  header: string = '';
  message: string = '';
  type: 'btnCancel' | 'btnLanguage' | 'btnDeletePlace' | 'btnDeleteDay' | 'btnClose';
  cancelBtnLabel: string;
  confirmBtnLabel: string;
  isDisableConfirmBtn: boolean = false;
  responsiveModal: boolean;
  isLoading: boolean = false;
  private destroy$ = new Subject<void>();

  constructor(
    public confirmationService: ConfirmationService,
    public confirmationDialogService: ConfirmationDialogService,
    private translationService: SoctripTranslationService
  ) {}

  get visible(): boolean {
    return this.confirmationDialogService.confirmationDataSubject.value !== null;
  }

  ngOnInit(): void {
    this.confirmationDialogService.confirmationData$.subscribe((data) => {
      if (data) {
        this.header = data.header;
        this.message = data.message;
        this.type = data.type;
        this.cancelBtnLabel =
          data.cancelBtnLabel ||
          this.translationService.getTranslationWithParam('common.term.cancel');
        this.confirmBtnLabel =
          data.confirmBtnLabel ||
          this.translationService.getTranslationWithParam('common.term.confirm');
        this.responsiveModal = data.responsiveModal || false;
        this.isDisableConfirmBtn = data.disableConfirmBtn || false;
      }
    });
    this.confirmationDialogService.isLoadingConfirm
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.isLoading = value;
      });
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
