import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PropertyAmenitiesService {
  private collapseAll = new Subject<boolean>();
  public collapseAll$ = this.collapseAll.asObservable();
  private selectedAmenities = new BehaviorSubject<any>({});
  private isChargeAmenities = new BehaviorSubject<any>({});

  notiCollapseAll(data: boolean) {
    this.collapseAll.next(data);
  }

  getSelectedAmenities() {
    return this.selectedAmenities.asObservable();
  }

  setSelectedAmenities(data: any) {
    this.selectedAmenities.next(data);
  }

  getIsChargeAmenities() {
    return this.isChargeAmenities.asObservable();
  }

  setIsChargeAmenities(data: any) {
    this.isChargeAmenities.next(data);
  }
}
