import { Injectable } from '@angular/core';
import {
  RentalUserControllerService,
  ResponseModelRentalUserRoleDetailDTO,
  ValidateMediaDataDTO,
} from '@car-rental/angular-car-rental-ota-service';
import { UserService } from './user.service';
import { BehaviorSubject, Observable, catchError, filter, map, of, switchMap, tap } from 'rxjs';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  ResolveFn,
  RouterStateSnapshot,
} from '@angular/router';
import { PermissionService } from '../permission/permission.service';

@Injectable({
  providedIn: 'root',
})
export class UserRolesResolverService {
  private _agencyRoleCache: ResponseModelRentalUserRoleDetailDTO | undefined;

  constructor(
    private retalUserControllerService: RentalUserControllerService,
    private userService: UserService,
    private permissionService: PermissionService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.getAgencyRoles().pipe(
      map((res) => {
        if (res.success) {
          const agencies = res.data?.agencies ?? [];
          const agency = agencies.find(item => state.url.includes(item.agency_id!));

          if (agency?.permission) {
              this.permissionService.nextPermissions(agency.permission);
          }
        }
      })
    );
  }

  getAgencyRoles(): Observable<ResponseModelRentalUserRoleDetailDTO> {
    if (this._agencyRoleCache && Object.keys(this._agencyRoleCache).length !== 0) return of(this._agencyRoleCache);

    const userId = this.userService.getUserProfileFromLocalStorage()?.id;
    if (!userId) return of ({} as ResponseModelRentalUserRoleDetailDTO);

    return this.retalUserControllerService.getRentalUserRolesById(userId).pipe(
      tap(res => this._agencyRoleCache = res),
      catchError(err => of({} as ResponseModelRentalUserRoleDetailDTO))
    );
  }

  resetAgencyRoles(){
    this._agencyRoleCache = undefined;
  }
}
