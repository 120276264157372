import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PropertyAmenitiesManagementService {
  private isViewOnly = new BehaviorSubject<boolean>(false);

  setIsViewOnly(isViewOnly: any) {
    this.isViewOnly.next(isViewOnly);
  }

  getIsViewOnly(): Observable<any> {
    return this.isViewOnly.asObservable();
  }
}