import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-journey-information',
  templateUrl: './journey-information.component.html',
  styleUrls: ['./journey-information.component.scss'],
})
export class JourneyInformationComponent {
  @Input() keepValue: boolean = false;
  @Input() vehiclePriceData: any;
  constructor() {}
  ngOnInit(): void {
  }
}
