import { DateTime } from 'luxon';
import { Pipe, PipeTransform, inject } from '@angular/core';
import { DatePipe } from '@angular/common';
import { TimeFormat } from '@shared/enum/time-format.enum';
import { SoctripTranslationService } from 'src/app/services/soctrip-translation/soctrip-translation.service';

@Pipe({
  name: 'customTime',
})
export class CustomTimePipe extends DatePipe implements PipeTransform {
  private translationService = inject(SoctripTranslationService);
  private localeString = this.translationService.getLocale();

  override transform(value: any, format?: string): any {
    const timeValue = new Date(value).toISOString();
    const timeFormat =
      format && (Object.values(TimeFormat) as string[]).includes(format) ? format : 'TIME_SIMPLE';

    return DateTime.fromISO(timeValue)
      .setLocale(this.localeString)
      .toLocaleString(DateTime[timeFormat as TimeFormat]);
  }
}
