<div
  [ngClass]="{
    'shadow-sx col-span-2 mt-3 rounded-xl border border-palette-gray-200 bg-white px-4':
      isDependencyPanel
  }"
>
  <p class="!mb-3 !pt-4 text-base font-semibold text-palette-gray-800 md:!text-xl">
    {{ 'APP_CAR_CANCELLATION-POLICY.CANCEL_POLICY' | soctripTranslate | async }}
  </p>
  <div class="p-2">
    <div class="grid grid-cols-1 p-2">
      <div
        class="grid grid-cols-3 rounded-tl-lg rounded-tr-lg bg-gray-100 p-2 px-4 !text-md font-medium text-[#344054]"
      >
        <div class="col-span-1">
          {{ 'APP_CAR_CANCELLATION-POLICY.CANCELLATION_PERIOD' | soctripTranslate | async }}
        </div>
        <div class="col-span-1 text-center">{{ 'APP_CAR_CANCELLATION-POLICY.tenant_cancel' | soctripTranslate | async }}</div>
        <div class="col-span-1 text-center">
          {{ 'APP_CAR_CANCELLATION-POLICY.CAR_OWNER_CANCEL' | soctripTranslate | async }}
        </div>
      </div>
    </div>

    <div class="grid grid-cols-1 border border-t-0 py-2 !px-4">
      <div
        class="grid grid-cols-3 rounded-tl-xl rounded-tr-xl p-2 pb-0 text-md font-normal text-[#344054]"
      >
        <div class="col-span-1 flex items-center">
          {{ 'APP_CAR_CANCELLATION-POLICY.WITHIN_1H_AFTER_BOOKING_CONFIRMED' | soctripTranslate | async }}
        </div>
        <div class="col-span-1">
          <common-icon-text
            class="flex flex-col items-center !gap-1"
            [icon]="'sctr-icon-check-circle-broken'"
            [iconStyle]="'text-xl/none text-system-success-600'"
            text="{{ 'APP_CAR_CANCELLATION-POLICY.free_cancellation' | soctripTranslate : { deposit: 100 } | async }}"
            [textStyle]="'text-[#344054] font-normal text-md'"
          >
          </common-icon-text>
        </div>
        <div class="col-span-1">
          <common-icon-text
            class="flex flex-col items-center !gap-1"
            [icon]="'sctr-icon-check-circle-broken'"
            [iconStyle]="'text-xl/none text-system-success-600'"
            text=" {{ 'APP_CAR_CANCELLATION-POLICY.free_cancellation' | soctripTranslate | async }}"
            [textStyle]="'text-[#344054] font-normal text-md'"
          >
          </common-icon-text>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-1 border border-t-0 py-2 !px-4">
      <div class="grid grid-cols-3 rounded-tl-xl rounded-tr-xl p-2 pb-0 pt-3">
        <div class="col-span-1 text-md font-normal text-[#344054] flex items-center">
          {{ 'APP_CAR_CANCELLATION-POLICY.more_than_7_days_before_check_in' | soctripTranslate | async }}
        </div>
        <div class="col-span-1 text-center">
          <common-icon-text
            class="flex flex-col items-center !gap-1"
            [icon]="'sctr-icon-check-circle-broken'"
            [iconStyle]="'text-xl/none text-system-success-600'"
            text="{{ 'APP_CAR_CANCELLATION-POLICY.REFUND_FEES' | soctripTranslate  : { deposit: 70 } | async }}"
            [textStyle]="'text-[#344054] font-normal text-md'"
          >
          </common-icon-text>
        </div>
        <div class="col-span-1 text-center">
          <common-icon-text
            class="flex flex-col items-center !gap-1"
            [icon]="'sctr-icon-check-circle-broken'"
            [iconStyle]="'text-xl/none text-system-success-600'"
            text="{{ 'APP_CAR_CANCELLATION-POLICY.compensate_deposit' | soctripTranslate  : { deposit: 30 } | async }}"
            [textStyle]="'text-[#344054] font-normal text-md'"
          >
          </common-icon-text>
        </div>
      </div>
    </div>

    <div class="grid grid-cols-1 rounded-b-xl border border-t-0 !px-4 pt-4">
      <div class="grid grid-cols-3 rounded-tl-xl rounded-tr-xl p-2 pt-0">
        <div class="col-span-1 text-md font-normal text-[#344054] flex items-center">
          {{ 'APP_CAR_CANCELLATION-POLICY.7_days_or_less_before_check_in_date' | soctripTranslate | async }}
        </div>
        <div class="col-span-1 text-center">
          <common-icon-text
            class="flex flex-col items-center !gap-1"
            [icon]="'sctr-icon-x-circle'"
            [iconStyle]="'text-xl/none text-system-error-600'"
            text="{{ 'APP_CAR_CANCELLATION-POLICY.NONE_REFUNDABLE' | soctripTranslate | async }}"
            [textStyle]="'text-[#344054] font-normal text-md'"
          >
          </common-icon-text>
        </div>
        <div class="col-span-1 text-center">
          <common-icon-text
            class="flex flex-col items-center !gap-1"
            [icon]="'sctr-icon-x-circle'"
            [iconStyle]="'text-xl/none text-system-error-600'"
            text="{{ 'APP_CAR_CANCELLATION-POLICY.compensate_deposit' | soctripTranslate : { deposit: 100 } | async }}"
            [textStyle]="'text-[#344054] font-normal text-md'"
          >
          </common-icon-text>
        </div>
      </div>
    </div>
  </div>
  <div
    class="mb-4 mt-2 flex flex-col gap-2 rounded-lg bg-palette-gray-100 p-3 text-md font-normal text-[#344054]"
  >
    <p>&#x2022; {{ 'APP_CAR_CANCELLATION-POLICY.THE_TENANT_WILL_NOT_REFUND' | soctripTranslate | async }}</p>
    <p>&#x2022; {{ 'APP_CAR_CANCELLATION-POLICY.THE_DEPOSIT_MONEY' | soctripTranslate | async }}</p>
  </div>
</div>
