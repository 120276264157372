import { DateTime } from 'luxon';
import { Injectable, inject } from '@angular/core';
import { SoctripTranslationService } from 'src/app/services/soctrip-translation/soctrip-translation.service';

import { DateTimeFormat } from '@shared/enum/date-time-format.enum';
import { DateFormat } from '@shared/enum/date-format.enum';
import { TimeFormat } from '@shared/enum/time-format.enum';

export const localeLanguages: Map<string, string> = new Map([
  ['en', 'en-US'],
  ['vi', 'vi-VN'],
]);

@Injectable({
  providedIn: 'root',
})
export class CustomDateTimeService {
  private translationService = inject(SoctripTranslationService);
  private readonly localeString = this.translationService.getLocale();

  formatDateTime(value: any, format?: DateTimeFormat): string {
    try {
      const dateTimeValue = new Date(value).toISOString();
      const dateTimeFormat =
        format && (Object.values(DateTimeFormat) as string[]).includes(format)
          ? format
          : DateTimeFormat.DATETIME_SHORT__24H;
      const formatSplited: string[] = dateTimeFormat.split('__');
      return DateTime.fromISO(dateTimeValue)
        .setLocale(this.localeString)
        .toLocaleString(
          formatSplited[1]
            ? {
                ...DateTime[formatSplited[0] as keyof typeof DateTime],
                hourCycle: 'h23',
                hour: '2-digit',
              }
            : { ...DateTime[formatSplited[0] as keyof typeof DateTime] }
        );
    } catch (error) {
      return '';
    }
  }

  formatDate(value: any, format?: DateFormat): string {
    const dateValue = new Date(value).toISOString();
    const dateFormat =
      format && (Object.values(DateFormat) as string[]).includes(format) ? format : 'DATE_SHORT';
    return DateTime.fromISO(dateValue)
      .setLocale(this.localeString)
      .toLocaleString(DateTime[dateFormat as TimeFormat]);
  }

  formatTime(value: any, format?: TimeFormat): string {
    const timeValue = new Date(value).toISOString();
    const timeFormat =
      format && (Object.values(TimeFormat) as string[]).includes(format) ? format : 'TIME_SIMPLE';

    return DateTime.fromISO(timeValue)
      .setLocale(this.localeString)
      .toLocaleString(DateTime[`${timeFormat}`]);
  }

  convertToDateTime(datetimeString: any): DateTime | null {
    if (datetimeString === null || datetimeString === undefined) return DateTime.now();
    const date = DateTime.fromJSDate(new Date(datetimeString));
    // date = date.setZone(timezone);

    if (date.isValid) {
      return date;
    }
    return null;
  }
}
