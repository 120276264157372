import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 't-media-preview',
  templateUrl: './media-preview.component.html',
  styleUrls: ['./media-preview.component.scss'],
})
export class MediaPreviewComponent implements OnInit {
  @Input() medias: any[] = [];
  isShowPopupPreview: boolean = false;
  previewFile: any = null;

  constructor() {}
  ngOnInit() {}

  showPreview(file: any) {
    this.isShowPopupPreview = true;
    this.previewFile = file;
  }
  onClosePreview() {
    this.isShowPopupPreview = false;
  }
  replaceWithDefaultImage(event: any): void {
    event.target.src = 'assets/images/test/default-hotel.svg';
  }
  isImageFile(extension: string) {
    try {
      const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.tiff', '.webp'];
      const fileExtension = extension.toLowerCase();
      return imageExtensions.includes(fileExtension);
    } catch (error) {
      return false;
    }
  }
}
