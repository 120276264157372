<div *ngIf="medias && medias.length > 0" class="t-media-preview flex flex-wrap !gap-3">
  <div class="flex" *ngFor="let file of medias">
    <img
      *ngIf="isImageFile(file.extension)"
      (click)="showPreview(file)"
      [src]="file.id | ImagePath : 'thumbnail'"
      (error)="replaceWithDefaultImage($event)"
      class="max-h-[80px] min-h-[80px] min-w-[80px] max-w-[80px] cursor-pointer rounded-md border"
      width="80"
      height="80"
      alt="review_image"
    />
    <div
      class="relative h-[80px] w-[80px] cursor-pointer rounded-md border"
      *ngIf="!isImageFile(file.extension)"
      (click)="showPreview(file)"
    >
      <span
        class="absolute left-1/2 top-1/2 flex -translate-x-1/2 -translate-y-1/2 transform rounded-full bg-palette-gray-700 p-2 opacity-80"
      >
        <i class="sctr-icon-play text-white"></i>
      </span>
      <video
        *ngIf="!isImageFile(file.extension)"
        class="max-h-[80px] min-h-[80px] min-w-[80px] max-w-[80px] rounded-md object-cover"
        width="80"
        height="80"
      >
        <source [src]="file.id | VideoPath" id="video_here" />
      </video>
    </div>
  </div>
</div>

<div
  *ngIf="isShowPopupPreview"
  class="fixed bottom-0 left-0 right-0 top-0 z-[9999] h-screen !overflow-hidden !overflow-x-hidden !overflow-y-hidden bg-palette-base-black bg-opacity-90"
>
  <div class="relative">
    <div (click)="onClosePreview()" class="absolute right-0 z-50 cursor-pointer !p-3 !text-4xl">
      <i class="sctr-icon-x-close text-palette-base-white"></i>
    </div>
  </div>
  <div class="relative flex h-screen items-center justify-center">
    <div>
      <img
        *ngIf="isImageFile(previewFile.extension)"
        class="max-h-[850px] max-w-[850px]"
        [src]="previewFile.id | ImagePath : 'thumbnail'"
      />
      <video
        *ngIf="!isImageFile(previewFile.extension)"
        class="max-h-[850px] max-w-[850px]"
        controls
        autoplay
        type="video/mp4"
      >
        <source [src]="previewFile.id | VideoPath" id="video_here" />
      </video>
    </div>
  </div>
</div>
