import { Component,Output,EventEmitter, Input, HostListener } from '@angular/core';
import { CommonModule } from '@angular/common';
interface Tab {
  title: string;
  short_name: string
}
@Component({
  selector: 'app-tab-menu',
  templateUrl: './tab-menu.component.html',
  styleUrls: ['./tab-menu.component.scss']
})
export class TabMenuComponent {
  activeTab: number | null = 0;
  animateBorder: boolean = false;
  @Output() indexSelectNumber = new EventEmitter();
  @Input() numberIndexSub:number;
  tabs: Tab[] = [
    { title: 'DETAIL.GENERAL', short_name: 'general'},
    { title: 'DETAIL.ABOUT', short_name: 'about' },
    { title: 'DETAIL.AMENITIES', short_name: 'amenities' },
    { title: 'DETAIL.POLICIES', short_name: 'policies' },
    { title: 'DETAIL.REVIEWS', short_name: 'reviews' }
  ];
  ngOnInit(){
    this.activeTab = this.numberIndexSub;
  }
  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event) {
    this.activeTab = this.numberIndexSub;

  }
  setActiveTab(index: number): void {
    this.indexSelectNumber.emit(index);
  }
  }

