<div class="t-confirmation" [ngClass]="{ 't-responsive-modal': responsiveModal }">
  <p-confirmDialog styleClass="t-custom-dialog" [message]="message" [visible]="visible">
    <ng-template pTemplate="header">
      <div class="flex justify-between w-full items-center">
        <span class="text-gray-900 text-xl font-semibold self-stretch">{{ header }}</span>
        <button
          type="button"
          class="flex justify-center items-center hover:bg-gray-50 absolute right-4 top-4 hover:rounded-2xl p-2.5"
          (click)="
            confirmationDialogService.executeRejectCallback();
            confirmationDialogService.clearConfirmationData()
          "
        >
          <span class="pi pi-times"></span>
        </button>
      </div>
    </ng-template>
    <ng-template pTemplate="footer">
      <div class="w-full flex justify-end items-center gap-3 self-stretch">
        <button
          *ngIf="type !== 'btnClose'"
          class="!text-gray-700 !bg-white hover:!bg-gray-50 hover:!text-gray-600 rounded-lg py-[10px] px-[18px] border"
          (click)="
            confirmationDialogService.executeRejectCallback();
            confirmationDialogService.clearConfirmationData()
          "
        >
          {{ cancelBtnLabel }}
        </button>
        <button
          class="!text-white border-none rounded-lg border"
          [disabled]="isDisableConfirmBtn"
          [ngClass]="{
            '!bg-red-500 hover:!bg-red-600':
              type === 'btnCancel' || type === 'btnDeletePlace' || type === 'btnDeleteDay',
            '!bg-primary-600 hover:!bg-primary-500':
              type !== 'btnCancel' && type !== 'btnDeletePlace' && type !== 'btnDeleteDay'
          }"
          type="button"
          pButton
          (click)="confirmationDialogService.executeAcceptCallback()"
        >
          <span *ngIf="isLoading" class="text-[1.25rem] pi pi-spin pi-spinner"></span>
          <p>{{ confirmBtnLabel }}</p>
        </button>
      </div>
    </ng-template>
  </p-confirmDialog>
</div>
