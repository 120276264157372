<app-modal (emitOnCloseDialog)="isShow = false" [showDialog]="isShow"  [dialogConfig]="dialogConfig">
      <ng-container modal-header>
        <p class="font-semibold text-xl text-[#101828]">Review contract</p>
      </ng-container>
      <ng-container modal-content>
        <div class="bg-white">
          <img src="assets//images/ota/banner.png" class="w-[55.25rem]  pb-4">
        <p class="text-branding-primary-600 font-bold text-2xl pb-4 px-4">Car rental cooperation agreement</p>
        <div class="overflow-y-scroll max-h-[23rem] px-4">
          <p class="text-lg font-bold">PLEASE READ THIS PRIVACY POLICY CAREFULLY.</p>
          <p class="text-base font-normal whitespace-pre-line">it explains how The Five Star Travel Corporation and/or its subsidiary and affiliated entities (collectively, "Forbes Travel Guide", "we", "us", or "our") collects information about you through our services and how this information is then used or disclosed. IF YOU DO NOT CONSENT TO OUR PRIVACY PRACTICES AS DESCRIBED IN THIS PRIVACY POLICY STATEMENT, PLEASE DO NOT USE OR ACCESS OUR SERVICES.</p>
          <p class="text-lg font-bold">A. GENERAL TERMS AND CONDITIONS</p>
          <p class="text-base font-normal whitespace-pre-line">
            Scope. This Privacy Policy applies to all of the websites, applications, and services where it is posted or incorporated by reference. Additional terms and conditions may apply to some services offered to you. Such terms and conditions may be found at the place where the relevant service is offered. This Privacy Policy does not apply to information we may collect from you or about you in other ways, including through emails you may send to us.
          </p>
          <p class="text-base font-normal whitespace-pre-line">
            Updates to the Privacy Policy. We may update this Privacy Policy at any time, with or without advance notice. In the event there are significant changes in the way we treat your personally identifiable information, or in the Privacy Policy terms and conditions, we will display a notice on the service or send you an email, so that you may review the changed terms prior to continuing to use the service. If you object to any of the changes to the Privacy Policy, and you no longer wish to use the service, you may close your account. Unless stated otherwise, our current Privacy Policy applies to all information that we have about you and your account. Using the services after a notice of changes has been sent to you or published on our service shall constitute consent to the changed terms or practices.
          </p>
          <p class="text-base font-normal whitespace-pre-line">
            Age Requirement. We do not intentionally gather Personal Information about visitors who are under the age of 13. You may not use our services if you are under the age of 13. You must also be old enough to consent to the processing of your personal data in your country.
          </p>
          <p class="text-base font-normal whitespace-pre-line">Acknowledgment of Terms. By using our services, you acknowledge that you have read this Privacy Policy and consent to our privacy practices as described in this Privacy Policy, including our use and disclosure of personal information for the purposes described below. You further affirm your consent by becoming a registered user or submitting content or materials to or through our services.</p>
          <p class="text-base font-normal whitespace-pre-line">
            Your California Privacy Rights. Residents of the State of California, please also read statement below titled Your California Privacy Rights to understand additional rights you may have pursuant to California Civil Code Section 1798.83.
          </p>
          <p class="text-lg font-bold">
            B. INFORMATION WE COLLECT THROUGH OUR SERVICES
          </p>
          <p class="text-base font-normal whitespace-pre-line">
            We collect two types of information from you and other visitors to our services: (1) "personally identifiable information" (as described below) and (2) non-personally identifiable information (as described below).
"Personally identifiable information" is information that identifies you personally, such as your name, address, telephone number, email address, or company name. Here are some examples of the ways in which we may collect and store your personally identifiable information through our services:
we may collect your email address, first and last name, and zip code, if you sign up for an online newsletter;
we may collect your email address if you contact us with a question;
we may collect your first and last name, date of birth, and email address if you choose to participate in a contest or sweepstakes; and
we may collect your first and last name, your address, and your credit card and billing information to enable you to make a purchase.
"Non-personally identifiable information" can be technical information or it can be demographic information, such as your age, gender, or interests. Non-personally identifiable information does not identify you personally. If you do provide us with non-personally identifiable information, we may use it for the purposes described in this Privacy Policy or where it is collected, or any other legal purpose.
Here are some examples of the ways in which we may collect and store your non-personally identifiable information through our services, and how we use such information:
Internet Protocol (IP) address. Your "IP address" is a number that lets computers attached to the Internet know where to send you data, such as the screens and pages of our services that you view. We use this information to deliver our screens and pages to you upon request, to tailor our services to the interests of you and our other visitors, and to measure traffic to and within our services.
Cookie. A "cookie" is a small text file that may be used to collect information about your activity on our services. For example, when you visit a screen or page on our services, a cookie is placed on your machine (if you or your browser accept the cookie). This cookie may then be read by screen or pages on our services. You may configure your browser to notify you or to reject and block cookies, but if you do, you may not have access to some areas of our services, or to the personalized features of our services.
Web Beacon. A "web beacon", "clear gif", "web bug", or "pixel tag" is a tiny graphic file with a unique identifier, that is similar in function to a cookie, but allows us to count the number of users that have visited certain pages or screens of our services, and to help determine the effectiveness of promotional or advertising campaigns. When used in HTML-formatted email messages, web beacons can tell the sender whether and when the email has been opened. In contrast to cookies, which may be stored on your computer's hard drive, web beacons are typically embedded invisibly on pages or screens.
Demographic Information. "Demographic information" may be your gender, age, zip code, and interests. We may collect such information about your through our services and use it to provide you with personalized services and to analyze trends to ensure that our services and the information on them is targeted to meet your needs. Please note that we also consider aggregated information, which is not personally identifiable, to be non-personally identifiable information.
If you encounter a screen or page that requests information you do not want to share with us, do not enter the information and do not proceed with that screen or page.
          </p>
        </div>
        <div class="pl-4 pt-2">
          <p-checkbox [(ngModel)]="accept" (ngModelChange)="onChangeAccept($event)" value="accept" inputId="accept"></p-checkbox>
        <label class="pl-2 text-md font-medium text-[#344054]" for="accept">I have read and agree to the terms and conditions of the Soctrip Car Rental Contract</label>
        </div>

        </div>

      </ng-container>
      <ng-container modal-footer>
        <div>
          <button
          (click)="cancel()"
          class="text-md font-semibold hover:bg-gray-200 bg-white cursor-pointer border border-gray-300 rounded-lg px-5 py-3"
         >
          Cancel
        </button>
        <button
        (click)="submit()"
        [ngClass]="[accept ? 'bg-branding-primary-600 hover:bg-branding-primary-700': 'disabled:bg-branding-primary-300 bg-branding-primary-300']"
          class="text-md font-semibold  text-white cursor-pointer rounded-lg px-5 py-3">

          Submit
        </button>
        </div>
      </ng-container>
    </app-modal>
