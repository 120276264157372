<div
  (click)="onClickIconShare()"
  class="flex h-9 min-h-[44px] w-9 min-w-[44px] cursor-pointer items-center justify-center rounded-lg border border-palette-gray-300 text-gray-700"
>
  <img src="assets/images/home/icon/gray-share.svg" alt="" />
</div>
<p-overlay [(visible)]="overlayVisible" [style]="{'margin-left': '-165px', }">
  <ng-template pTemplate="content">
    <div
      class="mt-2 min-w-[210px] max-w-[210px] rounded-lg border border-palette-gray-300 bg-white p-1 font-inter lg:min-h-fit"
    >
      <common-icon-text
        class="flex h-10 cursor-pointer items-center space-x-2 rounded-lg p-2 text-palette-gray-700 hover:bg-black hover:bg-opacity-[0.1]"
        (click)="onClickShareToFeed()"
        [icon]="'sctr-icon-home-line'"
        [iconStyle]="'text-xl'"
        [text]="('DETAIL.SHARE_TO_FEED' | soctripTranslate | async) || ''"
        [textStyle]="'whitespace-nowrap'"
      ></common-icon-text>
      <common-icon-text
        class="flex h-10 cursor-pointer items-center space-x-2 rounded-lg p-2 text-palette-gray-700 hover:bg-black hover:bg-opacity-[0.1]"
        (click)="onCopyLink()"
        [icon]="'sctr-icon-copy-06'"
        [iconStyle]="'text-xl'"
        [text]="('DETAIL.COPY_LINK' | soctripTranslate | async) || ''"
        [textStyle]="'whitespace-nowrap'"
      ></common-icon-text>
    </div>
  </ng-template>
</p-overlay>

<app-toast keyToast="cp"></app-toast>

<div *ngIf="overlayVisibleShareToFeed">
  <soctrip-post-dialog
    [visible]="overlayVisibleShareToFeed"
    (visibleChange)="visibleShareToFeedModuleChange($event)"
    [dataDetailCar]="dataDetailCar"
  ></soctrip-post-dialog>
</div>

<app-sign-in-request-dialog
  [visiblePleaseSignIn]="visiblePleaseSignIn"
  (visiblePleaseSignInChange)="visiblePleaseSignInChange()"
></app-sign-in-request-dialog>
