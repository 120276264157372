import { Injectable } from '@angular/core';
import { VehicleControllerService } from '@car-rental/angular-car-rental-service';

@Injectable({
  providedIn: 'root',
})
export class HelpfulService {
  constructor(
    private vehicleControllerService: VehicleControllerService
  ) {}

  checkIsHelpful(id: string) {
    return this.vehicleControllerService.vehiclesReactionsHelpfulCheckReviewIdGet(id);
  }

  markAsHelpful(id: string) {
    return this.vehicleControllerService.vehiclesReactionsHelpfulVoteReviewIdPost(id);
  }

  markAsUnHelpful(id: string) {
    return this.vehicleControllerService.vehiclesReactionsHelpfulUnvoteReviewIdDelete(id);
  }
}
