import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { url } from './config/app-config';

@Injectable({
  providedIn: 'root',
})
export class ViewDetailsService {
  private isSupportMultiRoom = new BehaviorSubject<boolean>(false);
  private isLoading = new BehaviorSubject<boolean>(false);
  constructor(private http: HttpClient) {}

  get(idHotel?: string): Observable<any> {
    return this.http.get<any>(`${url}/property-data/property/${idHotel}`);
  }

  setSupportMultiRoom(data: boolean) {
    this.isSupportMultiRoom.next(data);
  }

  getSupportMultiRoom() {
    return this.isSupportMultiRoom.asObservable();
  }

  setIsLoading(data: boolean) {
    this.isLoading.next(data);
  }

  getIsLoading() {
    return this.isLoading.asObservable();
  }
}
