<div
    *ngIf="isShowPopup"
    class="fixed top-0 right-0 bottom-0 left-0 z-[9999] bg-palette-base-black bg-opacity-40 h-screen !overflow-hidden !overflow-y-hidden !overflow-x-hidden"
    (click)="closePopup()"
>
    <div class="flex items-center justify-center h-screen">
        <div class="qr-code-booking rounded-lg !w-[367px] !p-6" (click)="stopPropagationEvent($event)">
            <div>
                <p class="font-inter !text-sm !font-normal text-palette-gray-500">Booking ID: <span class="!text-base !font-semibold text-palette-gray-900">{{ bookingId }}</span></p>
            </div>
            <img class="!my-4" [src]="urlImg" />
            <div class="">
                <button (click)="downloadImage(urlImg, 'download.png')" class="!w-full font-inter flex items-center justify-center !gap-2 !text-sm !font-semibold !py-3 !px-5 border bg-palette-base-white rounded-lg">
                    <i class="sctr-icon-download-01 !text-xl !leading-[20px]"></i>
                    Save
                </button>
            </div>
        </div>
    </div>
</div>