import { Component, EventEmitter, Output } from '@angular/core';
import {
  Input,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnInit,
  ChangeDetectorRef,
} from '@angular/core';
import { MessageService } from 'primeng/api';
import { ToastType } from '@shared/enum/toast-type';
import {
  Room,
  RoomAmenityList,
} from '@modules/car-booking/models/search-available-room.model';
import { formatCurrency } from '@shared/utils/helper';
import { BookedRoomListService } from '@modules/car-booking/services/booked-list-rooms.service';
import { ViewDetailsService } from '@modules/car-booking/services/view-details.service';
import { ReservationService } from '@modules/car-booking/services/reservation-service/reservation.service';
import { TooltipPosition } from '@shared/enum/tooltip.enums';
import { environment } from '@env/environment';


@Component({
  selector: 'app-hotel-page-room-type',
  templateUrl: './hotel-page-room-type.component.html',
  styleUrls: ['./hotel-page-room-type.component.scss'],
  providers: [MessageService],
})
export class HotelPageRoomTypeComponent implements AfterViewInit, OnInit {
  @ViewChild('hotelFacility') hotelFacility: ElementRef;
  @Input() item: any;
  @Output() buyRoomType = new EventEmitter();
  @Output() selectedRoomType = new EventEmitter();

  public displayHotelFacilityList: RoomAmenityList[];
  public slicedItems: string;
  public remaining: number;
  public hasRemaining: boolean;
  is_support_multi_room: boolean;
  baseUrl = environment.BASE_PATH;
  tooltip_inclusions: string = '';

  TooltipPosition: typeof TooltipPosition = TooltipPosition;

  constructor(
    private messageService: MessageService,
    private cdRef: ChangeDetectorRef,
    private bookedRoomListService: BookedRoomListService,
    private viewDetailsService: ViewDetailsService,
    private reservationService: ReservationService
  ) {}

  ngOnInit(): void {
    this.item.room_amenity_list?.filter((item: any) => {
      if (item.amenity_name === 'Spa') {
        item.amenity_icon = 'Spa';
      }
    });

    this.displayHotelFacilityList = this.item.room_amenity_list?.slice(0, 8);

    this.viewDetailsService.getSupportMultiRoom().subscribe((data: boolean) => {
      this.is_support_multi_room = data;
    });

    if (Array.isArray(this.item)) {
      for (let obj of this.item) {
        obj.showTooltipPolicy = false;
        obj.showTooltipTaxes = false;
      }
    }
  }

  ngAfterViewInit(): void {
    this.cdRef.detectChanges();
  }

  renderTooltipInclusion(inclusions: any) {
    let tooltip_inclusions = '';
    for (let i = 1; i < inclusions.length; i++) {
      tooltip_inclusions +=
        "<p class='flex !mt-1 items-center !gap-1 relative px-[6px] py-[2px] rounded-lg bg-palette-blue-50 text-palette-blue-600 max-w-fit'>";
      tooltip_inclusions += inclusions[i];
      tooltip_inclusions += '</p>';
    }

    return tooltip_inclusions;
  }

  public responsiveHotelFacility(index: number) {
    this.displayHotelFacilityList = this.item.room_amenity_list.slice(0, index);
    this.slicedItems = this.item.room_amenity_list.slice(index).join(', ');
    this.remaining = this.item.room_amenity_list.length - index;
    this.hasRemaining = true;
  }

  public handleClickView(event: Event) {
    event.stopPropagation();
    this.messageService.add({
      severity: ToastType.WARNING,
      detail: 'This feature is not developed yet',
    });
  }

  formatPrice(number: number) {
    return formatCurrency(number);
  }

  subtractNumberOfRoom(room: any) {
    if (room.numberRoomBuy !== 0) {
      room.numberRoomBuy--;
      this.bookedRoomListService.setBookedRoomList(
        room.room_type_id,
        'subtract'
      );
    }
    this.buyRoomType.emit(room);
  }

  plusNumberOfRoom(rate_plan: any, room: any) {
    rate_plan.numberRoomBuy++;
    this.bookedRoomListService.setBookedRoomList(
      rate_plan.room_type_id,
      'plus'
    );

    this.buyRoomType.emit(room);
  }

  handleClickRoomHotel(room: any) {
    this.selectedRoomType.emit(room);
  }

  replaceWithDefaultImage(event: any): void {
    event.target.src = 'assets/images/test/default-hotel.svg';
  }

  formatDollar(price: number) {
    return new Intl.NumberFormat('en-US', {
      style: 'decimal',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(price);
  }

  handleClickBookRoom(rate_plan: any, room: any) {
    const data = {
      rate_plan: rate_plan,
      room: room,
    };
    this.reservationService.notiBookingSingleRoomType(data);
  }

  getTotalTaxeCharge(taxes: any) {
    let totalTaxe = 0;
    for (const value of taxes) {
      totalTaxe += value.amount;
    }

    return totalTaxe;
  }

  convertToTitleCase(str: string): string {
    const words = str.split('_');

    const capitalizedWords = words.map(word => {
        return word.charAt(0).toUpperCase() + word.slice(1);
    });

    const result = capitalizedWords.join(' ');

    return result;
}
}
