<div class="shadow-sx col-span-2 mt-3 rounded-xl border border-palette-gray-200 bg-white px-4">
  <p class="!mb-3 !pt-4 text-base font-semibold text-palette-gray-800">
    {{ 'APP_JOURNEY_INFORMATION.JOURNEY_INFORMATION' | soctripTranslate | async }}
  </p>
  <div class="pb-2">
    <div class="col-span-1 flex rounded-md">
      <div class="col-span-1 w-full !text-md font-normal text-palette-gray-700">
        <div class="grid grid-cols-1 p-2">
          <div class="flex justify-between gap-2">
            <div class="col-span-1">
              {{ 'APP_JOURNEY_INFORMATION.DAILY_ALLOWABLE_MILEAGE' | soctripTranslate | async }}
            </div>
            <div class="col-span-1 flex justify-end font-medium">
              {{ vehiclePriceData?.daily_mileage_limit }} km
            </div>
          </div>
        </div>
        <div class="grid grid-cols-1 p-2">
          <div class="flex justify-between gap-2">
            <div class="col-span-1">
              {{ 'APP_JOURNEY_INFORMATION.EXCESS_MILEAGE_FEE' | soctripTranslate | async }}
            </div>
            <div class="col-span-1 flex justify-end font-medium">
              <app-price-format
                [keepValue]="keepValue"
                [price]="vehiclePriceData?.overage_fee"
              ></app-price-format
              >/km
            </div>
          </div>
        </div>
        <div class="grid grid-cols-1 p-2">
          <div class="flex justify-between gap-2">
            <div class="col-span-1">
              {{ 'APP_JOURNEY_INFORMATION.LATE_RETURN_FEE' | soctripTranslate | async }}
            </div>
            <div class="col-span-1 flex justify-end font-medium">
              <app-price-format
                [keepValue]="keepValue"
                [price]="vehiclePriceData?.late_return_fee"
              ></app-price-format
              >/{{ 'APP_JOURNEY_INFORMATION.HOUR' | soctripTranslate | async }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="vehiclePriceData?.car_delivery_fee!==0 && vehiclePriceData?.support_car_delivery_with_in!==0" class="border-t"></div>
  <p *ngIf="vehiclePriceData?.car_delivery_fee!==0 && vehiclePriceData?.support_car_delivery_with_in!==0" class="!mb-3 !pt-4 text-base font-semibold text-palette-gray-800">
    {{ 'APP_JOURNEY_INFORMATION.DELIVERY_FEE' | soctripTranslate | async }}
  </p>
  <div *ngIf="vehiclePriceData?.car_delivery_fee!==0 && vehiclePriceData?.support_car_delivery_with_in!==0" class="pb-2">
    <div class="col-span-1 flex rounded-md text-md font-normal text-palette-gray-700">
      <div class="col-span-1 w-full">
        <div class="grid grid-cols-1 p-2">
          <div class="flex justify-between gap-2">
            <div class="col-span-1">
              {{ 'APP_JOURNEY_INFORMATION.CAR_DELIVERY_FEE' | soctripTranslate | async }}
            </div>
            <div class="col-span-1 flex justify-end font-medium">
              <app-price-format
                [keepValue]="keepValue"
                [price]="vehiclePriceData?.car_delivery_fee"
              ></app-price-format
              >/km
            </div>
          </div>
        </div>
        <div class="grid grid-cols-1 p-2">
          <div class="flex justify-between gap-2">
            <div class="col-span-1">
              {{ 'APP_JOURNEY_INFORMATION.SUPPORT_CAR_DELIVERED_WITH_IN' | soctripTranslate | async }}
            </div>
            <div class="col-span-1 flex justify-end font-medium">
              {{ vehiclePriceData?.support_car_delivery_with_in }} km
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="border-t"></div>
  <p class="!mb-3 !pt-4 text-base font-semibold text-palette-gray-800">
    {{ 'APP_JOURNEY_INFORMATION.POTENTIAL_SURCHARGE' | soctripTranslate | async }}
  </p>
  <div class="pb-2">
    <div class="col-span-1 flex rounded-md text-md font-normal text-palette-gray-700">
      <div class="col-span-1 w-full">
        <div class="grid grid-cols-1 gap-1 p-2">
          <div class="flex justify-between gap-2">
            <div class="col-span-1">
              {{ 'APP_JOURNEY_INFORMATION.CLEANING_SURCHARGE' | soctripTranslate | async }}
            </div>
            <div class="col-span-1 flex justify-end font-medium">
              <app-price-format
                [keepValue]="keepValue"
                [price]="vehiclePriceData?.cleaning_surcharge"
              ></app-price-format>
            </div>
          </div>
          <span class="!text-sm">
            {{ 'APP_JOURNEY_INFORMATION.CANCELLATION_FEE_INFO' | soctripTranslate | async }}
          </span>
        </div>
        <div class="grid grid-cols-1 gap-1 p-2">
          <div class="flex justify-between gap-2">
            <div class="col-span-1">
              {{ 'APP_JOURNEY_INFORMATION.SMELL_REMOVAL_SURCHARGE' | soctripTranslate | async }}
            </div>
            <div class="col-span-1 flex justify-end font-medium">
              <app-price-format
                [keepValue]="keepValue"
                [price]="vehiclePriceData?.smell_removal_surcharge"
              ></app-price-format>
            </div>
          </div>
          <span class="!text-sm">
            {{ 'APP_JOURNEY_INFORMATION.SMELL_REMOVAL_SURCHARGE_INFO' | soctripTranslate | async }}
          </span>
        </div>
        <div class="grid grid-cols-1 gap-1 p-2">
          <div class="flex justify-between gap-2">
            <div class="col-span-1">
              {{ 'APP_JOURNEY_INFORMATION.OTHER_FEE' | soctripTranslate | async }}
            </div>
            <div class="col-span-1 flex justify-end font-medium">
              {{ 'APP_JOURNEY_INFORMATION.PAY_BY_RENTER' | soctripTranslate | async }}
            </div>
          </div>
          <span class="!text-sm">
            {{ 'APP_JOURNEY_INFORMATION.SOME_FEES_MAY_ARISE' | soctripTranslate | async }}
          </span>
          <div class="mt-[2px] flex items-start gap-2 rounded-lg border border-branding-primary-100 bg-branding-primary-50 p-3 text-branding-primary-600">
            <p class="text-md font-normal">
              {{ 'APP_JOURNEY_INFORMATION.DELIVERY_FEE_AND_POTENTIAL_SURCHARGE' | soctripTranslate | async }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
