import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { CarOtaService } from '../car-ota.service';
import { CheckboxModule } from 'primeng/checkbox';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-modal-submit',
  standalone: true,
  imports: [CommonModule, SharedModule, CheckboxModule],
  templateUrl: './modal-submit.component.html',
  styleUrls: ['./modal-submit.component.scss']
})
export class ModalSubmitComponent implements OnInit, OnDestroy{
  dialogConfig = {
    closeFromOutSide: true,
    resizable: false,
    scrollable: false,
    displayCloseButton: true,
    styleClass: 'w-[55.25rem]',
  };
  isShow: boolean = false;
  accept: any;
  type: string;
  destroy$ = new Subject<void>();
  constructor(private carOtaService: CarOtaService){}
  ngOnInit(): void {
    this.carOtaService.showModal.pipe(takeUntil(this.destroy$)).subscribe(value => {

        this.isShow = value.boolean
      this.type = value.type
      this.getType(this.type)
    });
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  cancel(){
    this.carOtaService.showModal.next({type: '', boolean: false});
    this.accept = undefined;
  }
  onChangeAccept(e: Event){
    this.accept = e;
  }
  getType(data: string){
    this.type = data;
  }
  submit(){
    this.carOtaService.showModal.next({type: this.type, boolean: false});
    this.carOtaService.submit.next({type: this.type, boolean: true});
    this.accept = undefined;
  }
}
