import { CalendarOptionEnum, StatisticEnum, StatisticUnitEnum } from '@shared/enum/dashboard.enum';

const translatePrefix = 'page.ota.dashboard.';

export const CalendarOptionsChoose = [
  {
    value: CalendarOptionEnum.TODAY,
    title: translatePrefix + 'today',
  },
  {
    value: CalendarOptionEnum.YESTERDAY,
    title: translatePrefix + 'yesterday',
  },
  {
    value: CalendarOptionEnum.PAST_7_DAYS,
    title: translatePrefix + 'past_7_days',
  },
  {
    value: CalendarOptionEnum.PAST_30_DAYS,
    title: translatePrefix + 'past_30_days',
  },
];

export const CalendarOptionsSelect = [
  {
    title: translatePrefix + 'by_day',
    value: CalendarOptionEnum.BY_DAY,
  },
  {
    title: translatePrefix + 'by_week',
    value: CalendarOptionEnum.BY_WEEK,
  },
  {
    title: translatePrefix + 'by_month',
    value: CalendarOptionEnum.BY_MONTH,
  },
  {
    title: translatePrefix + 'by_year',
    value: CalendarOptionEnum.BY_YEAR,
  },
];

export const StatisticConfig = [
  {
    id: StatisticEnum.TOTAL_REVENUE,
    title: translatePrefix + 'total_revenue',
    tooltip: translatePrefix + 'tooltip.total_revenue',
    type: StatisticUnitEnum.PRICE,
    amount: 0,
    fluctuation: 0,
  },
  {
    id: StatisticEnum.NUMBER_OF_CARS,
    title: translatePrefix + 'number_of_cars',
    tooltip: translatePrefix + 'tooltip.number_of_cars',
    type: StatisticUnitEnum.NORMAL,
    amount: 0,
    fluctuation: 0,
  },
  {
    id: StatisticEnum.TOTAL_BOOKING,
    title: translatePrefix + 'total_booking',
    tooltip: translatePrefix + 'tooltip.total_booking',
    type: StatisticUnitEnum.NORMAL,
    amount: 0,
    fluctuation: 0,
  },
  {
    id: StatisticEnum.AVERAGE_RENTAL_DURATION,
    title: translatePrefix + 'average_rental_duration',
    tooltip: translatePrefix + 'tooltip.average_rental_duration',
    type: StatisticUnitEnum.NORMAL,
    amount: 0,
    fluctuation: 0,
    unit: 'days/car',
  },

  {
    id: StatisticEnum.AVERAGE_DAILY_SPEND_ON_RENTAL,
    title: translatePrefix + 'average_daily_spend_on_rental',
    tooltip: translatePrefix + 'tooltip.average_daily_spend_on_rental',
    type: StatisticUnitEnum.PRICE,
    amount: 0,
    fluctuation: 0,
    unit: 'day',
  },
  {
    id: StatisticEnum.REVENUE_PER_CAR,
    title: translatePrefix + 'revenue_per_car',
    tooltip: translatePrefix + 'tooltip.revenue_per_car',
    type: StatisticUnitEnum.PRICE,
    amount: 0,
    fluctuation: 0,
  },
  {
    id: StatisticEnum.CANCELLATION_RATE,
    title: translatePrefix + 'cancellation_rate',
    tooltip: translatePrefix + 'tooltip.cancellation_rate',
    type: StatisticUnitEnum.PERCENT,
    amount: 0,
    fluctuation: 0,
  },
];
