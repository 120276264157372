<div *ngIf="isShowPopup && isShowCTA && isLogin === false" class="popup-cta fixed bottom-[75px] left-0 right-0 min-[805px]:h-[106px] mx-[8px] min-[540px]:mx-[12.5%] z-[99999] rounded-xl !p-6 overflow-hidden">
    <div class="flex flex-col gap-4 min-[805px]:flex-row justify-center md:justify-between items-center min-[805px]:h-[58px]">
        <div class="flex flex-col gap-2 font-inter text-palette-base-white text-center min-[805px]:!text-left">
            <div class="text-xl font-semibold leading-[30px]">{{'CTA.TITLE' | translate}}</div>
            <div class="text-sm !font-medium leading-5 !text-palette-gray-100">{{'CTA.DETAIL' | translate}}</div>
        </div>
        <button class="md:mr-4 xl:mr-12 2xl:mr-24 whitespace-nowrap rounded-lg border border-palette-blue-600 bg-palette-blue-600 hover:bg-palette-blue-700 hover:border-palette-blue-700 !h-11 w-fit py-[10px] px-[18px] text-base font-semibold leading-6 text-palette-base-white"
                (click)="handleClickSignInOrSignUp()">
            {{'CTA.SIGN_IN' | translate}}
        </button>
    </div>
    <button class="absolute top-1 right-1 !w-9 h-9 p-2 flex justify-center items-center shadow bg-palette-gray-100 rounded-full text-palette-gray-700 hover:!text-[#f26a3d]" (click)="handleClickClose()">
        <i class="sctr-icon-x-close text-xl "></i>
    </button>
</div>
