<div class="flex flex-col gap-2 !px-4 !pb-2 !pt-3 font-inter">
  <div class="info flex flex-row items-center justify-between">
    <div class="flex flex-row items-center">
      <span *ngIf="item.created_by; then user; else guest"></span>
      <ng-template #user>
        <img
          class="!h-10 !w-10 rounded-full"
          [src]="item.created_by.avatar_thumbnail_url | ImagePath : 'thumbnail'"
        />
      </ng-template>
      <ng-template #guest>
        <div class="flex flex-col items-center">
          <i
            class="sctr-icon-solid-user-02 !h-10 !w-10 rounded-full bg-palette-gray-200 text-center text-2xl !leading-10 text-palette-gray-700"
          ></i>
        </div>
      </ng-template>
      <div class="!ml-3">
        <p class="text-xs font-medium text-palette-gray-700 sm:text-base">
          {{ item.created_by ? item.created_by.full_name : ('DETAIL.GUEST' | soctripTranslate | async) }}
        </p>
        <p class="text-xs font-normal text-palette-gray-500 sm:text-sm">
          {{ item.created_at | customDate }}
        </p>
      </div>
    </div>
    <div class="flex flex-row items-center !gap-2">
      <p
        *ngIf="item.rating >= 9"
        class="!ml-2 text-base font-medium text-palette-gray-700 first-letter:uppercase"
      >
        {{ 'DETAIL.SUPERB' | soctripTranslate | async }}
      </p>
      <p
        *ngIf="item.rating >= 8 && item.rating < 9"
        class="!ml-2 text-base font-medium text-palette-gray-700 first-letter:uppercase"
      >
        {{ 'DETAIL.EXCELLENT' | soctripTranslate | async }}
      </p>
      <p
        *ngIf="item.rating >= 7 && item.rating < 8"
        class="!ml-2 text-base font-medium text-palette-gray-700 first-letter:uppercase"
      >
        {{ 'DETAIL.VERY_GOOD' | soctripTranslate | async }}
      </p>
      <p
        *ngIf="item.rating >= 6 && item.rating < 7"
        class="!ml-2 text-base font-medium text-palette-gray-700 first-letter:uppercase"
      >
        {{ 'DETAIL.PLEASANT' | soctripTranslate | async }}
      </p>
      <p
        *ngIf="item.rating < 6"
        class="!ml-2 text-base font-medium text-palette-gray-700 first-letter:uppercase"
      >
        {{ 'DETAIL.UNCOMFORTABLE' | soctripTranslate | async }}
      </p>
      <div
        class="box-border h-[22px] w-fit rounded border border-palette-blue-600 !px-2 py-[2px] text-xs font-medium leading-[18px] text-palette-blue-600"
      >
        {{ item.rating }}
      </div>
    </div>
  </div>
  <!-- <div class="flex flex-col 2xl:flex-row 2xl:items-center !my-2 text-palette-gray-700 flex-wrap mb-[2px]">
    <div class="flex flex-row items-center gap-2 text-xs sm:text-sm font-medium border-r-palette-gray-200 border-r pr-2">
        <p class="">{{ 'DETAIL.ROOM' | translate }}: {{ item.factors[0].type }}</p>
        <div class="relative" *ngIf="item.factors.length > 1">
            <p [tooltip]="listTypeRoomToolTip" [theme]="TooltipTheme.LIGHT"
                [position]="TooltipPosition.ABOVE"
                class="cursor-pointer line-clamp-1 !text-palette-gray-700 bg-palette-gray-200 !font-inter !text-xs !leading-[18px] !font-medium flex justify-center items-center rounded-2xl">
                <span class="!px-2 !py-[2px]">+{{item.factors.length - 1}}</span>
            </p>
        </div>
    </div>
    <div #hotelPoints class="flex flex-row flex-wrap sm:items-center text-xs font-medium !leading-[18px] ml-2">
        <p *ngFor="let note of item.notes" class="!mt-2 !mr-1 2xl:!mt-0 !py-[2px] !px-2 rounded-2xl bg-palette-gray-200">{{ note.content | translate }} ({{ note.score }})</p>
    </div>
  </div> -->

  <p
    #reviewContent
    class="text-sm sm:text-sm text-palette-gray-700 leading-6 font-normal {{
      isExpanded ? 'line-clamp-none' : 'line-clamp-3'
    }}"
  >
    {{ item.content }}
  </p>

  <button
    *ngIf="isLongText"
    (click)="toggleContent()"
    class="!mt-1 text-sm font-semibold text-palette-blue-700"
  >
    {{
      isExpanded ? ('COMMON.SHOW_LESS' | soctripTranslate | async) : ('COMMON.VIEW_MORE' | soctripTranslate | async)
    }}
  </button>

  <!-- <div *ngIf="item.medias && item.medias.length > 5" class="list-images flex flex-row flex-wrap">
    <div
      *ngFor="let image of item.medias; let i = index"
      (click)="onClickImage(item.medias, i)"
      class="relative !mr-2 cursor-pointer"
    >
      <ng-container *ngIf="i < 5">
        <img
          class="h-20 max-h-20 !w-20 max-w-[80px] rounded-lg"
          src="{{ baseUrl }}/storage/files/web/{{ image.id }}"
          (error)="replaceWithDefaultImage($event)"
          alt=""
        />
        <div
          *ngIf="i == 4"
          class="z-1 absolute left-0 top-0 flex h-20 max-h-20 !w-20 max-w-[80px] flex-row items-center justify-center rounded-lg bg-[#10182866] bg-opacity-40 text-sm text-white"
        >
          {{ 'DETAIL.VIEW_MORE' | soctripTranslate | async }}
        </div>
      </ng-container>
    </div>
  </div> -->
  <div *ngIf="item.medias && item.medias.length > 0">
    <t-media-preview [medias]="item.medias"></t-media-preview>
  </div>

  <div class="action flex flex-row space-x-[10px] text-sm font-semibold text-palette-gray-400">
    <div
      (click)="handleClickHelpful(item.id)"
      class="flex cursor-pointer flex-row items-center space-x-2 !py-2"
      [ngClass]="{ 'text-palette-blue-700': isHelpful }"
    >
      <i class="sctr-icon-thumbs-up text-2xl/5"></i>
      <p>{{ 'DETAIL.HELPFUL' | soctripTranslate | async }}</p>
      <span *ngIf="helpfulCount">{{ helpfulCount }}</span>
    </div>

    <div
      (click)="handleClickReport(item.id)"
      class="flex cursor-pointer flex-row items-center space-x-2 !px-[14px] !py-2"
      [ngClass]="{ 'text-palette-blue-700': isReported }"
    >
      <i class="sctr-icon-flag-01 text-2xl/5"></i>
      <p>{{ 'DETAIL.REPORT' | soctripTranslate | async }}</p>
    </div>
  </div>
</div>

