import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class CarOtaService {
  public showModal = new BehaviorSubject({ type: '', boolean: false });
  public submit = new BehaviorSubject({ type: '', boolean: false });
  constructor(private translateService: TranslateService) {}
  getTranslate(value: string, param?: Object) {
    return this.translateService.instant(value, param);
  }
}
