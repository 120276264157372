<div class="show-image-modal relative h-full bg-black">
  <div class="absolute top-[40px] flex w-full justify-between px-[32px]">
    <div class="w-1/3"></div>
    <div *ngIf="image_urls.length > 0" class="w-1/3 text-center text-[18px] font-normal text-white">
      {{ activeIndex + 1 }}/{{ all_image_urls.length }}
    </div>
    <div class="flex w-1/3 justify-end">
      <div
        class="z-[10000] max-h-[56px] min-w-[56px] max-w-[36px] cursor-pointer text-white"
        (click)="onclose()"
      >
        <i class="sctr-icon-x-close text-[32px] font-normal text-white"></i>
      </div>
    </div>
  </div>
  <div class="card md:justify-content-center pt-[100px] md:flex">
    <div
      class="item-navigator pointer-events-none absolute z-10 flex w-full items-center justify-between px-[32px] text-[44px]"
    >
      <div
        [ngClass]="
          activeIndex == 0
            ? 'cursor-default text-palette-gray-400 opacity-40'
            : 'cursor-pointer text-white'
        "
        class="sctr-icon-chevron-left pointer-events-auto"
        (click)="prev()"
        styleClass="galleria-prev"
      ></div>
      <div
        [ngClass]="
          activeIndex == image_urls.length - 1
            ? 'cursor-default text-palette-gray-400 opacity-40'
            : 'cursor-pointer text-white'
        "
        class="sctr-icon-chevron-right pointer-events-auto"
        (click)="next()"
        styleClass="galleria-next"
      ></div>
    </div>
    <div>
      <div
        class="image-item image-box flex content-center items-center justify-center overflow-x-scroll"
        (wheel)="onMouseWheelImage($event)"
      >
        <img
          id="image-preview"
          *ngIf="isImageFile(image_urls[activeIndex])"
          class="h-full max-w-[calc(100vw-100px)]"
          [src]="image_urls[activeIndex].id | ImagePath : 'thumbnail'"
          (error)="replaceWithDefaultImage($event)"
          alt=""
        />
        <video
          *ngIf="!isImageFile(image_urls[activeIndex])"
          controls
          class="h-full w-full max-w-[calc(100vw-100px)] object-cover"
          [src]="image_urls[activeIndex].id | VideoPath"
          id="video-preview"
        >
        </video>
      </div>
      <div class="flex justify-center">
        <div class="mt-[90px] flex max-w-[100vw] space-x-1 overflow-hidden">
          <div *ngFor="let item of image_urls; index as idx">
            <div
              id="image-{{ idx }}"
              (click)="handleImageScroll(idx)"
              class="rounded-sm"
              [ngClass]="idx == activeIndex ? 'p-galleria-thumbnail-item-current' : ''"
            >
              <img
                *ngIf="isImageFile(item)"
                class="image-thumbnail-item rounded-sm"
                [src]="item.id | ImagePath : 'thumbnail'"
                (error)="replaceWithDefaultImage($event)"
              />
              <div
                class="relative h-[100px] w-[100px] cursor-pointer rounded-sm"
                *ngIf="!isImageFile(item)"
              >
                <span
                  class="absolute left-1/2 top-1/2 flex -translate-x-1/2 -translate-y-1/2 transform rounded-full bg-palette-gray-700 p-2 opacity-80"
                >
                  <i class="sctr-icon-play text-white"></i>
                </span>
                <video *ngIf="!isImageFile(item)" class="h-full w-full rounded-sm object-cover">
                  <source [src]="item.id | VideoPath" id="{{ item.id }}" />
                </video>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
