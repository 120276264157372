<div class="featured-hotel-item cursor-pointer rounded-md border border-palette-gray-100" (click)="viewDetails(item.id, item.description, item)">
    <div class="rounded-t-lg image-container">
        <!-- <img *ngIf="item.property_image_overview_url" class="rounded-t-md object-cover" src="{{item.property_image_overview_url}}" (error)="replaceWithDefaultImage($event)" alt=" ">
        <img *ngIf="!item.property_image_overview_url" class="rounded-t-md object-cover" src="{{baseImageId}}/{{ item.property_image_overview_id }}.webp" (error)="replaceWithDefaultImage($event)" alt=" "> -->
        <button
            class="btn-favorite bg-palette-gray-900 bg-opacity-40 rounded-lg p-[6px] absolute top-2 right-2 text-center"
            (click)="handleBookmark($event, item)">
            <span *ngIf="isBookmark; then favourite; else unfavourite"></span>
            <ng-template #favourite>
                <img class="!w-4 !h-4" src="assets/images/common-icon/bookmark-close.svg" alt="">
            </ng-template>
            <ng-template #unfavourite>
                <img class="!w-4 !h-4" src="assets/images/common-icon/bookmark-open.svg" alt="">
            </ng-template>
        </button>
        <app-rating-score *ngIf="item.average_rating" [rating]="item.average_rating" 
            [style]="'px-2 py-1 bg-palette-gray-900 bg-opacity-40 absolute bottom-3 left-3 text-white text-base font-semibold'" 
            [tooltipStyle]="'bottom-1/2 left-[120%] translate-y-[50%] w-[180px] min-[370px]:w-[100px] min-[500px]:w-[180px]'">
        </app-rating-score>
        <!-- <div *ngIf="!item.average_rating"
            class="h-fit w-fit bg-palette-gray-900 bg-opacity-40 absolute bottom-3 left-2 text-white text-base rounded-lg p-1.5">
            {{'COMMON.NO_REVIEWS' | translate}}
        </div> -->
    </div>
    <div class="bg-white !p-3 rounded-b-md">
        <div class="flex justify-start items-center relative">
            <div class="flex gap-[1px]" (mouseenter)="showStarTooltip = true" (mouseleave)="showStarTooltip = false">
                <div class="flex gap-[1px]">
                    <div *ngFor="let star of [].constructor(getStarCount(item.star))">
                        <div class="min-h-[14px] min-w-[14px] max-h-[14px] max-w-[14px]">
                            <img src="assets/images/home/icon/star.svg" alt="">
                        </div>
                    </div>
                </div>
                <span *ngIf="hasHalfStar(item.star)">
                    <div class="min-h-[14px] min-w-[14px] max-h-[14px] max-w-[14px] relative">
                        <img src="assets/images/home/icon/star.svg" alt="">
                        <div class="w-[7px] h-full bg-white absolute right-0 top-0"></div>
                    </div>
                </span>
            </div>
            <div *ngIf="showStarTooltip" class="absolute z-[9999] bottom-[120%] w-[180px] min-[370px]:w-[100px] min-[500px]:w-[180px] font-inter font-normal text-center bg-palette-gray-600 bg-opacity-90 text-palette-base-white text-xs p-2 rounded shadow">
                Star rating is provided by the property based on their infrastructure and facilities
            </div>
        </div>
        <div class="relative h-[40px]">
            <div [tooltip]="item.description" [isTruncated]="true"
                class="property-name text-sm not-italic font-semibold leading-[20px] text-palette-gray-900">
                {{ item.description }}
            </div>
        </div>
        <div class="flex items-center relative" (click)="openMap($event, item)">
            <common-icon-text class="!w-full text-ellipsis overflow-hidden flex items-center"
                [icon]="'sctr-icon-marker-pin-01'"
                [iconStyle]="'text-base !leading-5 mr-1 opacity-70'"
                [text]="'item.address'"
                [textStyle]="'text-soctrip-small font-normal text-palette-gray-500 whitespace-nowrap text-ellipsis overflow-hidden'"></common-icon-text>
        </div>
        <div class="h-4 !mt-[6px] grid grid-cols-8 !gap-2 [&>*:nth-child(n+9)]:hidden" data-cy="hotel_facility_list">
            property_amenity_list_thien
            <!-- <ng-container *ngFor="let facility of renderAmenityList(item.property_amenity_list)">
                <div class="icon-service col-span-1 !w-4 h-4 flex items-center">
                    <i class="amenity-icon sctr-icon-{{facility.amenity_icon}}"></i>
                </div>
            </ng-container> -->
        </div>
        <div class="flex items-center gap-[6px] !mt-[6px]">
            <app-price-format [price]="item.price" 
                [styleClass]="'text-palette-orange-dark-500 text-base font-medium !leading-6'"></app-price-format>
            <app-price-format [price]="item.price" 
                [styleClass]="'text-palette-gray-500 text-sm whitespace-nowrap text-ellipsis overflow-hidden line-through'"></app-price-format>
        </div>
    </div>
</div>
<app-toast keyToast="fh"></app-toast>