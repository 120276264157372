import { Pipe, PipeTransform } from '@angular/core';
import { Observable, from, map } from 'rxjs';
import { SoctripTranslationService } from 'src/app/services/soctrip-translation/soctrip-translation.service';

@Pipe({
  name: 'soctripTranslate',
})
export class SoctripTranslate implements PipeTransform {
  constructor(private translationService: SoctripTranslationService) {}
  transform(key: string, interpolateParams?: Object): Observable<string> {
    if (!key) return from(['']);
    return from(this.translationService.getTranslationAsync(key, interpolateParams)).pipe(
      map((translation) => translation ?? '')
    );
  }
}
