import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sign-in-request-dialog',
  templateUrl: './sign-in-request-dialog.component.html',
  styleUrls: ['./sign-in-request-dialog.component.scss']
})
export class SignInRequestDialogComponent {
  @Input() visiblePleaseSignIn = false;
  @Output() visiblePleaseSignInChange = new EventEmitter();
  constructor(
    private router: Router,
  ) {
  }

  onSignUp() {
    this.router.navigate(['sign-up']);
  }
  onSignIn() {
    this.router.navigate(['sign-in']);
  }
  onCloseModal() {
    this.visiblePleaseSignInChange.emit();
  }

}
