import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { DialogConfig } from '@shared/models/dialog';
import { Breakpoint } from '@shared/enum/breakpoint-enum';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ModalComponent implements OnChanges, OnInit {
  @Input() showDialog = false;
  @Input() dialogConfig: DialogConfig;
  @Input() template: any;
  @Input() disabledConfirmBtn: boolean = false;
  @Input() loading?: boolean;
  @Input() autoCloseModal: boolean = true;
  @Output() emitOnCloseDialog = new EventEmitter();
  @Output() emitOnSaveDialog = new EventEmitter();
  @Output() emitOnCancelDialog = new EventEmitter();

  header: string;
  styleClass: string;
  closeFromOutSide: boolean;
  resizable: boolean;
  scrollable: boolean;
  displayCloseButton: boolean;
  innerWidth: number;
  position: string;
  showCancelBtn: boolean;
  showConfirmBtn: boolean;
  cancelBtnText: string;
  confirmBtnText: string;

  isMobile = false;
  constructor(private translateService: TranslateService) {}

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    const screenWidth = (event.target as Window).innerWidth;
    this.identifyBreakpoint(screenWidth);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['dialogConfig']) {
      this.closeFromOutSide = this.dialogConfig?.closeFromOutSide;
      this.resizable = this.dialogConfig?.resizable;
      this.displayCloseButton = this.dialogConfig?.displayCloseButton;
      this.styleClass = this.dialogConfig?.styleClass;
      this.showCancelBtn = this.dialogConfig.cancelBtn || false;
      this.showConfirmBtn = this.dialogConfig.confirmBtn || false;
      this.cancelBtnText =
        this.dialogConfig.cancelBtnText || this.translateService.instant('common.term.cancel');
      this.confirmBtnText =
        this.dialogConfig.confirmBtnText || this.translateService.instant('common.term.confirm');
    }
  }

  ngOnInit() {
    this.identifyBreakpoint(window.innerWidth);
  }

  identifyBreakpoint(screenWidth: number) {
    if (screenWidth >= Breakpoint.MD) {
      this.position = 'center';
    } else {
      this.position = 'bottom';
    }
  }

  closeDialog() {
    this.showDialog = false;
    this.emitOnCloseDialog.emit(false);
  }

  closeFromOutside() {
    if (this.dialogConfig.closeFromOutSide) {
      this.showDialog = false;
    }
  }

  handleSave(event: any) {
    if (this.loading === undefined) {
      this.showDialog = false;
    }
    this.emitOnSaveDialog.emit();
  }

  onCancelDialog() {
    if (this.autoCloseModal) {
      this.showDialog = false;
    }
    this.emitOnCancelDialog.emit(false);
  }
}
